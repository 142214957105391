import * as React from 'react';
import { AppStore } from '../../../stores';
import { inject, observer } from 'mobx-react';
import { ISplitTestAnswer } from '../../../interfaces/ISplitTestAnswer';
import * as ReactGA from 'react-ga';
import './EnneagrammySplitTestQuestion.css';
import EnneagrammySplitTestAnswer from './ennagrammySplitTestAnswer/EnneagrammySplitTestAnswer';

export interface IProps {
  appStore?: AppStore;
}

@inject('appStore')
@observer
export default class EnneagrammySplitTestQuestion extends React.Component<IProps> {

  render() {
    const questionItems = this.props.appStore.splitTest.map((splitTestAnswer: ISplitTestAnswer) =>
      <EnneagrammySplitTestAnswer
        answer={splitTestAnswer}
        key={splitTestAnswer.type}
        onSelect={(selectedType: number) => {
          ReactGA.event({
            category: 'Tests',
            action: `User answered Enneagram test split test question`
          });
          this.props.appStore.calculateSplitTestResult(selectedType);
        }}
      />
    );
    return (
      <div className="splitEnneagrammyContainer">
        {questionItems}
      </div>
    );
  }

}