import { observable, action } from 'mobx';
import { IUser } from '../interfaces/IUser';
import { IAnswer } from '../interfaces/IAnswer';
import { IQuestion } from '../interfaces/IQuestion';
import { ITestResult } from '../interfaces/ITestResult';
import { IType } from '../interfaces/IType';
import { ISplitTestAnswer } from '../interfaces/ISplitTestAnswer';
import TestService from '../services/TestService';
import { IPodcast } from '../interfaces/IPodcast';
import { IPodcastIntro } from '../interfaces/IPodcastIntro';
import { IProduct } from '../interfaces/IProduct';
import { IProductIntro } from '../interfaces/IProductIntro';
import { IProductAccess } from '../interfaces/IProductAccess';
import { ITeam } from '../interfaces/ITeam';
import ProductService from '../services/ProductService';
import { INotification } from '../interfaces/INotification';
import { ITeamAccess } from '../interfaces/ITeamAccess';
import { IMeditationIntro } from '../interfaces/IMeditationIntro';
import { IModuleDocument } from '../interfaces/IModuleDocument';
import AdminService from '../services/AdminService';
import { IProductModule } from '../interfaces/IProductModule';
import { IReadNotification } from '../interfaces/IReadNotification';
import { INote } from '../interfaces/INote';
import { IMindPaper } from '../interfaces/IMindPaper';
import { IShareContent } from '../interfaces/IShareContent';
import NativeService from '../services/NativeService';
import NotificationService from '../services/NotificationService';
import { IPush } from '../interfaces/IPush';
const questions = require('../resources/EnneagramTest.json');
const questionsForSplitTest = require('../resources/EnneagramSplitTest.json');

export class AppStore {

  @observable public isMobile: boolean;
  @observable public isTablet: boolean;
  @observable public environment = "";
  @observable public area: string;
  @observable public editMode: boolean = false;
  @observable public selectedTab = 1;
  @observable public showingIntroPopup = false;
  @observable public isUniversityApp: boolean = true;

  /////////////////////////////////// Test /////////////////////////////////////

  @observable public isTesting: boolean;
  @observable public questions: any[];
  @observable public currentQuestionIndex: number = 0;
  @observable public testProgression: string;
  @observable public disabled: boolean;

  @action public startTest(): void {
    this.isTesting = true;
    this.resetResult();
    this.currentQuestionIndex = 0;
    this.loadQuestions();
  }

  @action public loadQuestions(): void {
    this.splitTest = undefined;
    this.questions = questions;
    this.testProgression = "Test";
  }

  @action public selectAnswer(selectedIndex: number) {
    if (!this.disabled) {
      this.questions[this.currentQuestionIndex].answers.forEach((answer: IAnswer, index: number) => {
        if (selectedIndex === index) {
          answer.selected = true;
        } else {
          answer.selected = false;
        }
      });
      if (this.hasAllQuestionsBeenAnswered()) {
        this.calculateResult();
      } else {
        this.nextQuestion();
      }
    }
  }

  @action private hasAllQuestionsBeenAnswered(): boolean {
    let allQuestionsBeenAnswered = true;
    this.questions.forEach((question: IQuestion) => {
      if (!question.answers[0].selected && !question.answers[1].selected) {
        allQuestionsBeenAnswered = false;
      }
    });
    return allQuestionsBeenAnswered;
  }

  @action public nextQuestion(): void {
    this.disabled = true;
    if (this.questions.length - 1 > this.currentQuestionIndex) {
      setTimeout(() => {
        this.currentQuestionIndex++;
        this.disabled = false;
      }, 500);
    }
  }

  @action public previousQuestion(): void {
    this.disabled = true;
    if (this.currentQuestionIndex !== 0) {
      setTimeout(() => {
        this.currentQuestionIndex--;
        this.disabled = false;
      }, 500);
    }
  }

  ////////////////////////////////// SplitTest /////////////////////////////////

  @observable public splitTest: any[];

  @action private checkForTheNeedOfASplitTest(): void {
    this.splitTest = [];
    const topValue = this.result.pointsForTypes[0].points;
    this.result.pointsForTypes.forEach((type: IType) => {
      if (type.points === topValue) {
        questionsForSplitTest.forEach((splitTestAnswer: ISplitTestAnswer) => {
          if (type.id === splitTestAnswer.type) {
            this.splitTest.push(splitTestAnswer)
          }
        });
      }
    });
    if (this.splitTest.length >= 2) {
      this.testProgression = "SplitTestIntro";
    } else {
      this.testProgression = "Name";
      // TestService.save(this.user.id, this.result, window.loc.strings.myTest).then((testResults: ITestResult[]) => {
      //   if (testResults) {
      //     this.setTestResults(testResults);
      //     this.testProgression = "ResultIsReady";
      //   }
      // });
    }
  }

  @action public showSplitTest(): void {
    this.testProgression = "SplitTest";
  }

  ////////////////////////////// Test calculation //////////////////////////////

  @observable public result: ITestResult;

  @action public calculateResult() {
    this.resetResult();
    this.questions.forEach((question: IQuestion) => {
      if (question.answers[0].selected) {
        question.answers[0].types.forEach((type: number) => {
          this.result.pointsForTypes[type - 1].points++
        });
      } else if (question.answers[1].selected) {
        question.answers[1].types.forEach((type: number) => {
          this.result.pointsForTypes[type - 1].points++
        })
      }
    });
    this.result.pointsForTypes = this.sortByKey(this.result.pointsForTypes, "points");
    this.checkForTheNeedOfASplitTest();
  }

  @action public calculateSplitTestResult(selectedType: number) {
    this.result.splitTestResult = selectedType;
    console.log("Selected type: " + selectedType);
    this.testProgression = "Name";
  }

  @action public sortByKey(array, key): any[] {
    const sortedArray = array.sort(function (a, b) {
      var x = a[key]; var y = b[key];
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
    return sortedArray.reverse();
  }

  @action public resetResult() {
    this.result = {
      maxPoints: 8,
      splitTestResult: null,
      pointsForTypes: [
        { id: 1, points: 0 },
        { id: 2, points: 0 },
        { id: 3, points: 0 },
        { id: 4, points: 0 },
        { id: 5, points: 0 },
        { id: 6, points: 0 },
        { id: 7, points: 0 },
        { id: 8, points: 0 },
        { id: 9, points: 0 },
      ]
    }
  }

  //////////////////////////////// Test result /////////////////////////////////

  @observable public testResults: ITestResult[] = [];
  @observable public selectedTestResult: ITestResult;
  @observable public testIdToDelete: string;

  @action public setTestResults(testResults: ITestResult[]): void {
    this.testResults = testResults.reverse();
    localStorage.setItem("testResults", JSON.stringify(this.testResults));
    if (this.testResults.length > 0) {
      this.myResult = this.testResults[0];
      this.selectedTestResult = this.testResults[0];
      this.testResults.forEach((result: ITestResult) => {
        if (result.myResult) {
          this.myResult = result;
          this.selectedTestResult = result;
        }
      });
    }
  }

  ///////////////////////////////// Type info //////////////////////////////////

  @observable public selectedType: number;

  ///////////////////////////////// Type Test //////////////////////////////////

  @observable public selectedTypeTestTab: number = 1;

  /////////////////////////////// Authentication ///////////////////////////////

  @observable public showingLogin: boolean;
  @observable public showingSignUp: boolean;
  @observable public showingForgottenPassword: boolean;
  @observable public showingForgottenPasswordConfirmation: boolean;
  @observable public user: IUser;
  @observable public loggingIn: boolean;
  @observable public loading: boolean;
  @observable public showingDeleteAccount: boolean;
  @observable public showingUpdatePassword: boolean;
  @observable public showingUpdatePasswordWrongPasswordPopup: boolean;
  @observable public showingAdminUpdatePasswordForUser: IUser;
  @observable public showingAdminDeleteAccountForUser: IUser;

  @observable public username: string;
  @observable public password: string;
  @observable public loginEnabled: boolean;
  @observable public loginError: boolean;
  @observable public showQuote: boolean;

  @action public showDeleteAccountPopup(): void {
    this.showingDeleteAccount = true;
  }

  @action public showLogin(): void {
    this.showingLogin = true;
  }

  @action public showSignUp(): void {
    this.showingLogin = false;
    this.showingSignUp = true;
  }

  @action public showForgottenPassword(): void {
    this.showingForgottenPassword = true;
    this.showingLogin = false;
  }

  @action public showForgottenPasswordConfirmation(): void {
    this.showingForgottenPassword = false;
    this.showingForgottenPasswordConfirmation = true;
  }

  @action public setUser(user: IUser): void {
    if (user) {
      this.user = user;
      this.showingLogin = false;
      if (!localStorage.getItem("userSavedNatively")) {
        NativeService.saveUser(user.username);
        localStorage.setItem("userSavedNatively", "userSavedNatively");
      }
      localStorage.setItem("user", JSON.stringify(user));
      this.loading = true;
      if (localStorage.getItem("testResults")) {
        this.loading = false;
        this.testResults = (JSON.parse(localStorage.getItem("testResults")));
      }
      TestService.loadTestResults(user.id).then((testResults: ITestResult[]) => {
        if (testResults && testResults.length > 0) {
          this.testProgression = "Frontpage";
          this.setTestResults(testResults);
        } else {
          this.testProgression = "TestIntro";

        }
        this.loading = false;
      });
    }
  }

  @action public logOut(): void {
    this.testResults = [];
    this.myResult = undefined;
    this.user = undefined;
    this.testProgression = undefined;
    this.showingSignUp = false;
    this.showingLogin = false;
    this.showExtendedMenu = false;
    localStorage.removeItem("user");
    localStorage.removeItem("testResults");
    localStorage.clear();
  }

  /////////////////////////////// Global menu ///////////////////////////////

  @observable public mobileMenuIsOpen: boolean;
  @observable public showNotification: boolean;
  @observable public backPath: string;

  ///////////////////////////////// Admin ///////////////////////////////////

  @observable public selectedAdminPivot: "users" | "onlineCourses" | "tests" | "podcasts" | "meditations" | "documents" | "notifications" = "onlineCourses";
  @observable public courseToEdit: any;
  @observable public courseIdToEdit: string;
  @observable public usersWithAccess: IUser[];
  @observable public usersWithAccessToProducts: IProductAccess[];
  @observable public showTeamAccessPanelForProductAndTeam: ITeamAccess;

  /////////////////////////////// Product //////////////////////////////////

  @observable public copiedModule: IProductModule;
  @observable public productToEdit: IProduct;
  @observable public productModuleIndesToEdit: number;
  @observable public showProductIntroEditPanel: boolean;
  @observable public showProductAccessPanelForProduct: string;
  @observable public usersWithAccessToProduct: IProductAccess[]
  @observable public usersWithAccessToProductDivs: JSX.Element[] = [];
  @observable public showTeamPanelForProduct: string;
  @observable public teamModuleIndesToEdit: number;
  @observable public isAddingModule: boolean;

  @observable public selectedModule: any;

  @observable public productIntro: IProductIntro;
  @observable public products: IProduct[] = [];
  @observable public sortedProducts: IProduct[] = [];
  @observable public events: IProduct[] = [];
  @observable public productsToRender: JSX.Element[] = [];
  @observable public selectedProduct: IProduct;
  @observable public teamsForProduct: JSX.Element[] = [];
  @observable public teamToEdit: ITeam;
  @observable public productAccess: IProductAccess;
  @observable public selectedProductTeam: ITeam;
  @observable public teamId: string;
  @observable public slideIndex: number = 1;
  @observable public slideMarginLeft: number = 0;

  @observable public popup: string;
  @observable public pageEmbed: string;
  @observable public loadingEmbeddedPage: boolean;
  @observable public isSliderEvent: boolean;

  @observable backgroundImage: string = "landingpage.jpg";

  @observable usersToRender: JSX.Element[] = [];
  @observable productsForAdmin: any[] = [];

  public updateBackground(): void {
    this.backgroundImage = "landingpage.jpg";
    if (this.isMobile) {
      this.backgroundImage = "landingpage-mobile.jpg";
    }
    if (location.pathname.indexOf("19067") != -1) {
      this.backgroundImage = "my-page-bg.jpg";
      if (location.pathname === `${this.environment}/tests/19067`) {
        if (screen.width < 1023) {
          this.backgroundImage = "frontpage-bg-mobile.jpg";
        } else {
          this.backgroundImage = "frontpage-bg.jpg";
        }
      }
    }
    if (location.pathname.indexOf("admin") != -1) {
      this.backgroundImage = "admin.jpg";
    }
    if (location.pathname === "/speakup") {
      this.backgroundImage = "speaker-bg.png";
    }
  }

  public async getProducts(): Promise<void> {
    return new Promise(async (resolve) => {
      if (localStorage.getItem("mindjuice_programs")) {
        this.products = JSON.parse(localStorage.getItem("mindjuice_programs"));
      }
      if (localStorage.getItem("mindjuice_events")) {
        this.events = JSON.parse(localStorage.getItem("mindjuice_events"));
      }
      await ProductService.getProducts().then((products: IProduct[]) => {
        if (products.length > 0) {
          this.products = products.filter((product: IProduct) => product.productType !== "Event")
          localStorage.setItem("mindjuice_programs", JSON.stringify(this.products));
          this.events = products.filter((product: IProduct) => product.productType === "Event")
          localStorage.setItem("mindjuice_events", JSON.stringify(this.events));
        }
      });
      resolve();
    });
  }

  public async getCustomerHandle(): Promise<string> {
    return new Promise(async (resolve) => {
      if (!this.user?.billwerkHandle) {
        await ProductService.getCustomer(this.user.username).then((result) => {
          if (result && result?.content?.length > 0) {
            this.user.billwerkHandle = result?.content[0].handle;
            localStorage.setItem("user", JSON.stringify(this.user));
          } else {
            resolve(undefined);
          }
        });
      }
      resolve(this.user.billwerkHandle);
    });
  }

  public async getTests(): Promise<void> {
    return new Promise(async (resolve) => {
      if (localStorage.getItem("mindjuice_tests")) {
        this.tests = JSON.parse(localStorage.getItem("mindjuice_tests"));
      }
      await TestService.getTests().then((tests: IProduct[]) => {
        if (tests) {
          this.tests = tests;
          localStorage.setItem("mindjuice_tests", JSON.stringify(tests));
        }
      });
      resolve();
    });
  }

  public getProductFromId(id: string): IProduct {
    let selectedProduct: IProduct;
    this.products.forEach((product: IProduct) => {
      if (product.productId === id) {
        selectedProduct = product;
      }
    })
    if (!selectedProduct) {
      this.events.forEach((product: IProduct) => {
        if (product.productId === id) {
          selectedProduct = product;
        }
      })
    }
    return selectedProduct;
  }

  //////////////////////////////// Tests ////////////////////////////////////

  @observable public tests: IProduct[] = [];
  @observable public testToEdit: IProduct;
  @observable public showTestIntroEditPanel: boolean;
  @observable public testIntro: IProductIntro;
  @observable public showEnneagrammy: IProduct;
  @observable public showEnneagrammyTest: boolean;
  @observable public showEnneagrammyTestResults: boolean;
  @observable public checkAccessDone: boolean;
  @observable public userStillHasAccess: boolean;

  ///////////////////////////////// User ////////////////////////////////////

  @observable public users: IUser[];
  @observable public showUserMenu: boolean;
  @observable public showUserExport: boolean;

  /////////////////////////////// Podcasts //////////////////////////////////

  @observable public podcasts: IPodcast[];
  @observable public podcastToEdit: IPodcast;
  @observable public podcastIntro: IPodcastIntro;
  @observable public showPodcastIntroEditPanel: boolean;
  @observable public podcastToPlay: IPodcast;
  @observable public showPodcastPlayerInFullScreen: boolean;
  @observable public videoToPlay: string;
  @observable public minimizeVideo: boolean;
  @observable public speakerVideoToPlay: IPodcast;
  @observable public podcastIsPlaying: boolean;
  @observable public showPopcastPopup: IPodcast;
  @observable public podcastarea: "mindjuice" | "speakers" = "mindjuice";

  //////////////////////////// Meditations ////////////////////////////////

  @observable public meditations: IPodcast[];
  @observable public meditationToEdit: IPodcast;
  @observable public showMeditationIntroEditPanel: boolean;
  @observable public meditationIntro: IMeditationIntro;
  @observable public notificationsToShow: INotification[];

  /////////////////////////////// Popup //////////////////////////////////

  @observable public showPopup: boolean;


  /////////////////////////////// Notification //////////////////////////////////

  @observable public notification: INotification;
  @observable public notificationToEdit: INotification;
  @observable public productToNotify: { product: IProduct, selected: boolean, teams: any[] }[];
  @observable public readNotification: IReadNotification;

  public getNotifications(): void {
    NotificationService.getReadNotifications(this.user.id).then((readNotification: IReadNotification) => {
      if (readNotification) {
        this.readNotification = readNotification;
        NotificationService.getNotifications().then((notifications: INotification[]) => {
          this.notificationsToShow = [];
          if (notifications) {
            notifications = notifications.reverse();
            console.log(notifications);
            notifications.forEach((notification: INotification) => {
              notification.unread = true;
              if (this.readNotification && this.readNotification.notificationIds.length > 0) {
                this.readNotification.notificationIds.forEach((notificationId: string) => {
                  if (notificationId === notification.objectId) {
                    notification.unread = false;
                  }
                });
              }
              if (notification.sendToAll && notification.releaseDate != undefined) {
                if (notification.unread && !this.hasNotificationExpired(notification)) {
                  if (this.pushToShow == undefined) {
                    this.pushToShow = {
                      title: notification.title,
                      text: notification.text,
                      linkText: notification.linkText,
                      url: notification.link
                    };
                  }
                }
                this.notificationsToShow.push(notification);
              } else {
                if (notification.pushReceivers?.length > 0 && notification.pushReceivers.includes(this.user.username)) {
                  if (notification.unread && !this.hasNotificationExpired(notification)) {
                    if (this.pushToShow == undefined) {
                      this.pushToShow = {
                        title: notification.title,
                        text: notification.text,
                        linkText: notification.linkText,
                        url: notification.link
                      };
                    }
                  }
                  this.notificationsToShow.push(notification);
                }
              }
            });
          }
        });
      } else {
        NotificationService.getNotifications().then((notifications: INotification[]) => {
          this.notificationsToShow = [];
          if (notifications) {
            notifications = notifications.reverse();
            console.log(notifications);
            notifications.forEach((notification: INotification) => {
              notification.unread = true;
              if (notification.sendToAll && !this.hasNotificationExpired(notification)) {
                this.notificationsToShow.push(notification);
                if (this.pushToShow == undefined) {
                  this.pushToShow = {
                    title: notification.title,
                    text: notification.text,
                    linkText: notification.linkText,
                    url: notification.link
                  };
                }
              } else {
                if (notification.pushReceivers?.length > 0 && notification.pushReceivers.includes(this.user.username) && !this.hasNotificationExpired(notification)) {
                  this.notificationsToShow.push(notification);
                  if (this.pushToShow == undefined) {
                    this.pushToShow = {
                      title: notification.title,
                      text: notification.text,
                      linkText: notification.linkText,
                      url: notification.link
                    };
                  }
                }
              }
            });
          }
        });
      }
    });
  }

  @action public hasNotificationExpired(notification: INotification): boolean {
    let notificationHasExpired = false;
    // Notification released before user war created
    if (notification.releaseDate && this.user.createdAt && new Date(this.user.createdAt) > new Date(notification.releaseDate)) {
      notificationHasExpired = true;
    }
    // Notification has expired 
    if (notification.expirationeDate && new Date(notification.expirationeDate) > new Date()) {
      notificationHasExpired = true;
    }
    return notificationHasExpired;
  }

  /////////////////////////////// Push popup ////////////////////////////////////

  @observable public pushToShow: IPush;//  = { title: "jonas", text: "Text" , linkText: "Link", url: "https://www.google.com/"};

  //////////////////////////////// Type Test ////////////////////////////////////

  @observable public typeTestColor: string = "#6dc4c4";
  @observable public typeTestColorLighter: string = "#6dc4c4";
  @observable public expandIntro: boolean;
  @observable public typeTestChangeName: boolean;
  @observable public showExtendedMenu: boolean;
  @observable public selectedTypeInfoTab: string;
  @observable public myResult: ITestResult;
  @observable public isShowingSubscriptionPopup: boolean;
  @observable public isShowingUserProfilePopup: boolean;
  @observable public subscription: "enneagramapp.mindjuice.dk.yearlySubscription" | "enneagramapp.mindjuice.dk.monthlySubscription" | "accessFromAdmin";
  @observable public selectedSubscription: "enneagramapp.mindjuice.dk.yearlySubscription" | "enneagramapp.mindjuice.dk.monthlySubscription" | "accessFromAdmin" = "enneagramapp.mindjuice.dk.yearlySubscription";
  @observable public isWaitingForApple: boolean = false;
  @observable public isRestoringSubscription: boolean = false;



  ////////////////////////////////  Documents ////////////////////////////////////

  @observable public documents: IModuleDocument[] = [];
  @observable public documentsToRender: JSX.Element[] = [];

  public async getDocuments(): Promise<void> {
    return new Promise(async (resolve) => {
      await AdminService.getDocuments().then((documents: IModuleDocument[]) => {
        if (documents) {
          this.documents = documents;
        }
      });
      resolve();
    });
  }

  ///////////////////////////////  Mind Paper ///////////////////////////////////

  @observable public mindPapers: any[];
  @observable public noteToEdit: INote;
  @observable public mindPaperName: string;
  @observable public mindPaperToEdit: IMindPaper;
  @observable public printMode: boolean;

  ///////////////////////////////  Change language  ///////////////////////////////////

  @observable public showChangeLanguagePopup: boolean;

  //////////////////////////////////  Profile  ///////////////////////////////////////

  @observable public showProfile: boolean;

  //////////////////////////////////  Settings  ///////////////////////////////////////

  @observable public showSettings: boolean;

  ////////////////////////////////////  Home  /////////////////////////////////////////

  @observable public productsWithAccess: IProduct[] = [];
  @observable public selectedProductId: string = "";
  @observable public showAllPrograms: boolean;
  @observable public showAllEvents: boolean;

  @observable public shareContent: IShareContent;

  ////////////////////////////////////  Notes  /////////////////////////////////////////

  @observable public notes: INote[];

  /////////////////////////////////////  Push  /////////////////////////////////////////

  // @action public getOneSignalTags(): void {

  //   if (this.isMobile) {
  //     // PushService.getOneSignalUser(this.user?.username).then((oneSignalUser: IOneSignalUser) => {

  //     // });
  //   }
  //   console.log("getOneSignalTags");
  //   const tags = [];
  //   this.products.forEach((product: IProduct) => {
  //     if (product.productId) {
  //       ProductService.hasUserAccessToProduct(product.productId, this.user.username, product.accessPeriod).then((access: boolean) => {
  //         if (access) {
  //           tags.push({
  //             product: true
  //           });
  //           console.log(product.title);
  //           // LOOP
  //           ProductService.getTeamsForProduct(product.productId).then((teams: ITeam[]) => {
  //             if (teams && teams.length > 0) {
  //               teams.forEach((team: ITeam) => {
  //                 ProductService.hasUserAccessToProductAndTeam(product.productId, this.user.username, product.accessPeriod, team.team).then((teamAccess: boolean) => {
  //                   if (teamAccess) {
  //                     tags.push({
  //                       product.productId + " - " + team.objectId: true
  //                     });
  //                     console.log(product.productId + " - " + team.objectId);
  //                   }
  //                 });
  //               });
  //             }
  //           });
  //         }
  //       });
  //     }
  //   });
  // TODO: loop through programs and teams to get tags
  //}

  /////////////////////////////////////  Product and Event order panel  /////////////////////////////////////////

  @observable public showProductOrderPanel: boolean;
  @observable public showEventOrderPanel: boolean;
  @observable public showingProductOrderPopup: boolean;
  @observable public showingEventOrderPopup: boolean;

}