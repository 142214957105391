import * as React from 'react';
import './UserProductTeamPanel.css';
import { AppStore } from '../../../stores';
import { inject, observer } from 'mobx-react';
import ProductService from '../../../services/ProductService';
import { ITeam } from '../../../interfaces/ITeam';
import { IProductAccess } from '../../../interfaces/IProductAccess';

export interface IProps {
  appStore?: AppStore;
  productAccess: IProductAccess;
  update: () => void;
}

export interface IState {
  teamsToSelect: any[];
}

@inject('appStore')
@observer
export default class UserProductTeamPanel extends React.Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
      teamsToSelect: []
    }
  }

  public componentWillReceiveProps(props: IProps): void {
    if (props.productAccess !== undefined) {
      setTimeout(() => {
        this.getTeams(props.productAccess.product);
      }, 500);
    }
  }

  private getTeams(productId: string): void {
    const teamsToSelect = [];
    teamsToSelect.push(
      <option value={undefined}>Vælg hold</option>
    );
    ProductService.getTeamsForProduct(productId).then((teams: ITeam[]) => {
      if (teams) {
        teams.forEach((team: ITeam) => {
          teamsToSelect.push(
            <option value={team.team}>{team.team}</option>
          );
        });
        this.setState({ teamsToSelect });
      }
    });
  }

  render() {
    return (
      <div>
        <div
          className="userProductTeamPanelWrapper"
          style={{
            visibility: this.props.appStore.productAccess !== undefined ? "visible" : "hidden"
          }}
          onClick={() => {
            this.props.appStore.productAccess = undefined;
          }}
        />
        <div
          className="userProductTeamPanel"
          style={{
            right: this.props.appStore.productAccess !== undefined ? 0 : -620
          }}
        >
          {this.props.appStore.productAccess &&
            <div className="addUserAccess">
              <h3>Vælg hold:</h3>
              <select
                name="cars"
                id="cars"
                placeholder="Vælg et produkt"
                value={this.props.appStore.productAccess.team}
                onChange={(selectedOption: any) => {
                  this.props.appStore.productAccess.team = selectedOption.target.value !== "Vælg produkt" ? selectedOption.target.value : undefined;
                  ProductService.updateAccessToProduct(this.props.appStore.productAccess).then(() => {
                    this.props.appStore.productAccess = undefined;
                    this.props.update();
                  });
                }}
              >
                {this.state.teamsToSelect}
              </select>
            </div>
          }
        </div>
      </div>
    );
  }
}