import { IOneSignalUser } from "../interfaces/IOneSignalUser";
import { IPushResult } from "../interfaces/IPushResult";
import { IPushMessage } from "../interfaces/IPushMessage";

export default class PushService {

  public static getOneSignalUser(email: string): Promise<IOneSignalUser> {
    return new Promise((resolve) => {
      let oneSignalUserObject;
      if (localStorage.getItem(`Mindjuice_getOneSignalUser`) != undefined) {
        oneSignalUserObject = JSON.parse(localStorage.getItem(`Mindjuice_getOneSignalUser`));
      }
      if (oneSignalUserObject && oneSignalUserObject?.date && (new Date(oneSignalUserObject?.date).getUTCDate() === new Date().getUTCDate() && new Date(oneSignalUserObject?.date).getUTCMonth() === new Date().getUTCMonth())) {
        resolve(oneSignalUserObject?.oneSignalUser);
      } else {
        fetch(`https://mindjuice.herokuapp.com/getOneSignalUser/${email}`, {
          method: "GET",
          credentials: "same-origin",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }).then((response) => {
          if (!response.ok) {
            resolve(undefined);
          } else {
            localStorage.setItem(`Mindjuice_getOneSignalUser`, JSON.stringify({
              oneSignalUser: response.json(),
              date: new Date()
            }));
            return response.json();
          }
        }).then(response => {
          resolve(response);
        }).catch((error) => {
          console.log(error);
          resolve(undefined);
        });
      }
    });
  }

  public static getPushWithId(id: string): Promise<IPushResult> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/get/${id}`, {
        method: "GET",
        credentials: "same-origin",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        if (!response.ok) {
          resolve(undefined);
        } else {
          return response.json();
        }
      }).then(response => {
        resolve(response);
      }).catch((error) => {
        console.log(error);
        resolve(undefined);
      });
    });
  }

  public static sendPush(push: IPushMessage): Promise<void> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/send/`, {
        method: "POST",
        credentials: "same-origin",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(push)
      }).then((response) => {
        if (!response.ok) {
          resolve(undefined);
        } else {
          return response.json();
        }
      }).then(response => {
        resolve(response);
      }).catch((error) => {
        console.log(error);
        resolve(undefined);
      });
    });
  }

}