import * as React from 'react';
import './EnneagrammyProgressBar.css';
import { AppStore } from '../../../stores';
import { inject, observer } from 'mobx-react';

export interface IProps {
  appStore?: AppStore;
}

export interface IState {
  width: number;
}

@inject('appStore')
@observer
export default class EnneagrammyProgressBar extends React.Component<IProps, IState> {

  constructor(props, context) {
    super(props, context);
    this.state = {
      width: this.props.appStore.isMobile ? window.innerWidth - 50 :  window.innerWidth - 140
    }
    this.props.appStore.loadQuestions();
  }

  render() {
    const listItems = [];
    for (let index = 0; index < this.props.appStore.questions.length; index++) {
      if (this.props.appStore.currentQuestionIndex === index) {
        listItems.push(
          <div
            className="enneagrammyProgressBarItem current"
            key={index}
            style={{
              width: this.props.appStore.isMobile ? (window.innerWidth - 140) / 24 : (this.state.width) / 24,
              opacity: this.props.appStore.questions[index].answers[0].selected || this.props.appStore.questions[index].answers[1].selected ? 1 : 0.4,
              borderRadius: index === 0 ? "5px 0 0 5px" : (index === this.props.appStore.questions.length - 1 ? "0 5px 5px 0" : 0)
            }}
          />
        );
      } else {
        listItems.push(
          <div
            className="enneagrammyProgressBarItem"
            key={index}
            style={{
              width: this.props.appStore.isMobile ? (window.innerWidth - 140) / 24 : (this.state.width) / 24,
              opacity: this.props.appStore.questions[index].answers[0].selected || this.props.appStore.questions[index].answers[1].selected ? 1 : 0.4,
              borderRadius: index === 0 ? "5px 0 0 5px" : (index === this.props.appStore.questions.length - 1 ? "0 5px 5px 0" : 0)
            }}
            onClick={() => {
              this.props.appStore.currentQuestionIndex = index;
            }}
          />
        );
      }
    };
    return (
      !this.props.appStore.splitTest && this.props.appStore.testProgression !== "Name" &&
      <div
        className="enneagrammyProgressBar"
        id="enneagrammyProgressBar"
        style={{
          left: this.state.width ? `calc(50% - ${this.state.width / 2}px)` : 0
        }}
      >
        <div style={{
          color: "#ffffff",
          textAlign: "center",
          marginTop: -35,
          position: "relative",
          float: "left",
          width: "100%",
          fontSize: 18
        }}>
          {`${this.props.appStore.currentQuestionIndex + 1} / ${this.props.appStore.questions.length}`}
        </div>
        <div
        style={{
          clear: "both"
        }}
        >
        {listItems}
        </div>
      </div>

    );
  }

}
