import * as React from 'react';
import './podcastPlayer.css';
import { inject, observer } from 'mobx-react';
import { AppStore } from '../../../stores';
import ReactPlayer from 'react-player';
import PodcastService from '../../../services/PodcastService';
import { IPodcast } from '../../../interfaces/IPodcast';
import MeditationService from '../../../services/MeditationService';
import NativeService from '../../../services/NativeService';

export interface IProps {
  appStore?: AppStore;
  podcastId: string;
  closePlayer: () => void;
}

export interface IState {
  titlePosition: number;
  showTitle: boolean;
  playing: boolean;
  duration: number;
  progress: number;
  loading: boolean;
}

@inject('appStore')
@observer
export default class PodcastPlayer extends React.Component<IProps, IState> {

  private player: any;

  constructor(props, context) {
    super(props, context);
    this.state = {
      titlePosition: 0,
      showTitle: true,
      playing: false,
      duration: undefined,
      progress: 0,
      loading: true
    }
  }

  public componentWillReceiveProps(props: IProps): void {
    if (props.podcastId !== this.props.podcastId) {
      this.setState({
        progress: 0,
        loading: true
      })
      this.continue(props.podcastId);
    }
  }

  public componentDidMount(): void {
    this.animate();
  }

  private animate(): void {
    const PlayerTitleWrapper = document.getElementById("PlayerTitle");
    if (PlayerTitleWrapper) {
      if (this.state.titlePosition < -PlayerTitleWrapper.clientWidth) {
        this.setState({ showTitle: false });
        setTimeout(() => {
          const PlayerTitleWrapper = document.getElementById("PlayerTitleWrapper");
          if (PlayerTitleWrapper) {
            this.setState({ showTitle: true, titlePosition: PlayerTitleWrapper.clientWidth });
            this.animate();
          }
        }, 100);
      } else {
        setTimeout(() => {
          this.setState({ titlePosition: this.state.titlePosition - 30 });
          this.animate();
        }, 1000);
      }
    }
  }

  private continue(podcastId): void {
    let podcastProgress = localStorage.getItem(`podcast_${podcastId}`);
    setTimeout(() => {
      if (podcastProgress && this.player !== null && !this.state.loading) {
        this.setState({ progress: parseInt(podcastProgress.split("-")[0]) }, () => {
          this.player.seekTo(this.state.progress, "seconds");
          setTimeout(() => {
            this.forceUpdate();
          }, 100);
        })
      } else {
        this.continue(podcastId);
      }
    }, 100);
  }

  private convertSecondsToHHMMSS(secs: number): string {
    var hours = Math.floor(secs / 3600)
    var minutes = Math.floor(secs / 60) % 60
    var seconds = Math.floor(secs % 60)
    return [hours, minutes, seconds]
      .map(v => v < 10 ? "0" + v : v)
      .filter((v, i) => v !== "00" || i > 0)
      .join(":")
  }

  private saveProgress(): void {
    if (this.state.progress !== 0) {
      localStorage.setItem(`podcast_${this.props.podcastId}`, `${this.state.progress}-${this.state.duration}`);
    } else {
      localStorage.removeItem(`podcast_${this.props.podcastId}`);
    }
  }

  private onPlay(): void {
    this.setState({ playing: !this.state.playing }, () => {
      if (this.state.playing) {
        if (this.props.appStore.podcastToPlay.category === "Meditation") {
          MeditationService.getMeditation(this.props.appStore.podcastToPlay.objectId).then((meditation: IPodcast) => {
            if (meditation) {
              if (!meditation.views) {
                meditation.views = 1;
              } else {
                meditation.views = meditation.views + 1;
              }
              MeditationService.updateMeditation(meditation.objectId, meditation).then(() => {
                this.props.appStore.podcastToPlay = meditation;
              })
            }
          });
        } else {
          PodcastService.getPodcast(this.props.appStore.podcastToPlay.objectId).then((podcast: IPodcast) => {
            if (podcast) {
              if (!podcast.views) {
                podcast.views = 1;
              } else {
                podcast.views = podcast.views + 1;
              }
              PodcastService.updatePodcast(podcast.objectId, podcast).then(() => {
                this.props.appStore.podcastToPlay = podcast;
              })
            }
          });
        }
      }
    });
  }

  render() {
    console.log(this.state.progress);
    let url = this.props.appStore.podcastToPlay?.link;
    if (!url) {
      url = this.props.appStore.podcastToPlay?.videoUrl;
    }
    let imageUrl = this.props.appStore.podcastToPlay?.image;
    if (!imageUrl) {
      imageUrl = this.props.appStore.podcastToPlay?.imageUrl;
    }
    let innerHeight = this.props.appStore.isMobile ? window.innerHeight : window.innerHeight - 50;
    let videoWidth = (innerHeight - 250) / 9 * 16;
    if (videoWidth > innerWidth - 100) {
      videoWidth = innerWidth - 100;
    }
    if (this.props.appStore.isMobile) {
      videoWidth = screen.availWidth;
    }
    let videoHeight = videoWidth / 16 * 9;
    if (videoHeight > window.innerHeight - ((window.innerHeight - 200 - videoHeight) / 2) - 280) {
      videoHeight = window.innerHeight - ((window.innerHeight - 200 - videoHeight) / 2) - 280;
      videoWidth = videoHeight / 9 * 16;
    }
    let videoTop = (window.innerHeight - 200 - videoHeight) / 2;
    if (this.props.appStore.isMobile) {
      videoTop = 135;
    }
    return (
      <>
        {this.props.appStore.podcastToPlay &&
          <div
            style={{
              width: this.props.appStore.showPodcastPlayerInFullScreen ? videoWidth : (this.props.appStore.isMobile ? 0 : 500),
              height: this.props.appStore.showPodcastPlayerInFullScreen ? videoHeight : (this.props.appStore.isMobile ? 0 : (500 / 16 * 9)),
              overflow: "hidden",
              position: "fixed",
              display: this.props.appStore.podcastToPlay?.link || this.props.appStore.podcastToPlay?.hideVideo ? "none" : "block",
              top: this.props.appStore.showPodcastPlayerInFullScreen ? videoTop : "unset",
              left: this.props.appStore.showPodcastPlayerInFullScreen ? `calc(50% - ${videoWidth / 2}px)` : "unset",
              bottom: this.props.appStore.showPodcastPlayerInFullScreen ? "unset" : 101,
              right: this.props.appStore.showPodcastPlayerInFullScreen ? "unset" : 0,
              zIndex: 3000001
            }}
          >
            <ReactPlayer
              ref={(player: any) => this.player = player}
              playing={this.state.playing}
              url={url}
              title={this.props.appStore.podcastToPlay?.title}
              playsinline
              poster={imageUrl}
              onProgress={(progress: { played: number, playedSeconds: number, loaded: number, loadedSeconds: number }) => {
                if (this.state.playing) {
                  this.setState({ progress: progress.playedSeconds });
                } else {
                  this.saveProgress();
                }
              }}
              onEnded={() => {
                console.log("PLayer ended");
                this.setState({ playing: false, progress: 0 }, () => {
                  this.player.seekTo(this.state.progress, "seconds");
                })
              }}
              onPlay={() => this.setState({ playing: true })}
              onError={(error) => {
                console.log("Player error:");
                console.log(error);
                this.setState({ playing: false })
                this.forceUpdate();
              }}
              onReady={() => {
                console.log("PLayer ready");
                this.setState({ loading: false })
              }}
              onDuration={(duration) => this.setState({ duration })}
              width='100%'
              height='100%'
              controls
            />
          </div>
        }
        <>
          {this.props.appStore.showPodcastPlayerInFullScreen &&
            // (this.props.appStore.isMobile ?
            <div
              className="podcastPlayer"
              id="myPlayer"
              style={{
                top: this.props.appStore.podcastToPlay ? (this.props.appStore.isMobile ? 0 : 50) : screen.availHeight,
                backgroundColor: this.props.appStore.podcastToPlay?.backgroundColor ? this.props.appStore.podcastToPlay?.backgroundColor : "#202020"
              }}
            >
              {url &&
                <>
                  {(this.props.appStore.podcastToPlay?.link || this.props.appStore.podcastToPlay.hideVideo) &&
                    <div
                      className="podcastPlayerCover"
                      style={{
                        backgroundImage: `url(${imageUrl})`,
                        height: window.innerHeight / 2,
                        width: window.innerHeight / 2,
                        marginTop: (window.innerHeight / 2) - (window.innerHeight / 2.5),
                        marginLeft: (window.innerWidth / 2) - (window.innerHeight / 4),
                      }}
                    />
                  }
                  <div
                    style={{
                      width: this.props.appStore.podcastToPlay?.link || this.props.appStore.podcastToPlay.hideVideo ? 0 : (this.props.appStore.isMobile ? screen.availWidth - 40 : 600),
                      height: this.props.appStore.podcastToPlay?.link || this.props.appStore.podcastToPlay.hideVideo ? 0 : (this.props.appStore.isMobile ? (screen.availWidth - 40) / 16 * 9 : 600 / 16 * 9),
                      overflow: "hidden",
                      position: "relative",
                      display: this.props.appStore.podcastToPlay?.link || this.props.appStore.podcastToPlay.hideVideo ? "none" : "block",
                      marginTop: "20vh",
                      marginLeft: this.props.appStore.isMobile ? 20 : "calc(50% - 300px)"
                    }}
                  >
                  </div>
                  <div className='fullscreenControls'>
                    <div
                      className="podcastPlayerTitle"
                      style={{
                        marginLeft: this.state.titlePosition
                      }}
                    >
                      {this.props.appStore.podcastToPlay?.title}
                    </div>
                    <div
                      className="podcastPlayerSpeaker"
                      style={{
                        marginLeft: this.state.titlePosition
                      }}
                    >
                      {this.props.appStore.podcastToPlay?.speaker}
                    </div>
                    <div className='podcastPlayerProgressLabel'>{this.convertSecondsToHHMMSS(this.state.progress)}</div>
                    <input
                      type="range"
                      min="0"
                      max={this.state.duration}
                      value={this.state.progress}
                      className="podcastPlayerDurationSlider"
                      onChange={(event) => {
                        this.setState({ progress: parseInt(event.target.value) });
                        this.player.seekTo(event.target.value, "seconds");
                      }}
                    />
                    <div className='podcastPlayerDurationLabel'>{this.state.duration ? this.convertSecondsToHHMMSS(this.state.duration) : "-"}</div>
                    <div className='podcastPlayerNavigation'>
                      <div
                        className='podcastPlayerBackButton'
                        style={{
                          opacity: this.state.progress > 15 ? 1 : 0.3
                        }}
                        onClick={() => {
                          if (this.state.progress > 15) {
                            this.setState({ progress: this.state.progress - 15 }, () => {
                              this.player.seekTo(this.state.progress, "seconds");
                            });
                          }
                        }}
                      />
                      <div
                        className='podcastPlayerPlayButton'
                        style={{
                          backgroundImage: this.state.playing ? 'url("https://dojo.mindjuice.com/images/pause-button.png")' : 'url("https://dojo.mindjuice.com/images/play-button.png")'
                        }}
                        onClick={() => {
                          this.onPlay();
                        }}
                      />
                      <div
                        className='podcastPlayerForwardButton'
                        onClick={() => {
                          this.setState({ progress: this.state.progress + 15 }, () => {
                            this.player.seekTo(this.state.progress, "seconds");
                          });
                        }}
                      />
                    </div>
                  </div>
                </>
              }
              {this.state.loading &&
                <div className="podcastPlayerLoaderBg">
                  <div className="podcastPlayerLoader" />
                </div>
              }
              <div
                className='closeButton'
                onClick={() => {
                  this.props.closePlayer();
                  this.saveProgress();
                  setTimeout(() => {
                    this.setState({ loading: true, duration: undefined, progress: 0, playing: false });
                  }, 200);
                }}
              />
              <div
                className='minimize'
                style={{
                  backgroundImage: (this.props.appStore.podcastToPlay?.link || this.props.appStore.podcastToPlay?.hideVideo) ? `url("https://dojo.mindjuice.com/images/minimize-dark.png")` : `url("https://dojo.mindjuice.com/images/pip-dark.png")`
                }}
                onClick={() => {
                  this.props.appStore.showPodcastPlayerInFullScreen = false;
                }}
              />
            </div>
            //   :
            //   <div
            //     className="podcastPlayer"
            //     id="myPlayer"
            //     style={{
            //       top: this.props.appStore.podcastToPlay ? 0 : screen.availHeight,
            //       backgroundColor: this.props.appStore.podcastToPlay?.backgroundColor ? this.props.appStore.podcastToPlay?.backgroundColor : "#202020"
            //     }}
            //   >
            //     {url &&
            //       <>
            //         {(this.props.appStore.podcastToPlay?.link || this.props.appStore.podcastToPlay.hideVideo) &&
            //           <>
            //             <div
            //               className="podcastPlayerCover"
            //               style={{
            //                 backgroundImage: `url(${imageUrl})`
            //               }}
            //             />
            //           </>
            //         }
            //         {/* Player */}
            //       </>
            //     }
            //     {this.state.loading &&
            //       <div className="podcastPlayerLoaderBg">
            //         <div className="podcastPlayerLoader" />
            //       </div>
            //     }
            //     <div
            //       className='closeButton'
            //       onClick={() => {
            //         this.props.closePlayer();
            //         this.saveProgress();
            //         setTimeout(() => {
            //           this.setState({ loading: true, duration: undefined, progress: 0, playing: false });
            //         }, 200);
            //       }}
            //     />
            //     <div
            //       className='minimize'
            //       onClick={() => {
            //         this.props.appStore.showPodcastPlayerInFullScreen = false;
            //       }}
            //     />
            //   </div>
            // )
          }
          <div
            className="podcastPlayerBar"
            id="myPlayer"
            style={{
              bottom: this.props.appStore.podcastToPlay && !this.props.appStore.showPodcastPlayerInFullScreen ? (this.props.appStore.isMobile ? (this.props.appStore.selectedProductId === "" ? 78 : 0) : 0) : -100
            }}
          >
            {!this.props.appStore.showPodcastPlayerInFullScreen &&
              <div
                className='closeButton'
                onClick={() => {
                  this.props.closePlayer();
                  this.saveProgress();
                  setTimeout(() => {
                    this.setState({ loading: true, duration: undefined, progress: 0, playing: false });
                  }, 200);
                }}
              />
            }
            {!this.props.appStore.showPodcastPlayerInFullScreen &&
              <div
                className='enlarge'
                onClick={() => {
                  this.props.appStore.showPodcastPlayerInFullScreen = true;
                }}
              />
            }
            {!this.props.appStore.showPodcastPlayerInFullScreen && !this.props.appStore.isMobile &&
              <div
                className='share'
                onClick={() => {
                  if (window.platform === "ios" || window.platform === "android") {
                    setTimeout(() => {
                      NativeService.share(window.loc.strings.sharePodcast, `https://dojo.mindjuice.com/metadata.php?&title=${encodeURIComponent(this.props.appStore.podcastToPlay.title)}&description=${encodeURIComponent(this.props.appStore.podcastToPlay.teaser)}&image=${encodeURIComponent(this.props.appStore.podcastToPlay.image)}&url=${encodeURIComponent(location.href)}`)
                    }, 100);
                  } else {
                    this.props.appStore.shareContent = {
                      title: this.props.appStore.podcastToPlay.title,
                      description: this.props.appStore.podcastToPlay.teaser,
                      image: this.props.appStore.podcastToPlay.image
                    }
                  }
                }}
              />
            }
            <div className='podcastPlayerBarCover'>
              <div
                className="podcastPlayerBarCoverImage"
                style={{
                  backgroundImage: `url(${imageUrl})`
                }}
              />
              <div className="playerTitle">
                {this.props.appStore?.podcastToPlay?.title}
              </div>
              <div className='playerAuthor'>
                {this.props.appStore?.podcastToPlay?.speaker}
              </div>
            </div>
            {this.props.appStore.isMobile ?
              (!this.props.appStore.showPodcastPlayerInFullScreen &&
                <div
                  className='podcastPlayerPlayButton'
                  style={{
                    backgroundImage: this.state.playing ? 'url("https://dojo.mindjuice.com/images/pause-button.png")' : 'url("https://dojo.mindjuice.com/images/play-button.png")'
                  }}
                  onClick={() => {
                    this.onPlay();
                  }}
                />
              )
              :
              <div
                className='podcastPlayerBarControls'
                style={{
                  opacity: this.state.loading ? 0.4 : 1,
                  userSelect: this.state.loading ? "none" : "auto"
                }}
              >
                <div className='podcastPlayerNavigation'>
                  <div
                    className='podcastPlayerBackButton'
                    style={{
                      opacity: this.state.progress > 15 ? 1 : 0.3
                    }}
                    onClick={() => {
                      if (this.state.progress > 15) {
                        this.setState({ progress: this.state.progress - 15 }, () => {
                          this.player.seekTo(this.state.progress, "seconds");
                        });
                      }
                    }}
                  />
                  <div
                    className='podcastPlayerPlayButton'
                    style={{
                      backgroundImage: this.state.playing ? 'url("https://dojo.mindjuice.com/images/pause-button.png")' : 'url("https://dojo.mindjuice.com/images/play-button.png")'
                    }}
                    onClick={() => {
                      this.onPlay();
                    }}
                  />
                  <div
                    className='podcastPlayerForwardButton'
                    onClick={() => {
                      this.setState({ progress: this.state.progress + 15 }, () => {
                        this.player.seekTo(this.state.progress, "seconds");
                      });
                    }}
                  />
                </div>
                <div className='podcastPlayerProgressLabel'>{this.convertSecondsToHHMMSS(this.state.progress)}</div>
                <input
                  type="range"
                  min="0"
                  max={this.state.duration}
                  value={this.state.progress}
                  className="podcastPlayerDurationSlider"
                  onChange={(event) => {
                    this.setState({ progress: parseInt(event.target.value) }, () => {
                      this.player.seekTo(event.target.value, "seconds");
                    });
                  }}
                />
                <div className='podcastPlayerDurationLabel'>{this.state.duration ? this.convertSecondsToHHMMSS(this.state.duration) : "-"}</div>
              </div>
            }
            {this.props.appStore.isMobile &&
              <progress
                max={this.state.duration}
                value={this.state.progress}
              />
            }
          </div>
        </>
      </>
    )
  }
}