import * as React from 'react';
import './Enneagrammy.css';
import { AppStore } from '../stores';
import { inject, observer } from 'mobx-react';
import Intro from './intro/EnneagrammyIntro';
import TestFlow from './flow/EnneagrammyFlow';
import { Route, Switch } from 'react-router-dom';
import TabBar from './tabBar/EnneagrammyTabBar';
import Login from './login/EnneagrammyLogin';
import About from './about/EnneagrammyAbout';
import ExtendedMenu from './extendedMenu/ExtendedMenu';
import TypeTestProfile from './profile/TypeTestProfile';
import TypeTestResults from './results/TypeTestResults';
import TypeTestTypeInfoPopup from './typeInfoPopup/EnneagrammyTypeInfoPopup';
import TypeTestShop from './shop/EnneagrammyShop';
import TypeTestUserProfile from './profileSettings/EnneagrammyProfile';
import SideBar from './sideBar/SideBar';
import SubscriptionService from '../services/SubscriptionService';
import ProductService from '../services/ProductService';
import NativeService from '../services/NativeService';

export interface IProps {
  appStore?: AppStore;
}

export interface IState {
}

@inject('appStore')
@observer
export default class Enneagrammy extends React.Component<IProps, IState> {

  public componentDidMount(): void {
    this.props.appStore.updateBackground();
    let shouldReload = false;
    if (!localStorage.getItem("typeTestAccess")) {
      shouldReload = true;
    }
    this.props.appStore.checkAccessDone = false;
    this.props.appStore.userStillHasAccess = false;
    this.isCheckingAccess();
    // Check access via MongoDB
    ProductService.productAccess("19067", this.props.appStore.user.username).then((enneagrammy: any) => {
      if (enneagrammy?.results?.length > 0) {
        localStorage.setItem("typeTestAccess", "hasAccess");
        this.props.appStore.isWaitingForApple = false;
        this.props.appStore.subscription = "accessFromAdmin";
        this.props.appStore.selectedSubscription = "accessFromAdmin";
        this.props.appStore.isRestoringSubscription = false;
        this.props.appStore.userStillHasAccess = true;
        this.props.appStore.checkAccessDone = true;
        console.log("%cUser has access from MongoDB", 'background: orange; color: #272727');
        if (shouldReload) {
          location.reload();
        }
      } else {
        ProductService.productAccess("17827", this.props.appStore.user.username).then((enneagrammy: any) => {
          if (enneagrammy?.results?.length > 0) {
            localStorage.setItem("typeTestAccess", "hasAccess");
            this.props.appStore.isWaitingForApple = false;
            this.props.appStore.subscription = "accessFromAdmin";
            this.props.appStore.selectedSubscription = "accessFromAdmin";
            this.props.appStore.isRestoringSubscription = false;
            this.props.appStore.userStillHasAccess = true;
            this.props.appStore.checkAccessDone = true;
            console.log("%cUser has access from MongoDB", 'background: orange; color: #272727');
            if (shouldReload) {
              location.reload();
            }
          } else {
            // User does not have access from MongoDB and will check Reepay
            SubscriptionService.getCustomer(this.props.appStore.user?.username, "production").then((result) => {
              console.log(result);
              if (result?.content?.length > 0) {
                let customerHandle = result.content[0].handle;
                SubscriptionService.get(customerHandle, "production").then((result) => {
                  console.log(result);
                  if (result?.content?.length > 0) {
                    result?.content.forEach((content: any) => {
                      if ((content?.plan === "plan-36feb" || content?.plan === "plan-82c17") && content?.state === "active") {
                        if (content?.plan === "plan-36feb") {
                          this.props.appStore.selectedSubscription = "enneagramapp.mindjuice.dk.monthlySubscription";
                          this.props.appStore.subscription = "enneagramapp.mindjuice.dk.monthlySubscription";
                        }
                        if (content?.plan === "plan-82c17") {
                          this.props.appStore.selectedSubscription = "enneagramapp.mindjuice.dk.yearlySubscription";
                          this.props.appStore.subscription = "enneagramapp.mindjuice.dk.yearlySubscription";
                        }
                        this.props.appStore.isShowingSubscriptionPopup = false;
                        localStorage.setItem("typeTestAccess", "hasAccess");
                        this.props.appStore.isWaitingForApple = false;
                        this.props.appStore.isRestoringSubscription = false;
                        this.props.appStore.userStillHasAccess = true;
                        this.props.appStore.checkAccessDone = true;
                        console.log("%cUser has access from Reepay", 'background: orange; color: #272727');
                        if (shouldReload) {
                          location.reload();
                        }
                      }
                    });
                  } else {
                    this.props.appStore.getCustomerHandle().then((customerHandle: string) => {
                      if (customerHandle) {
                        ProductService.getCustomerInvoice(customerHandle).then((invoice) => {
                          if (invoice?.content?.length > 0 && invoice.content[0].order_lines?.length > 0) {
                            invoice.content[0].order_lines.forEach((orderLine) => {
                              if ("Kender du typen?" === orderLine?.ordertext) {
                                this.props.appStore.isShowingSubscriptionPopup = false;
                                localStorage.setItem("typeTestAccess", "hasAccess");
                                this.props.appStore.isWaitingForApple = false;
                                this.props.appStore.isRestoringSubscription = false;
                                this.props.appStore.userStillHasAccess = true;
                                this.props.appStore.checkAccessDone = true;
                              }
                            });
                          } else {
                            // User does not have access from MongoDB either check Apple if iOS
                            if (window.platform === "ios") {
                              NativeService.isUserSubscribed();
                            }
                          }
                        })
                      }
                    });
                    // User does not have access from MongoDB either check Apple if iOS
                    if (window.platform === "ios") {
                      NativeService.isUserSubscribed();
                    }
                  }
                });
              } else {
                // User does not have access from MongoDB either check Apple if iOS
                SubscriptionService.getCustomer(this.props.appStore.user?.username, "test").then((result) => {
                  console.log(result);
                  if (result?.content?.length > 0) {
                    let customerHandle = result.content[0].handle;
                    SubscriptionService.get(customerHandle, "test").then((result) => {
                      console.log(result);
                      if (result?.content?.length > 0) {
                        result?.content.forEach((content: any) => {
                          if (content?.plan === "plan-b38ea" && content?.state === "active") {
                            this.props.appStore.selectedSubscription = "enneagramapp.mindjuice.dk.yearlySubscription";
                            this.props.appStore.subscription = "enneagramapp.mindjuice.dk.yearlySubscription";
                            this.props.appStore.isShowingSubscriptionPopup = false;
                            localStorage.setItem("typeTestAccess", "hasAccess");
                            this.props.appStore.isWaitingForApple = false;
                            this.props.appStore.isRestoringSubscription = false;
                            this.props.appStore.userStillHasAccess = true;
                            this.props.appStore.checkAccessDone = true;
                            console.log("%cUser has access from Reepay TEST", 'background: orange; color: #272727');
                            if (shouldReload) {
                              location.reload();
                            }
                          }
                        });
                      } else {
                        // User does not have access from MongoDB either check Apple if iOS
                        if (window.platform === "ios") {
                          NativeService.isUserSubscribed();
                        }
                      }
                    });
                  } else {
                    // User does not have access from MongoDB either check Apple if iOS
                    if (window.platform === "ios") {
                      NativeService.isUserSubscribed();
                    }
                  }
                });
                if (window.platform === "ios") {
                  NativeService.isUserSubscribed();
                }
              }
            });
          }
        });
      }
    });
  }

  private isCheckingAccess(): void {
    if (this.props.appStore.checkAccessDone) {
      if (!this.props.appStore.userStillHasAccess) {
        localStorage.removeItem("typeTestAccess");
        this.props.appStore.subscription = undefined;
      }
    } else {
      setTimeout(() => {
        this.isCheckingAccess();
      }, 1000);
    }
  }

  render() {
    return (
      <>
        <div
          className="enneagrammy"
          style={{
            overflow: !this.props.appStore.isTesting && this.props.appStore.user ? "auto" : undefined,
            height: this.props.appStore.user && this.props.appStore.isMobile ? "calc(100% - 80px)" : "100%",
            left: this.props.appStore.isMobile ? 0 : 250,
            width: this.props.appStore.isMobile ? "100%" : "calc(100% - 250px)",
          }}
        >
          <Switch>
            <Route path={`${this.props.appStore.environment}/enneagrammy/Results`}>
              <TypeTestResults />
            </Route>
            <Route path={`${this.props.appStore.environment}/enneagrammy/Profile`}>
              <TypeTestProfile />
            </Route>
            <Route path={`${this.props.appStore.environment}/enneagrammy/TypeTestAbout`}>
              <About />
            </Route>
            <Route exact path={`${this.props.appStore.environment}/enneagrammy`}>
              <div>
                {this.props.appStore.user ?
                  <Intro />
                  :
                  <Login />
                }
              </div>
            </Route>
            <Route exact path={`${this.props.appStore.environment}/typetest`}>
              <div>
                {this.props.appStore.user ?
                  <Intro />
                  :
                  <Login />
                }
              </div>
            </Route>
          </Switch>
          <TestFlow />
          {this.props.appStore.selectedType &&
            <TypeTestTypeInfoPopup />
          }
        </div>
        {this.props.appStore.isMobile &&
          <ExtendedMenu />
        }
        {this.props.appStore.user && !this.props.appStore.isTesting && !this.props.appStore.selectedType &&
          (this.props.appStore.isMobile ?
            <TabBar />
            :
            <SideBar />
          )
        }
        {this.props.appStore.isShowingSubscriptionPopup &&
          <TypeTestShop />
        }
        {this.props.appStore.isShowingUserProfilePopup &&
          <TypeTestUserProfile />
        }
      </>
    );
  }
}