import * as React from 'react';
import './IconSelector.css';
import { Icon, Icons } from '../icon';

export interface IIconSelectorProps {
  selectedIcon: string;
  color: string;
  label: string;
  styles?: any;
  showTitle?: boolean;
  shouldBeAbleToClear: boolean;
  select: (icon: string) => void;
  clear: () => void;
}

export interface IIconSelectorState {
  showIconPicker: boolean;
  searchQuery: string;
}

export class IconSelector extends React.Component<IIconSelectorProps, IIconSelectorState> {

  constructor(props: IIconSelectorProps) {
    super(props);
    this.state = {
      showIconPicker: false,
      searchQuery: ''
    }
    
  }

  public render(): JSX.Element {
    let icons = [];
    let allIcons: string[];
    if (!this.state.searchQuery) {
      allIcons = Icons.getAll();
    }
    else {
      allIcons = Icons.withTag(this.state.searchQuery);
    }
    allIcons.forEach((iconTitle: string) => {
      icons.push(
        <div
          key={`icon_${iconTitle}`}
          className="iconSelectorIconToSelect"
          onClick={() => {
            this.setState({ showIconPicker: false });
            this.props.select(iconTitle);
          }}
        >
          <Icon
            title={iconTitle}
            size={24}
            color={"#000000"}
            style={{
              marginLeft: 20,
              outline: "none"
            }}
          />
          {this.props.showTitle &&
            <div className="iconSelectorIconToSelectTitle">
              {iconTitle}
            </div>
          }
        </div>
      )
    });
    return (
      <div className="iconSelector" style={this.props.styles}>
        <label>{this.props.label}</label>
        <div
          className="iconSelectorIcon"
          style={{
            backgroundColor: this.props.selectedIcon != undefined ? this.props.color : "#cccccc"
          }}
          onClick={() => this.setState({ showIconPicker: true })}
        >
          <Icon
            title={this.props.selectedIcon != undefined ? this.props.selectedIcon : "Unavailable"}
            size={24}
            color={"#ffffff"}
          />
        </div>
        {this.props.shouldBeAbleToClear &&
          <div
            className="iconSelectorIconDeselect"
            onClick={() => this.props.clear()}
          >
            <Icon
              title="Close"
              size={16}
              color={"red"}
            />
          </div>
        }
        {this.state.showIconPicker &&
          <div className="iconSelectorWrapper">
            <div
              className="iconSelectorBackground"
              onClick={() => this.setState({ showIconPicker: false, searchQuery: '' })}
            >
            </div>
            <div
              className="iconSelectorBox"
            >
              <div className="iconSelectorSearchBox"
                onClick={() => {
                  this.setState({ showIconPicker: true });
                }}
              >
                <input type="text" className="iconSelectorSearchField" placeholder="Søg"
                  value={this.state.searchQuery}
                  onChange={(evt) => {
                    this.setState({ searchQuery: evt.target.value });
                  }} />
                {this.state.searchQuery &&
                  <div
                    className="iconSelectorSearchClear"
                    onClick={() => this.setState({ searchQuery: '' })}
                  >
                    <Icon
                      title="Close"
                      size={14}
                      color={"#000000"}
                    />
                  </div>
                }
              </div>
              <div className="iconSelectorIconBox">
                {icons}
                {this.state.searchQuery && icons.length == 0 &&
                  <div className="iconSelectorNoContent">
                    Der er ikke nogen ikoner, som passer til din søgning
                  </div>
                }
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}