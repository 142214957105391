import * as React from 'react';
import { IModuleDocument } from '../../../interfaces/IModuleDocument';
import AdminService from '../../../services/AdminService';
import ProductService from '../../../services/ProductService';
import { FilePicker } from '../filePicker/FilePicker';
import { IconSelector } from '../iconSelector/IconSelector';
import { IAPivot } from '../pivot/Pivot';
import '../../../admin/products/productAccessPanel/ProductAccessPanel.css'
import { inject, observer } from 'mobx-react';
import { AppStore } from '../../../stores';
import MindPaperEditPanel from '../../../admin/products/productEditPanel/mindPaperEditPanel/MindPaperEditPanel';
import { Icon } from '../icon';
import { Pivot } from '../../pivot/Pivot';

export interface IProps {
  appStore?: AppStore;
  document: IModuleDocument
  disableMoveUp?: boolean;
  disableMoveDown?: boolean;
  isMobile: boolean;
  selectedLanguage?;
  onDelete?: () => void;
  onMoveUp?: () => void;
  onMoveDown?: () => void;
  onChange: (document: IModuleDocument) => void;
}

export interface IState {
  document?: IModuleDocument;
  selectedType: any;
  availablemindPapers: any[];
}

@inject('appStore')
@observer
export default class DocumentPicker extends React.Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
      document: Object.assign({}, props.document),
      selectedType: props.document?.mindPaperId ? "mindPaper" : "file",
      availablemindPapers: []
    }
    this.getMindPapers();
  }

  public componentWillReceiveProps(props: IProps): void {
    if (props.document !== this.props.document) {
      this.setState({
        document: Object.assign({}, props.document),
      });
    }
  }

  private getMindPapers(selectObjectId?: string): void {
    ProductService.getMindPapers().then((mindPapers: any[]) => {
      this.props.appStore.mindPapers = mindPapers;
      if (selectObjectId) {
        let document = this.state.document;
        document.mindPaperId = selectObjectId;
        this.setState({ document });
      }
      this.loadMindPapers();
    });
  }

  private loadMindPapers(): void {
    const availablemindPapers = [];
    availablemindPapers.push(
      <option value="all">Vælg MindPaper</option>
    );
    this.setState({ availablemindPapers });
    if (this.props.appStore.mindPapers) {
      this.props.appStore.mindPapers.forEach((mindPaper: any) => {
        if (this.state.document?.mindPaperId === mindPaper.objectId) {
          availablemindPapers.push(
            <option value={mindPaper.objectId} selected>{mindPaper.title}</option>
          );
        } else {
          availablemindPapers.push(
            <option value={mindPaper.objectId}>{mindPaper.title}</option>
          );
        }
      });
      this.setState({ availablemindPapers });
      this.forceUpdate();
    }
  }

  public isYouTubeUrl(url: string): boolean {
    const p = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    if (url.match(p)) {
      return true;
    }
    return false;
  }

  public isVimeoVideo(url: string): boolean {
    return url.indexOf("vimeo") !== -1;
  }

  public convertUrlToEmbedUrl(url: string): string {
    if (this.isYouTubeUrl(url)) {
      if (url.indexOf("embed") !== -1) {
        return url;
      } else {
        const rx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
        const youtubeId: string = url.match(rx)[1];
        return "https://www.youtube.com/embed/" + youtubeId;
      }
    } else if (this.isVimeoVideo(url)) {
      const urlParts = url.split("/");
      const vimeoId = urlParts[urlParts.length - 1];
      return "https://player.vimeo.com/video/" + vimeoId;
    } else {
      return url;
    }
  }

  render() {
    let mindpaperId;
    if (this.props.selectedLanguage === "danish") {
      mindpaperId = this.state.document?.mindPaperId;
    } else {
      if (this.state.document.localizedMindPaperId) {
        mindpaperId = this.state.document.localizedMindPaperId[this.props.selectedLanguage];
      }
    }
    return (
      <div style={{
        border: "1px solid #cccccc",
        padding: 10,
        boxSizing: "border-box",
        float: "left",
        width: "100%",
        marginBottom: 10
      }}>
        <IAPivot
          highlightColor={"#ffffff"}
          items={[
            {
              key: 'mindPaper',
              text: "MindPaper"
            },
            {
              key: 'file',
              text: "Fil"
            }
          ]}
          asTabs
          tabBackgrondColor="lightGray"
          selectedTabBackgrondColor="#7a12d4"
          selectedKey={this.state.selectedType}
          select={(selectedTab: "mindPaper" | "file") => {
            this.setState({ selectedType: selectedTab });
          }}
          style={{
            width: this.props.appStore.isMobile ? "100%" : "40%",
            marginTop: 0,
            height: 30,
            marginBottom: 10,
            textAlign: "center",
            position: "relative",
            float: "left"
          }}
          isMobile={this.props.appStore.isMobile}
        />
        {this.state.selectedType === "mindPaper" ?
          <>
            <select
              name="cars"
              id="cars"
              placeholder="Vælg et MindPaper"
              value={mindpaperId}
              onChange={(selectedOption: any) => {
                let document = this.state.document;
                if (this.props.selectedLanguage === "danish") {
                  document.mindPaperId = selectedOption.target.value;
                } else {
                  if (!document.localizedMindPaperId) {
                    document.localizedMindPaperId = {};
                  }
                  document.localizedMindPaperId[this.props.selectedLanguage] = selectedOption.target.value;
                }
                this.setState({ document });
                this.forceUpdate();
              }}
              style={{
                width: "calc(100% - 240px)",
                clear: "both"
              }}
            >
              {this.state.availablemindPapers}
            </select>
            <button
              onClick={() => {
                this.props.appStore.mindPaperToEdit = {
                  document: true
                };
              }}
              style={{
                marginLeft: 20,
                marginRight: 0,
                width: 100,
                float: "left"
              }}
            >
              Tilføj nyt MindPaper
            </button>
            <button
              disabled={!mindpaperId}
              onClick={() => {
                ProductService.getMindPaper(mindpaperId).then((mindPaperToEdit) => {
                  mindPaperToEdit.document = true
                  this.props.appStore.mindPaperToEdit = mindPaperToEdit;
                })
              }}
              style={{
                marginLeft: 20,
                marginRight: 0,
                width: 100,
                float: "left"
              }}
            >
              Rediger valgt Mindpaper
            </button>
          </>
          :
          <FilePicker
            url={this.state.document ? (this.props.selectedLanguage === "danish" ? this.state.document.url : (this.state.document.localizedUrl && this.state.document.localizedUrl[this.props.selectedLanguage] ? this.state.document.localizedUrl[this.props.selectedLanguage] : "")) : ""}
            placeholderText={"Indsæt et link eller upload en fil..."}
            inputWidth={this.props.isMobile ? "79%" : "87%"}
            onChange={(url: string) => {
              const splittedUrl = url.split(".");
              let fileType = splittedUrl[splittedUrl.length - 1];
              let document = this.state.document;
              if (this.props.selectedLanguage === "danish") {
                document.url = this.convertUrlToEmbedUrl(url);
              } else {
                if (!document.localizedUrl) {
                  document.localizedUrl = {};
                }
                document.localizedUrl[this.props.selectedLanguage] = this.convertUrlToEmbedUrl(url);
              }
              document.fileType = fileType;
              this.setState({ document });
            }}
          />
        }
        <input
          type="text"
          value={this.state.document ? (this.props.selectedLanguage === "danish" ? this.state.document.documentTitle : (this.state.document.localizedDocumentTitle && this.state.document.localizedDocumentTitle[this.props.selectedLanguage] ? this.state.document.localizedDocumentTitle[this.props.selectedLanguage] : "")) : ""}
          onChange={(event: any) => {
            let document = this.state.document;
            if (this.props.selectedLanguage === "danish") {
              document.documentTitle = event.target.value;
            } else {
              if (!document.localizedDocumentTitle) {
                document.localizedDocumentTitle = {};
              }
              document.localizedDocumentTitle[this.props.selectedLanguage] = event.target.value;
            }
            this.setState({ document });
            console.log(JSON.stringify(this.props.document) + " === " + JSON.stringify(this.state.document));
          }}
          placeholder="Title"
        />
        <IconSelector
          label='Vælg et ikon'
          selectedIcon={this.state.document ? (this.props.selectedLanguage === "danish" ? this.state.document.icon : (this.state.document.localizedIcon && this.state.document.localizedIcon[this.props.selectedLanguage] ? this.state.document.localizedIcon[this.props.selectedLanguage] : undefined)) : undefined}
          color='#272727'
          shouldBeAbleToClear
          select={(icon) => {
            let document = this.state.document;
            if (this.props.selectedLanguage === "danish") {
              document.icon = icon;
            } else {
              if (!document.localizedIcon) {
                document.localizedIcon = {};
              }
              document.localizedIcon[this.props.selectedLanguage] = icon;
            }
            this.setState({ document });
          }}
          clear={() => {
            let document = this.state.document;
            document.icon = undefined;
            this.setState({ document });
          }}
        />
        {this.state.selectedType === "file" &&
          <>
            <div className="flowpaper">
              <input
                type="checkbox"
                checked={this.state.document.openInSameWindow}
                onChange={() => {
                  let document = this.state.document;
                  document.openInSameWindow = this.state.document.openInSameWindow != undefined ? !this.state.document.openInSameWindow : true;
                  if (document.openInPopup) {
                    document.openInPopup = false;
                  }
                  if (document.openEmbedded) {
                    document.openEmbedded = false;
                  }
                  this.setState({ document });
                }}
                style={{
                  width: 20,
                  marginTop: -2
                }}
              />
              Åben i samme fane
            </div>
            <div className="flowpaper">
              <input
                type="checkbox"
                checked={this.state.document.openInPopup}
                onChange={() => {
                  let document = this.state.document;
                  document.openInPopup = this.state.document.openInPopup != undefined ? !this.state.document.openInPopup : true;
                  if (document.openInSameWindow) {
                    document.openInSameWindow = false;
                  }
                  if (document.openEmbedded) {
                    document.openEmbedded = false;
                  }
                  this.setState({ document });
                }}
                style={{
                  width: 20,
                  marginTop: -2
                }}
              />
              Åben i popup
            </div>
            <div className="flowpaper">
              <input
                type="checkbox"
                checked={this.state.document.openEmbedded}
                onChange={() => {
                  let document = this.state.document;
                  document.openEmbedded = this.state.document.openEmbedded != undefined ? !this.state.document.openEmbedded : true;
                  if (document.openInSameWindow) {
                    document.openInSameWindow = false;
                  }
                  if (document.openInPopup) {
                    document.openInPopup = false;
                  }
                  this.setState({ document });
                }}
                style={{
                  width: 20,
                  marginTop: -2
                }}
              />
              Vis som embedded side
            </div>
          </>
        }
        <button
          style={{
            float: "left",
            clear: "both",
          }}
          onClick={() => {
            if (JSON.stringify(this.props.document) === JSON.stringify(this.state.document)) {
              this.props.onChange(this.state.document);
            } else {
              if (!this.props.document.objectId) {
                AdminService.addFile(this.state.document).then((result: IModuleDocument) => {
                  this.props.onChange(result);
                  // this.setState({ document: {} }, () => this.forceUpdate());
                });
              } else {
                AdminService.updateFile(this.state.document).then(() => {
                  this.props.onChange(this.state.document);
                });
              }
            }
          }}>
          {this.props.document.objectId ? (JSON.stringify(this.props.document) === JSON.stringify(this.state.document) ? "Annuler" : "Opdater dokument") : "Tilføj dokument"}
        </button>
        {this.props.document.objectId &&
          <button
            style={{
              float: "right",
              backgroundColor: "red"
            }}
            onClick={() => {
              this.props.onDelete();
            }}
          >
            <Icon
              title={"Trash"}
              size={18}
              color={"#ffffff"}
              style={{
              }}
              onClick={() => { }}
            />
          </button>
        }
        {this.props.document.objectId &&
          <button
            style={{
              float: "right",
              opacity: this.props.disableMoveDown ? 0.5 : 1
            }}
            disabled={this.props.disableMoveDown}
            onClick={() => {
              this.props.onMoveDown();
            }}
          >
            <Icon
              title={"ArrowDown"}
              size={18}
              color={"#ffffff"}
              style={{
              }}
              onClick={() => { }}
            />
          </button>
        }
        {this.props.document.objectId &&
          <button
            style={{
              float: "right",
              opacity: this.props.disableMoveUp ? 0.5 : 1
            }}
            disabled={this.props.disableMoveUp}
            onClick={() => {
              this.props.onMoveUp();
            }}
          >
            <Icon
              title={"ArrowUp"}
              size={18}
              color={"#ffffff"}
              style={{
              }}
              onClick={() => { }}
            />
          </button>
        }
        {this.state.document && this.props.appStore.mindPaperToEdit &&
          <MindPaperEditPanel
            reloadMindPapers={(selectedId: string) => {
              this.props.appStore.mindPaperToEdit = undefined;
              this.getMindPapers(selectedId);
            }}
          />
        }
      </div>
    );
  }
}