import * as React from 'react';
import './Pivot.css'

export interface IProps {
  items: { key: string, text: string, disabled?: boolean, icon?: string, error?: boolean }[];
  highlightColor: string;
  textColor?: string;
  selectedKey: string;
  style?: React.CSSProperties;
  select: (key: string) => void;
  isMobile?: boolean;
}

export class Pivot extends React.Component<IProps, {}> {

  constructor(props: IProps) {
    super(props);
  }

  public render(): JSX.Element {
    let items = [];
    this.props.items.forEach((pivotItem: { key: string, text: string, disabled?: boolean, icon?: string, error?: boolean }) => {

      items.push(
        <div
          tabIndex={0}
          key={pivotItem.key}
          className="pivotItem"
          onKeyPress={(event: React.KeyboardEvent<HTMLDivElement>) => {
            if (event.key === "Enter") {
              this.props.select(pivotItem.key);
            }
          }}
          onClick={() => {
            if (!pivotItem.disabled) {
              this.props.select(pivotItem.key);
            }
          }}
          style={{
            borderBottom: this.props.selectedKey === pivotItem.key ? `2px solid ${this.props.highlightColor}` : "none",
            opacity: pivotItem.disabled ? 0.5 : 1,
            color: this.props.selectedKey === pivotItem.key ? this.props.highlightColor : this.props.textColor
          }}
        >
          {pivotItem.text}
        </div>
      );
    });
    return (
      <div
        className={"pivot"}
        style={this.props.style}
      >
        {items}
      </div>
    );
  }
}