import { IProduct } from "../interfaces/IProduct";
import { IProductIntro } from "../interfaces/IProductIntro";
import { ITestResult } from "../interfaces/ITestResult";

export default class TestService {

  public static getTests(): Promise<IProduct[]> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/Tests/`, {
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        }
      }).then((response) => {
        if (!response.ok) {
          resolve(undefined);
        } else {
          return response.json();
        }
      }).then(response => {
        resolve(response.results);
      }).catch((error) => {
        console.log(error);
        resolve(undefined);
      });
    });
  }

  public static createTest(product: IProduct): Promise<void> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/Tests/`, {
        method: "POST",
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        },
        body: JSON.stringify(
          product
        )
      }).then((response) => {
        if (!response.ok) {
          resolve();
        } else {
          resolve();
        }
      }).catch((error) => {
        console.log(error);
        resolve();
      });
    });
  }

  public static updateTest(product: IProduct): Promise<void> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/Tests/${product.objectId}`, {
        method: "PUT",
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        },
        body: JSON.stringify({
          type: product.type,
          title: product.title,
          productId: product.productId,
          teaser: product.teaser,
          image: product.image,
          link: product.link,
          modules: product.modules
        })
      }).then((response) => {
        if (!response.ok) {
          resolve();
        } else {
          resolve();
        }
      }).catch((error) => {
        console.log(error);
        resolve();
      });
    });
  }

  public static deleteTest(product: IProduct): Promise<void> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/Tests/${product.objectId}`, {
        method: "DELETE",
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        }
      }).then((response) => {
        if (!response.ok) {
          resolve();
        } else {
          resolve();
        }
      }).catch((error) => {
        console.log(error);
        resolve();
      });
    });
  }

  ///////////////////////////// ProductIntro ////////////////////////////////

  public static getTestIntro(): Promise<IProductIntro> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/TestIntro/`, {
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        }
      }).then((response) => {
        if (!response.ok) {
          resolve(undefined);
        } else {
          return response.json();
        }
      }).then(response => {
        resolve(response.results[0]);
      }).catch((error) => {
        console.log(error);
        resolve(undefined);
      });
    });
  }

  public static updateTestIntro(testIntro: IProductIntro): Promise<void> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/TestIntro/${testIntro.objectId}`, {
        method: "PUT",
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        },
        body: JSON.stringify({
          title: testIntro.title,
          text: testIntro.text,
          titleEN: testIntro.titleEN,
          textEN: testIntro.textEN
        })
      }).then((response) => {
        if (!response.ok) {
          resolve();
        } else {
          resolve();
        }
      }).catch((error) => {
        console.log(error);
        resolve();
      });
    });
  }

  ///////////////////////////// Results ////////////////////////////////

  public static loadTestResults(userId: string): Promise<ITestResult[]> {
    return new Promise((resolve) => {
      const url = `https://mindjuice.herokuapp.com/parse/classes/TestResults/?where={\"userId\":\"${userId}\"}`;
      fetch(
        url, {
        method: "GET",
        headers: {
          'X-Parse-Application-Id': 'mindjuice',
        }
      }).then((response) => {
        if (!response.ok) {
          resolve(undefined);
        } else {
          return response.json();
        }
      }).then(response => {
        let testResults = response.results.map((result: any) => {
          return {
            id: result.objectId,
            name: result.name,
            myResult: result.myResult,
            createdAt: result.createdAt,
            maxPoints: JSON.parse(result.testResult).maxPoints,
            splitTestResult: JSON.parse(result.testResult).splitTestResult,
            pointsForTypes: JSON.parse(result.testResult).pointsForTypes

          };
        });
        resolve(testResults);
      }).catch((error) => {
        console.log(error);
        resolve(undefined);
      });
    });
  }

  public static save(userId: string, result: ITestResult, name: string): Promise<ITestResult[]> {
    return new Promise((resolve) => {
      const url = `https://mindjuice.herokuapp.com/parse/classes/TestResults/`;
      fetch(
        url, {
        method: "POST",
        headers: {
          'X-Parse-Application-Id': 'mindjuice',
        },
        body: JSON.stringify({
          userId: userId,
          name: name,
          testResult: JSON.stringify(result)
        })
      }).then((response) => {
        if (!response.ok) {
          resolve(undefined);
        } else {
          resolve(this.loadTestResults(userId));
        }
      }).catch((error) => {
        console.log(error);
        resolve(undefined);
      });
    });
  }

  public static update(userId: string, result: ITestResult, name: string): Promise<ITestResult[]> {
    return new Promise((resolve) => {
      const url = `https://mindjuice.herokuapp.com/parse/classes/TestResults/${result.id}`;
      fetch(
        url, {
        method: "PUT",
        headers: {
          'X-Parse-Application-Id': 'mindjuice',
        },
        body: JSON.stringify({
          userId: userId,
          name: name,
          myResult: result.myResult,
          testResult: JSON.stringify(result)
        })
      }).then((response) => {
        if (!response.ok) {
          resolve(undefined);
        } else {
          resolve(this.loadTestResults(userId));
        }
      }).catch((error) => {
        console.log(error);
        resolve(undefined);
      });
    });
  }

  public static delete(userId: string, testId: string): Promise<ITestResult[]> {
    return new Promise((resolve) => {
      const url = `https://mindjuice.herokuapp.com/parse/classes/TestResults/${testId}`;
      fetch(
        url, {
        method: "Delete",
        headers: {
          'X-Parse-Application-Id': 'mindjuice',
        }
      }).then((response) => {
        if (!response.ok) {
          resolve(undefined);
        } else {
          resolve(this.loadTestResults(userId));
        }
      }).catch((error) => {
        console.log(error);
        resolve(undefined);
      });
    });
  }
}
