import * as React from 'react';
import './PodcastList.css';
import { AppStore } from '../../../stores';
import { inject, observer } from 'mobx-react';

export interface IProps {
  title: string;
  image?: string;
  speaker?: string;
  description?: string;
  label?: string;
  duration?: string;
  width: number;
  marginRight: number;
  selected: boolean;
  onClick: () => void;
  appStore?: AppStore;
}

export interface IState {
}

@inject('appStore')
@observer
export default class PodcastList extends React.Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    return (
      <div
        className='podcastList'
        style={{
          width: this.props.appStore.isMobile ? "100%" : this.props.width,
          height: this.props.appStore.isMobile ? "33vw" : 200,
          marginRight: this.props.marginRight,
          backgroundColor: this.props.selected && !this.props.appStore.isMobile ? "#555555" : "#191919"
        }}
        onClick={() => this.props.onClick()}
      >
        <div
          className="podcastListImage"
          style={{
            backgroundImage: `url(${this.props.image}`,
            width: this.props.appStore.isMobile ? "33vw" : 200,
            height: this.props.appStore.isMobile ? "33vw" : 200,
          }}
        />
        {this.props.label &&
          <div className="podcastListLabel">
            {`${this.props.label}`}
          </div>
        }
        <div
          className="podcastListTitle"
          style={{
            width: this.props.appStore.isMobile ? `calc(66vw - 87px)` : `calc(100% - ${(this.props.width / 2.5) + 20}px)`
          }}
        >
          {this.props.title}
        </div>
        <div
          className="podcastListSpeaker"
          style={{
            width: this.props.appStore.isMobile ? `calc(66vw - 87px)` : `calc(100% - ${(this.props.width / 2.5) + 20}px)`
          }}
        >
          {this.props.speaker}
        </div>
        <div
          className="podcastListDescription"
          style={{
            width: this.props.appStore.isMobile ? `calc(66vw - 87px)` : `calc(100% - ${(this.props.width / 2.5) + 20}px)`
          }}
        >
          {this.props.description}
        </div>
      </div>
    );
  }
}