import { IModuleDocument } from "../interfaces/IModuleDocument";
import { IDeletedUser, IUser } from "../interfaces/IUser";

export default class AdminService {

  public static upload(file: any): Promise<string> {
    return new Promise<string>((resolve: (url: string) => void): void => {
      fetch(`https://mindjuice.herokuapp.com/parse/files/${file.name}`, {
        headers: {
          "Content-Type": file.type,
          'X-Parse-Application-Id': 'mindjuice'
        },
        method: "POST",
        body: file
      }).then((response) => {
        if (!response.ok) {
          resolve(undefined);
        } else {
          response.json().then((result) => {
            resolve(result.url);
          })
        }
      }).catch(error => {
        console.log(error);
        resolve(undefined);
      });
    });
  }

  public static delete(file: any): Promise<void> {
    return new Promise<void>((resolve: () => void, reject: (error: any) => void): void => {
      fetch(`https://mindjuice.herokuapp.com/parse/files/${file.name}`, {
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        },
        method: "DELETE"
      }).then((response) => {
        if (!response.ok) {
          reject(response);
        } else {
          resolve();
        }
      }).catch(error => {
        console.log(error);
        reject(null);
      });
    });
  }

  public static getDocuments(): Promise<any> {
    return new Promise<any>((resolve: (result: any) => void, reject: (error: any) => void): any => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/filesMetadata/`, {
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        },
        method: "GET"
      }).then((response) => {
        if (!response.ok) {
          reject(response);
        } else {
          return response.json();
        }
      }).then(document => {
        resolve(document);
      }).catch(error => {
        console.log(error);
        reject(null);
      });
    });
  }

  public static getFile(documentId: string): Promise<any> {
    return new Promise<any>((resolve: (result: any) => void, reject: (error: any) => void): any => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/filesMetadata/${documentId}`, {
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        },
        method: "GET"
      }).then((response) => {
        if (!response.ok) {
          reject(response);
        } else {
          return response.json();
        }
      }).then(document => {
        resolve(document);
      }).catch(error => {
        console.log(error);
        reject(null);
      });
    });
  }

  public static addFile(document: IModuleDocument): Promise<any> {
    return new Promise<any>((resolve: (result: any) => void, reject: (error: any) => void): any => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/filesMetadata/`, {
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        },
        method: "POST",
        body: JSON.stringify(document)
      }).then((response) => {
        if (!response.ok) {
          reject(response);
        } else {
          return response.json();
        }
      }).then(updatedDocument => {
        document.objectId = updatedDocument.objectId;
        document.createdAt = updatedDocument.createdAt;
        resolve(document);
      }).catch(error => {
        console.log(error);
        reject(null);
      });
    });
  }

  public static updateFile(document: IModuleDocument): Promise<void> {
    return new Promise<void>((resolve: () => void, reject: (error: any) => void): any => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/filesMetadata/${document.objectId}`, {
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        },
        method: "PUT",
        body: JSON.stringify({
          product: document.product,
          team: document.team,
          type: document.type,
          url: document.url,
          mindPaperId: document.mindPaperId,
          fileType: document.fileType,
          icon: document.icon,
          documentTitle: document.documentTitle,
          openInSameWindow: document.openInSameWindow,
          openInPopup: document.openInPopup,
          openEmbedded: document.openEmbedded,
          localizedDocumentTitle: document.localizedDocumentTitle,
          localizedMindPaperId: document.localizedMindPaperId,
          localizedIcon: document.localizedIcon,
        })
      }).then((response) => {
        if (!response.ok) {
          reject(response);
        } else {
          resolve();
        }
      }).catch(error => {
        console.log(error);
        reject(null);
      });
    });
  }

  private static compare(a, b) {
    let comparison = 0;
    if (a.username > b.username) {
      comparison = 1;
    } else if (a.username < b.username) {
      comparison = -1;
    }
    return comparison;
  }

  private static dynamicsort(property, order) {
    var sort_order = 1;
    if (order === "desc") {
      sort_order = -1;
    }
    return function (a, b) {
      // a should come before b in the sorted order
      if (a[property] < b[property]) {
        return -1 * sort_order;
        // a should come after b in the sorted order
      } else if (a[property] > b[property]) {
        return 1 * sort_order;
        // a and b are the same
      } else {
        return 0 * sort_order;
      }
    }
  }

  public static getUsers(searchString?: string): Promise<IUser[]> {
    return new Promise((resolve) => {
      let url;
      if (searchString) {
        url = `https://mindjuice.herokuapp.com/parse/users/?where={\"username\":{"$regex":\"${searchString}\"}}`;
      } else {
        url = "https://mindjuice.herokuapp.com/parse/users/?limit=2000";
      }
      fetch(url, {
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        }
      }).then((response) => {
        if (!response.ok) {
          resolve(undefined);
        } else {
          return response.json();
        }
      }).then(response => {
        let users: IUser[] = [];
        response.results.forEach((userObject) => {
          users.push({
            id: userObject.objectId,
            username: userObject.username.toLowerCase(),
            name: userObject.name,
            userType: userObject.userType,
            onDemandAccess: userObject.onDemandAccess,
            createdAt: userObject.createdAt,
            sessionToken: userObject.sessionToken
          });
        });
        resolve(users.sort(this.dynamicsort("username", "desc")));
      }).catch((error) => {
        console.log(error);
        resolve(undefined);
      });
    });
  }

  public static updateUser(user: IUser, masterKey): Promise<void> {
    return new Promise((resolve) => {
      const url = `https://mindjuice.herokuapp.com/parse/users/${user.id}`;
      fetch(
        url, {
        method: "PUT",
        headers: {
          'X-Parse-Application-Id': 'mindjuice',
          'X-Parse-Master-Key': masterKey
        },
        body: JSON.stringify({
          name: user.name,
          password: user.password,
        })
      }).then((response) => {
        if (!response.ok) {
          resolve();
        } else {
          return response.json();
        }
      }).then(response => {
        resolve();
      }).catch((error) => {
        console.log(error);
        resolve();
      });
    });
  }

  public static deleteUser(user: IUser, masterKey): Promise<void> {
    return new Promise((resolve) => {
      const url = `https://mindjuice.herokuapp.com/parse/users/${user.id}`;
      fetch(
        url, {
        method: "DELETE",
        headers: {
          'X-Parse-Application-Id': 'mindjuice',
          'X-Parse-Master-Key': masterKey
        }
      }).then((response) => {
        if (!response.ok) {
          resolve();
        } else {
          return response.json();
        }
      }).then(response => {
        resolve();
      }).catch((error) => {
        console.log(error);
        resolve();
      });
    });
  }

}