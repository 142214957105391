import * as React from 'react';
import './TeamAccessPanel.css';
import { inject, observer } from 'mobx-react';
import { AppStore } from '../../../../stores';
import { IProductAccess } from '../../../../interfaces/IProductAccess';
import ProductService from '../../../../services/ProductService';

export interface IProps {
  appStore?: AppStore;
  productId: string;
  teamId: string;
}

export interface IState {
  username: string;
}

@inject('appStore')
@observer
export default class TeamAccessPanel extends React.Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
      username: undefined
    }
  }

  public componentWillReceiveProps(props: IProps): void {
    if (props.productId !== undefined) {
      setTimeout(() => {
        this.getUsersWithAccess(props.productId);
      }, 500);
    }
  }

  private getUsersWithAccess(productId: string): void {
    ProductService.getUsersWithAccessToProduct(productId).then((usersWithAccess: IProductAccess[]) => {
      if (usersWithAccess) {
        this.props.appStore.usersWithAccessToProduct = usersWithAccess;
        this.props.appStore.usersWithAccessToProductDivs = [];
        usersWithAccess.forEach((userWithAccess: IProductAccess) => {
          if (userWithAccess.team === this.props.teamId) {
            this.props.appStore.usersWithAccessToProductDivs.push(
              <div
                key={"usersWithAccess_" + userWithAccess.objectId}
                className="emailLabel"
              >
                {userWithAccess.user}
                <div
                  className="emailLabelDeleteBtn"
                  onClick={() => {
                    userWithAccess.team = "";
                    ProductService.updateAccessToProduct(userWithAccess).then(() => {
                      this.getUsersWithAccess(this.props.productId);
                    });
                  }}
                />
              </div>
            );
          }
        })
        this.forceUpdate();
      }
    });
  }

  render() {
    return (
      <div>
        <div
          className="teamAccessPanelWrapper"
          style={{
            visibility: this.props.appStore.showTeamAccessPanelForProductAndTeam !== undefined ? "visible" : "hidden"
          }}
          onClick={() => {
            this.props.appStore.showTeamAccessPanelForProductAndTeam = undefined;
          }}
        />
        <div
          className="teamAccessPanel"
          style={{
            right: this.props.appStore.showTeamAccessPanelForProductAndTeam !== undefined ? 0 : -620
          }}
        >
          <div className="editPanelCloseBtn"
            onClick={() => this.props.appStore.showTeamAccessPanelForProductAndTeam = undefined}
          />
          <div className="addUserAccess">
            <h3>Tilføj bruger:</h3>
            <div className="inputFieldLabel">Tilføj brugere fra CSV-fil</div>
            <input
              type="file"
              id="myFile"
              name="filename"
              onChange={(event) => {
                var files = event.target.files[0];
                if (files) {
                  var r = new FileReader();
                  r.onload = (e) => {
                    let target: any = e.target;
                    const users = target.result.split(/\r\n|\r|\n/g);
                    console.log(users);
                    users.forEach((newUser: string) => {
                      let alreadyAdded;
                      this.props.appStore.usersWithAccessToProduct.forEach((productAccess: IProductAccess) => {
                        if (productAccess.user.toLowerCase() === newUser.toLowerCase()) {
                          alreadyAdded = productAccess;
                        }
                      });
                      if (alreadyAdded == undefined) {
                        ProductService.allowAccessToProduct(this.props.productId, newUser.toLowerCase(), this.props.teamId).then((status: number) => {
                          this.getUsersWithAccess(this.props.productId);
                        });
                      } else {
                        alreadyAdded.team = this.props.teamId;
                        ProductService.updateAccessToProduct(alreadyAdded).then(() => {
                          this.getUsersWithAccess(this.props.productId);
                        });
                      }
                    });
                  }
                  r.readAsText(files);
                } else {
                  alert("Failed to load file");
                }
              }}
            />
            <div className="inputFieldLabel">Email</div>
            <input
              type="text"
              value={this.state.username}
              onChange={(event: any) => {
                this.setState({ username: event.target.value.toLowerCase() });
              }}
            />
            <button
              onClick={() => {
                let alreadyAdded;
                this.props.appStore.usersWithAccessToProduct.forEach((productAccess: IProductAccess) => {
                  if (productAccess.user === this.state.username) {
                    alreadyAdded = productAccess;
                  }
                });
                if (alreadyAdded == undefined) {
                  ProductService.allowAccessToProduct(this.props.productId, this.state.username, this.props.teamId).then((status: number) => {
                    this.setState({ username: "" });
                    this.getUsersWithAccess(this.props.productId);
                  });
                } else {
                  alreadyAdded.team = this.props.teamId;
                  ProductService.updateAccessToProduct(alreadyAdded).then(() => {
                    this.setState({ username: "" });
                    this.getUsersWithAccess(this.props.productId);
                  });
                }
              }}
            >
              Tilføj
            </button>
          </div>
          <h3>{`Brugere med adgang: (${this.props.appStore.usersWithAccessToProductDivs.length})`}</h3>
          <div>
            {this.props.appStore.usersWithAccessToProductDivs}
          </div>
        </div>
      </div>
    );
  }
}