import * as React from 'react';
import './TypeTestResults.css';
import { AppStore } from '../../stores';
import { inject, observer } from 'mobx-react';
import { ITestResult } from '../../interfaces/ITestResult';
import DateTimeService from '../../services/DateTimeService';
import TypeTestDeletePopup from './deletePopup/TypeTestDeletePopup';
import TestService from '../../services/TestService';
import TypeTestChangeName from '../changeName/EnneagrammyChangeName';

export interface IProps {
  appStore?: AppStore;
}

export interface IState {
  top: number;
}

@inject('appStore')
@observer
export default class TypeTestResults extends React.Component<IProps, IState> {

  constructor(props, context) {
    super(props, context);
    this.state = {
      top: window.innerHeight
    }
  }

  public componentDidMount(): void {
    setTimeout(() => {
      this.setState({ top: 0 });
    }, 0);
  }

  render() {
    var winningTypes = [];
    let resultItems;
    if (this.props.appStore.selectedTestResult && this.props.appStore.testResults) {

      this.props.appStore.testResults.forEach((testResult: ITestResult) => {
        var resultToDisplay;
        if (testResult.splitTestResult != null) {
          resultToDisplay = testResult.splitTestResult;
        } else {
          resultToDisplay = testResult.pointsForTypes[0].id;
        }
        winningTypes.push(resultToDisplay);
      });
      resultItems = this.props.appStore.testResults.map((testResult: ITestResult, i) =>
        <div
          key={`testResultItem_${testResult.id}`}
          className="enneagrammyAllResultItem"
        >
          <div
            className="enneagrammyAllResultItemType"
            style={{
              backgroundColor: this.props.appStore.typeTestColor
            }}
            onClick={() => {
              this.props.appStore.selectedTestResult = testResult;
              this.props.appStore.selectedType = winningTypes[i];
              this.props.appStore.selectedTypeInfoTab = "result";
              if (this.props.appStore.isUniversityApp) {
                this.props.appStore.myResult = testResult;
              }
            }}
          >
            {winningTypes[i]}
          </div>
          <div
            className="enneagrammyAllResultItemName"
            style={{
              color: "#064859"
            }}
            onClick={() => {
              this.props.appStore.selectedTestResult = testResult;
              this.props.appStore.selectedType = winningTypes[i];
              this.props.appStore.selectedTypeInfoTab = "result";
              if (!this.props.appStore.isMobile) {
                this.props.appStore.myResult = testResult;
              }
            }}
          >
            {testResult.name}
          </div>
          <div
            className="enneagrammyAllResultItemDate"
            onClick={() => {
              this.props.appStore.selectedTestResult = testResult;
              this.props.appStore.selectedType = winningTypes[i];
              this.props.appStore.selectedTypeInfoTab = "result";
              if (!this.props.appStore.isMobile) {
                this.props.appStore.myResult = testResult;
              }
            }}
          >
            {DateTimeService.ConvertToDDMMYYYYHHMM(testResult.createdAt)}
          </div>
          <div className="enneagrammyAllResultItemMyResult">
            <input
              type="checkbox"
              checked={this.props.appStore.myResult.id === testResult.id}
              onChange={() => {
                this.props.appStore.myResult.myResult = false;
                TestService.update(this.props.appStore.user.id, this.props.appStore.myResult, this.props.appStore.myResult.name).then((testResults: ITestResult[]) => {
                  testResult.myResult = true;
                  TestService.update(this.props.appStore.user.id, testResult, testResult.name).then((testResults: ITestResult[]) => {
                    this.props.appStore.loading = false;
                    this.props.appStore.myResult = testResult;
                  });
                });
              }}
              style={{
                width: 20,
                marginTop: -2
              }}
            />
            {window.loc.strings.myResult}
          </div>
          <div
            className="enneagrammyDeleteBtn"
            onClick={() => this.props.appStore.testIdToDelete = testResult.id.toString()}
          />
          <div
            className="enneagrammyRenameBtn"
            onClick={() => {
              this.props.appStore.selectedTestResult = testResult;
              this.props.appStore.typeTestChangeName = true;
            }}
          />
        </div>
      );
    }
    return (
      <div className="enneagrammyAllResults">
        {(localStorage.getItem("typeTestAccess") || this.props.appStore.user.userType === "admin") ?
          <div className="enneagrammyResultListContent">
            {resultItems}
          </div>
          :
          <div
            className="startBtn"
            style={{
              backgroundColor: "#064859"
            }}
            onClick={() => this.props.appStore.isShowingSubscriptionPopup = true}
          >
            {window.loc.strings.openShop}
          </div>
        }
        {this.props.appStore.testIdToDelete &&
          <TypeTestDeletePopup />
        }
        {this.props.appStore.typeTestChangeName && this.props.appStore.isMobile &&
          <div
            className="enneagrammyChangeNameBoxContainer"
          >
            <div className="enneagrammyChangeNameBox">
              <TypeTestChangeName />
            </div>
          </div>
        }
      </div>
    );
  }

}