import * as React from 'react';
import './ForgottenPassword.css';
import { AppStore } from '../../../stores';
import { inject, observer } from 'mobx-react';
import AuthenticationService from '../../../services/AuthenticationService';
import { IUser } from '../../../interfaces/IUser';

export interface IProps {
  appStore?: AppStore;
  buttonColor: string;
}

export interface IState {
  username: string;
  savingEnabled: boolean;
  validEmail: boolean;
  loginError: boolean;
  showErrorMessage: boolean;
}

@inject('appStore')
@observer
export default class ForgottenPassword extends React.Component<IProps, IState> {

  constructor(props, context) {
    super(props, context);
    this.state = {
      username: undefined,
      savingEnabled: false,
      validEmail: true,
      loginError: false,
      showErrorMessage: false
    }
  }

  public componentDidMount(): void {
    document.addEventListener("keyup", ((event) => {
      if (event.keyCode === 13) {
        event.preventDefault();
        this.reset();
      }
    }));
  }

  private reset(): void {
    if (this.state) {
      if (this.validateEmail(this.state.username)) {
        this.props.appStore.loggingIn = true;
        this.setState({ showErrorMessage: false });
        AuthenticationService.resetPassword(this.state.username).then((status: number) => {
          if (status === 200) {
            this.props.appStore.loggingIn = false;
            this.props.appStore.showingForgottenPassword = false;
            this.props.appStore.showingForgottenPasswordConfirmation = true;
          } else {
            this.setState({ loginError: true, showErrorMessage: true });
            this.props.appStore.loggingIn = false;
            setTimeout(() => {
              this.setState({ loginError: false });
            }, 1000);
          }
        });
      } else {
        this.setState({ validEmail: false });
      }
    }
  }

  private validate(): void {
    if (this.state.username) {
      this.setState({ savingEnabled: true });
    } else {
      this.setState({ savingEnabled: false });
    }
  }

  private validateEmail(email): boolean {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  render() {
    return (
      <div className="forgottenPassword">
        <div
          className="forgottenPasswordBackground"
          onClick={() => {
            this.props.appStore.showingForgottenPassword = false;
          }}
        />
        <div
          className="forgottenPasswordBox"
          style={{
            animation: this.state.loginError ? "shake 0.82s cubic-bezier(.36,.07,.19,.97) both" : "none",
            transform: "translate3d(0, 0, 0)",
            backfaceVisibility: "hidden",
            perspective: "1000px"
          }}
        >
          <div className="forgottenPasswordBoxHeadline">
            {window.loc.strings.resetPassword}
          </div>
          <div
            className="forgottenPasswordCloseBtn"
            onClick={() => {
              this.props.appStore.showingForgottenPassword = false;
              this.props.appStore.showingLogin = true;
            }}
          />
          <input
            type="email"
            placeholder={window.loc.strings.email}
            style={{
              color: this.state.validEmail && !this.state.loginError ? "#272727" : "red"
            }}
            onPaste={() => {
              setTimeout(() => {
                this.validate();
              }, 100);
            }}
            onChange={(event: any) => {
              if (!this.state.validEmail) {
                this.setState({ validEmail: this.validateEmail(event.target.value) });
              }
              this.setState({ username: event.target.value.toLowerCase() });
              this.validate();
            }}
          />
          <div
            className="forgottenPasswordBtn"
            style={{
              opacity: this.state.savingEnabled ? 1 : 0.5,
              cursor: this.state.savingEnabled ? "pointer" : "default",
            }}
            onClick={() => {
              if (this.state.savingEnabled) {
                this.reset();
              }
            }}
          >
            {window.loc.strings.resetPassword?.toUpperCase()}
          </div>
          {this.state.showErrorMessage &&
            <div
              className="forgottenPasswordErrorMessage"
            >
              Brugeren eksisterer ikke. Er du sikker på, at du har oprettet en bruger?
            </div>
          }
        </div>
      </div>
    );
  }

}