import * as React from 'react';
import './EnneagrammyResultBar.css';
import { inject, observer } from 'mobx-react';
import CountUp from 'react-countup';
import { AppStore } from '../../stores';

export interface IProps {
  appStore?: AppStore;
  type: number;
  points: number;
  maxPoints: number;
  winner: boolean;
  textColor: string;
  backgroundColor: string;
  barColor: string;
  iconTextColor: string;
  iconBackgroundColor: string;
  onClick: () => void;
}

export interface IState {
  width: number;
  procentageLabel: number;
}

@inject('appStore')
@observer
export default class EnneagrammyResultBar extends React.Component<IProps, IState> {

  constructor(props, context) {
    super(props, context);
    this.state = {
      width: 0,
      procentageLabel: 0
    }
    this.animate();
  }

  public componentWillReceiveProps(props: IProps): void {
    if (props !== this.props) {
      this.animate();
    }
  }

  private animate(): void {
    setTimeout(() => {
      const bar: any = this.refs.resultBar;
      this.setState({ width: ((bar.offsetWidth / this.props.maxPoints) * this.props.points) })
      this.setState({ procentageLabel: (100 / this.props.maxPoints) * this.props.points })
    }, 10);
  }

  render() {
    let type;
    switch (this.props.type) {
      case 1:
        type = window.loc.strings.type1
        break;
      case 2:
        type = window.loc.strings.type2
        break;
      case 3:
        type = window.loc.strings.type3
        break;
      case 4:
        type = window.loc.strings.type4
        break;
      case 5:
        type = window.loc.strings.type5
        break;
      case 6:
        type = window.loc.strings.type6
        break;
      case 7:
        type = window.loc.strings.type7
        break;
      case 8:
        type = window.loc.strings.type8
        break;
      case 9:
        type = window.loc.strings.type9
        break;

      default:
        break;
    }
    return (
      <div
        className="enneagrammyResultItem"
        onClick={() => this.props.onClick()}
      >
        <div
          className="enneagrammyResultBarTitle"
          style={{
            fontWeight: this.props.winner ? "bold" : "normal",
            color: this.props.iconTextColor,
            backgroundColor: this.props.iconBackgroundColor
          }}
        >
          {this.props.type}
        </div>
        <div
          className="enneagrammyResultBar"
          ref="resultBar"
          style={{
            backgroundColor: `${this.props.backgroundColor}73`
          }}
        >
          <div
            className="enneagrammyAnimationBar"
            ref="AnimationBar"
            style={{
              width: this.state.width,
              backgroundColor: this.props.barColor
            }}
          />
          <div
            className="enneagrammyBarText"
            style={{
              color: this.props.textColor,
            }}
          >
            <div
              style={{
                float: "left"
              }}
            >
              {type}
            </div>
          </div>
        </div>
        <div 
        className="enneagrammyResultBarLabel"
        style={{
          color: this.props.barColor
        }}
        >
          <CountUp className="enneagrammyCountUp" start={0} end={this.state.procentageLabel} duration={0.8} />%
        </div>
      </div>
    );
  }

}