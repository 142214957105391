export class NativeService {

  private static sendMessage(method: string): void {
    switch (window.platform) {
      case "ios":
        if (window.webkit != undefined) {
          try {
            window.webkit.messageHandlers.app.postMessage(method);
          }
          catch (err) {
            console.log(err);
          }
        }
        break;
      case "android":
        if (window.android != undefined) {
          try {
            window.android.postMessage(method);
          }
          catch (err) {
            console.log(err);
          }
        }
        break;
      default:
        console.log("This method is not support by your device");
        break;
    }
  }

  public static print(url: string): void {
    this.sendMessage(
      JSON.stringify({
        "action": "print",
        "url": url
      })
    );
  }

  public static ready(): void {
    this.sendMessage(
      JSON.stringify({
        "action": "ready"
      })
    );
  }

  public static pushPopupSuccesfullyOpened(): void {
    this.sendMessage(
      JSON.stringify({
        "action": "pushPopupSuccesfullyOpened"
      })
    );
  }
  

  public static saveUser(email: string): void {
    this.sendMessage(
      JSON.stringify({
        "action": "saveUser",
        "email": email
      })
    );
  }
  
  public static showSplash(): void {
    //if (window.platform === "ios") {
      this.sendMessage(
        JSON.stringify({
          "action": "showSplash"
        })
      );
    //}
  }

  public static share(teaser: string, link: string): void {
    //if (window.platform === "ios") {
      this.sendMessage(
        JSON.stringify({
          "action": "share",
          "teaser": teaser,
          "link": link
        })
      );
    //}
  }
  
  public static buyMonthlySubscription(): void {
    //if (window.platform === "ios") {
      this.sendMessage(
        JSON.stringify({
          "action": "buyMonthlySubscription"
        })
      );
    //}
  }

  public static buyYearlySubscription(): void {
    //if (window.platform === "ios") {
      this.sendMessage(
        JSON.stringify({
          "action": "buyYearlySubscription"
        })
      );
    //}
  }

  public static restore(): void {
    //if (window.platform === "ios") {
      this.sendMessage(
        JSON.stringify({
          "action": "restore"
        })
      );
    //}
  }

  public static isUserSubscribed(): void {
    //if (window.platform === "ios") {
      this.sendMessage(
        JSON.stringify({
          "action": "isUserSubscribed"
        })
      );
    //}
  }

  public static isHandlingATrsnsaction(): void {
    //if (window.platform === "ios") {
      this.sendMessage(
        JSON.stringify({
          "action": "isHandlingATrsnsaction"
        })
      );
    //}
  }

  public static log(message: string): void {
    this.sendMessage(
      JSON.stringify({
        "action": "log",
        "message": message
      })
    );
  }

}

export default NativeService;