import * as React from 'react';
import './ForgottenPasswordConfirmation.css';
import { AppStore } from '../../../stores';
import { inject, observer } from 'mobx-react';

export interface IProps {
  appStore?: AppStore;
}

export interface IState {
}

@inject('appStore')
@observer
export default class ForgottenPasswordConfirmation extends React.Component<IProps, IState> {

  constructor(props, context) {
    super(props, context);
    this.state = {
    }
  }

  render() {
    return (
      <div className="forgottenPasswordConfirmation">
        <div
          className="forgottenPasswordConfirmationBackground"
          onClick={() => {
            this.props.appStore.showingForgottenPasswordConfirmation = false;
            if (this.props.appStore.isMobile) {
              this.props.appStore.showingLogin = true;
            }
          }}
        />
        <div
          className="forgottenPasswordConfirmationBox"
        >
          <div className="forgottenPasswordConfirmationBoxHeadline">
            {window.loc.strings.resetPassword}
          </div>
          <div
            className="forgottenPasswordConfirmationCloseBtn"
            onClick={() => {
              this.props.appStore.showingForgottenPasswordConfirmation = false;
              if (this.props.appStore.isMobile) {
                this.props.appStore.showingLogin = true;
              }
            }}
          />
          <div className="forgottenPasswordConfirmationText">
            Vi har sendt dig en mail med et link, som du skal bruge til at nulstille dit kodeord. Vær opmærksom på, at mail'en kan være havnet i "spam" mappen i dit emailprogram. Når du har nulstillet dit kodeord kan du logge på igen.
          </div>
        </div>
      </div>
    );
  }

}