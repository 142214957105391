import * as React from 'react';
import './Diploma.css';
import { AppStore } from '../../stores';
import { inject, observer } from 'mobx-react';

export interface IProps {
  appStore?: AppStore;
}

@inject('appStore')
@observer
export default class Diploma extends React.Component<IProps> {

  constructor(props) {
    super(props);
  }

  render() {
    const urlParams = new URLSearchParams(window.location.search);
    let courseId;
    if (urlParams.get('course') != undefined) {
      courseId = urlParams.get('course');
    }
    return (
      <div style={{
        overflow: "auto",
        height: "100vh",
      }}>
        <div
          className="diploma"
          style={{
            backgroundImage: `url(https://dojo.mindjuice.com/images/diplom/${courseId}.jpg)`
          }}
        >
          <div className="contentArea">
            <div className="name">
              {this.props.appStore.user.name}
            </div>
          </div>
        </div>
        <div
          id="print"
          onClick={() => window.print()}
        >
          Gem
        </div>
      </div>
    );
  }

}