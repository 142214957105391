import * as React from 'react';
import './Slide.css';
import { AppStore } from '../../../stores';
import { inject, observer } from 'mobx-react';
import { IProduct } from '../../../interfaces/IProduct';
import { Link } from 'react-router-dom';

export interface IProps {
  appStore?: AppStore;
  width: number;
  content: IProduct;
}

export interface IState {
}

@inject('appStore')
@observer
export default class Slide extends React.Component<IProps, IState> {

  constructor(props, context) {
    super(props, context);
    this.state = {
    }
  }

  render() {
    return this.props.appStore.editMode ? (
      <div
        className="slide"
        style={{
          width: this.props.width,
          height: this.props.appStore.isMobile ? this.props.width / 1.5 : this.props.width / 4,
          backgroundImage: `url(${this.props.appStore.isMobile ? this.props.content.image : (this.props.content.banner ? this.props.content.banner : this.props.content.image)})`,
          opacity: this.props.content.public ? 1 : 0.1
        }}
        onClick={() => {
          this.props.appStore.productToEdit = this.props.content;
        }}
      />
    ) : (
      <Link
        to={`${this.props.appStore.environment}/home/${this.props.content?.objectId}`}
      >
        <div
          className="slide"
          style={{
            width: this.props.width,
            height: this.props.appStore.isMobile ? this.props.width / 1.5 : this.props.width / 4,
            backgroundImage: `url(${this.props.appStore.isMobile ? this.props.content.image : (this.props.content.banner ? this.props.content.banner : this.props.content.image)})`,
            opacity: this.props.content.public ? 1 : 0.1
          }}
          onClick={() => {
            this.props.appStore.isSliderEvent = true;
            this.props.appStore.loadingEmbeddedPage = true;
            setTimeout(() => {
              this.props.appStore.loadingEmbeddedPage = false;
            }, 2000);
            this.props.appStore.pageEmbed = this.props.content.link;
          }}
        />
      </Link>
    )
  }
}