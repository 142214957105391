import * as React from 'react';
import './EnneagrammyAnswer.css';
import { inject, observer } from 'mobx-react';
import { AppStore } from '../../../../stores';

export interface IProps {
  appStore?: AppStore;
  selected: boolean;
  answered: boolean;
  id: string;
  text: string;
  onSelect: () => void;
}

export interface IState {
  showTick: boolean;
}

@inject('appStore')
@observer
export default class Answer extends React.Component<IProps, IState> {

  constructor(props, context) {
    super(props, context);
    this.state = {
      showTick: false
    }
  }

  render() {
    return (
      <div
        className={"enneagrammyAnswer " + this.props.id}
        onClick={() => {
          this.props.onSelect();
          this.setState({ showTick: true });
        }}
        style={{ 
          backgroundColor: "#ffffff",
          opacity: this.props.answered ? 0.5 : 1
        }}
      >
        <div className="enneagrammyText">{this.props.text}</div>
        <div className="enneagrammySelectedAnimation">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 100 100" enableBackground="new 0 0 100 100">
            <polyline className="check" fill="none" stroke="#ffffff" strokeWidth="5" strokeMiterlimit="20" points="15,60 40,80 85,20" style={{ animation: this.props.selected ? "dash 500ms forwards" : "none" }} />
          </svg>
        </div>
      </div>
    );
  }

}
