import * as React from 'react';
import './EnneagrammyTypeInfoPopup.css';
import { AppStore } from '../../stores';
import { inject, observer } from 'mobx-react';
import { IType } from '../../interfaces/IType';
import TypeTestResultBar from '../resultBar/EnneagrammyResultBar';
import * as ReactGA from 'react-ga';
import DateTimeService from '../../services/DateTimeService';
import TypeTestChangeName from '../changeName/EnneagrammyChangeName';

export interface IProps {
  appStore?: AppStore;
}

export interface IState {
  top: number;
}

@inject('appStore')
@observer
export default class EnneagrammyTypeInfoPopup extends React.Component<IProps, IState> {

  constructor(props, context) {
    super(props, context);
    this.state = {
      top: window.innerHeight
    }
  }

  public componentDidMount(): void {
    setTimeout(() => {
      this.setState({ top: 0 });
    }, 0);
  }

  render() {
    const resultBars = [];
    let resultDate;
    let resultToShow;
    if (this.props.appStore.selectedTestResult) {
      resultToShow = this.props.appStore.selectedTestResult.pointsForTypes.map((type: IType) => {
        return {
          id: type.id,
          points: this.props.appStore.selectedTestResult.splitTestResult && type.id === this.props.appStore.selectedTestResult.splitTestResult ? type.points + 1 : type.points
        }
      });
      resultToShow = this.props.appStore.sortByKey(resultToShow, "points");
      resultToShow.forEach((type: IType, index) => {
        resultBars.push(
          <TypeTestResultBar
            key={`resultBar_${type.id}`}
            type={type.id}
            points={type.points}
            maxPoints={this.props.appStore.selectedTestResult.splitTestResult ? this.props.appStore.selectedTestResult.maxPoints + 1 : this.props.appStore.selectedTestResult.maxPoints}
            winner={index === 0}
            backgroundColor={"#064859"}
            barColor={"#064859"}
            textColor={"#ffffff"}
            iconBackgroundColor={"#064859"}
            iconTextColor={"#ffffff"}
            onClick={() => {
              ReactGA.event({
                category: 'Tests',
                action: `User read description of type: ${type.id}`
              });
              this.props.appStore.selectedType = type.id;
              this.props.appStore.selectedTypeInfoTab = "type";
            }}
          />
        );
      });
      resultDate = DateTimeService.ConvertToDDMMYYYYHHMM(this.props.appStore.selectedTestResult.createdAt)
    }
    let videoLink;
    let typeTest;
    switch (this.props.appStore.selectedType) {
      case 1:
        videoLink = window.loc.strings.type1Video;
        typeTest = window.loc.strings.type1Test;
        break;
      case 2:
        videoLink = window.loc.strings.type2Video;
        typeTest = window.loc.strings.type2Test;
        break;
      case 3:
        videoLink = window.loc.strings.type3Video;
        typeTest = window.loc.strings.type3Test;
        break;
      case 4:
        videoLink = window.loc.strings.type4Video;
        typeTest = window.loc.strings.type4Test;
        break;
      case 5:
        videoLink = window.loc.strings.type5Video;
        typeTest = window.loc.strings.type5Test;
        break;
      case 6:
        videoLink = window.loc.strings.type6Video;
        typeTest = window.loc.strings.type6Test;
        break;
      case 7:
        videoLink = window.loc.strings.type7Video;
        typeTest = window.loc.strings.type7Test;
        break;
      case 8:
        videoLink = window.loc.strings.type8Video;
        typeTest = window.loc.strings.type8Test;
        break;
      case 9:
        videoLink = window.loc.strings.type9Video;
        typeTest = window.loc.strings.type9Test;
        break;
      default:
        break;
    }
    let slideHeight;
    if (document.getElementById("enneagrammyInfoPopupSlide_results")) {
      const enneagrammyInfoPopupSlide = document.getElementById("enneagrammyInfoPopupSlide_results");
      slideHeight = enneagrammyInfoPopupSlide.clientHeight;
    }
    return (
      <div
        className="enneagrammyTypeInfoPopup"
      >
        <div
          className="enneagrammyTypeInfoPopupBox"
          style={{
            top: this.state.top
          }}
        >
          <div
            className="enneagrammyTypeInfoPopupCloseButton"
            onClick={() => {
              this.setState({ top: window.innerHeight });
              setTimeout(() => {
                this.props.appStore.selectedType = undefined;
              }, 500);
            }}
          />
          <div className="enneagrammyInfoPopupPivots">
            <div
              className="enneagrammyInfoPopupPivot"
              style={{
                borderRight: "1px solid #272727",
                textAlign: 'right',
                paddingRight: 30,
                fontWeight: this.props.appStore.selectedTypeInfoTab === "result" ? "bold" : "lighter",
                fontFamily: this.props.appStore.selectedTypeInfoTab === "result" ? "Geogtq-Md" : "Geogtq-Rg",
                color: "#064859"
              }}
              onClick={() => {
                this.props.appStore.selectedTypeInfoTab = "result";
              }}
            >
              RESULTAT
            </div>
            <div
              className="enneagrammyInfoPopupPivot"
              style={{
                textAlign: 'left',
                paddingLeft: 30,
                fontWeight: this.props.appStore.selectedTypeInfoTab === "type" ? "bold" : "lighter",
                fontFamily: this.props.appStore.selectedTypeInfoTab === "type" ? "Geogtq-Md" : "Geogtq-Rg",
                color: "#064859"
              }}
              onClick={() => {
                this.props.appStore.selectedTypeInfoTab = "type";
              }}
            >
              OM TYPERNE
            </div>
          </div>
          <div
            className="enneagrammyInfoPopupWrapper"
            style={{
              left: this.props.appStore.selectedTypeInfoTab === "result" ? 0 : -screen.width + 18,
            }}
          >
            <div
              id={"enneagrammyInfoPopupSlide_results"}
              className="enneagrammyInfoPopupSlide"
            >
              <div
                className="enneagrammyResult"
                style={{
                  marginTop: 10,
                  marginLeft: 0,
                  width: "100%"
                }}
              >
                <div
                  className="enneagrammyResultHeadline"
                  style={{
                    color: "#272727"
                  }}
                  onClick={() => {
                    this.props.appStore.typeTestChangeName = true;
                  }}
                >
                  {this.props.appStore.selectedTestResult && this.props.appStore.selectedTestResult.name ? this.props.appStore.selectedTestResult.name : window.loc.strings.latestResult}
                </div>
                <div
                  className="enneagrammyResultDate"
                  style={{
                    color: "#272727"
                  }}
                >
                  {this.props.appStore.selectedTestResult && this.props.appStore.selectedTestResult.createdAt ? DateTimeService.ConvertToDDMMYYYYHHMM(this.props.appStore.selectedTestResult.createdAt) : ""}
                </div>
                <div
                  className="resultBars"
                  style={{
                    paddingLeft: 4,
                    paddingRight: 4
                  }}
                >
                  {resultBars}
                </div>
                <div
                  className="detailsBtn"
                  onClick={() => {
                    this.props.appStore.selectedType = resultToShow[0].id;
                    this.props.appStore.selectedTypeInfoTab = "type";
                  }}
                >
                  {window.loc.strings.details}
                </div>
              </div>
            </div>
            <div
              className="enneagrammyInfoPopupSlide"
              style={{
                height: slideHeight && this.props.appStore.selectedTypeInfoTab === "result" ? slideHeight : "auto",
                overflowY: slideHeight && this.props.appStore.selectedTypeInfoTab === "result" ? "hidden" : "auto"
              }}
            >
              <div
                className="enneagrammyInfoPopupIllustration"
                style={{
                  backgroundImage: this.props.appStore.isMobile ? `url("https://dojo.mindjuice.com/images/typetest-enneagram-grid-${this.props.appStore.selectedType}.png")` : `url("https://dojo.mindjuice.com/images/typetest-enneagram-grid-${this.props.appStore.selectedType}-white.png")`
                }}
              >
                <div
                  className="enneagrammyType1"
                  style={{
                    backgroundColor: "#064859",
                    transform: this.props.appStore.selectedType === 1 ? "scale(1.3)" : "scale(1.0)"
                  }}
                  onClick={() => this.props.appStore.selectedType = 1}
                >
                  1
                </div>
                <div
                  className="enneagrammyType2"
                  style={{
                    backgroundColor: "#064859",
                    transform: this.props.appStore.selectedType === 2 ? "scale(1.3)" : "scale(1.0)"
                  }}
                  onClick={() => this.props.appStore.selectedType = 2}
                >
                  2
                </div>
                <div
                  className="enneagrammyType3"
                  style={{
                    backgroundColor: "#064859",
                    transform: this.props.appStore.selectedType === 3 ? "scale(1.3)" : "scale(1.0)"
                  }}
                  onClick={() => this.props.appStore.selectedType = 3}
                >
                  3
                </div>
                <div
                  className="enneagrammyType4"
                  style={{
                    backgroundColor: "#064859",
                    transform: this.props.appStore.selectedType === 4 ? "scale(1.3)" : "scale(1.0)"
                  }}
                  onClick={() => this.props.appStore.selectedType = 4}
                >
                  4
                </div>
                <div
                  className="enneagrammyType5"
                  style={{
                    backgroundColor: "#064859",
                    transform: this.props.appStore.selectedType === 5 ? "scale(1.3)" : "scale(1.0)"
                  }}
                  onClick={() => this.props.appStore.selectedType = 5}
                >
                  5
                </div>
                <div
                  className="enneagrammyType6"
                  style={{
                    backgroundColor: "#064859",
                    transform: this.props.appStore.selectedType === 6 ? "scale(1.3)" : "scale(1.0)"
                  }}
                  onClick={() => this.props.appStore.selectedType = 6}
                >
                  6
                </div>
                <div
                  className="enneagrammyType7"
                  style={{
                    backgroundColor: "#064859",
                    transform: this.props.appStore.selectedType === 7 ? "scale(1.3)" : "scale(1.0)"
                  }}
                  onClick={() => this.props.appStore.selectedType = 7}
                >
                  7
                </div>
                <div
                  className="enneagrammyType8"
                  style={{
                    backgroundColor: "#064859",
                    transform: this.props.appStore.selectedType === 8 ? "scale(1.3)" : "scale(1.0)"
                  }}
                  onClick={() => this.props.appStore.selectedType = 8}
                >
                  8
                </div>
                <div
                  className="enneagrammyType9"
                  style={{
                    backgroundColor: "#064859",
                    transform: this.props.appStore.selectedType === 9 ? "scale(1.3)" : "scale(1.0)"
                  }}
                  onClick={() => this.props.appStore.selectedType = 9}
                >
                  9
                </div>
              </div>
              <div className="enneagrammyInfoPopupIllustrationInfo">&#9432; Klik på illustrationen for at læse om en anden type</div>
              <div className="enneagrammyVideo">
                <iframe
                  src={`https://player.vimeo.com/video/${videoLink}`}
                  width={window.innerWidth - 55}
                  height={(window.innerWidth - 55) / 16 * 9}
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  allowFullScreen
                  style={{
                    backgroundColor: "#064859"
                  }}
                />
              </div>
              <div className="enneagrammyInfo" dangerouslySetInnerHTML={{ __html: typeTest }} />
            </div>
          </div>
        </div>
        {this.props.appStore.typeTestChangeName &&
          <div
            className="enneagrammyChangeNameBoxContainer"
          >
            <div className="enneagrammyChangeNameBox">
              <div
                className="enneagrammyChangeNameBoxCloseButton"
                onClick={() => this.props.appStore.typeTestChangeName = false}
              />
              <TypeTestChangeName />
            </div>
          </div>
        }
      </div>
    );
  }

}