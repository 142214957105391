import * as React from 'react';
import './EnneagrammyQuestion.css';
import { AppStore } from '../../../stores';
import { inject, observer } from 'mobx-react';
import Answer from './enneagrammyAnswer/EnneagrammyAnswer';
import * as ReactGA from 'react-ga';

export interface IProps {
  appStore?: AppStore;
  index: number;
}

@inject('appStore')
@observer
export default class enneagrammyQuestion extends React.Component<IProps> {

  render() {
    return (
      <div className="enneagrammyQuestion">
        <Answer
          id={"a"}
          text={window.loc.strings.language === "Danish" ? this.props.appStore.questions[this.props.index].answers[0].answer : this.props.appStore.questions[this.props.index].answers[0].answerEn}
          selected={this.props.appStore.questions[this.props.index].answers[0].selected}
          answered={this.props.appStore.questions[this.props.index].answers[1].selected}
          onSelect={() => {
            ReactGA.event({
              category: 'Tests',
              action: `User answered Enneagram test question: ${this.props.index}`
            });
            this.props.appStore.selectAnswer(0);
          }}
        />
        <Answer
          id={"b"}
          text={window.loc.strings.language === "Danish" ? this.props.appStore.questions[this.props.index].answers[1].answer : this.props.appStore.questions[this.props.index].answers[1].answerEn}
          selected={this.props.appStore.questions[this.props.index].answers[1].selected}
          answered={this.props.appStore.questions[this.props.index].answers[0].selected}
          onSelect={() => {
            ReactGA.event({
              category: 'Tests',
              action: `User answered Enneagram test question: ${this.props.index}`
            });
            this.props.appStore.selectAnswer(1);
          }}
        />
      </div>
    );
  }

}