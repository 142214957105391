import * as React from 'react';
import './EnneagrammyLogin.css';
import { inject, observer } from 'mobx-react';
import { AppStore } from '../../stores';
import Login from '../../mindjuiceApp/landingPage/login/Login';
import SignUp from '../../mindjuiceApp/landingPage/signUp/SignUp';
import ForgottenPassword from '../../mindjuiceApp/landingPage/forgottenPassword/ForgottenPassword';
import ForgottenPasswordConfirmation from '../../mindjuiceApp/landingPage/forgottenPasswordConfirmation/ForgottenPasswordConfirmation';

export interface IProps {
  appStore?: AppStore;
}

export interface IState {
  loginError: boolean;
  showLogo: boolean;
  showButtons: boolean;
}

@inject('appStore')
@observer
export default class EnneagrammyLogin extends React.Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
      loginError: false,
      showLogo: true,
      showButtons: false
    }
  }

  public componentDidMount(): void {
    setTimeout(() => {
      this.setState({ showLogo: false });
      setTimeout(() => {
        this.setState({ showButtons: true });
      }, 1000);
    }, 2000);
  }

  render() {
    return (
      <div className="enneagrammyLogin"
        style={{
          backgroundImage: this.state.showLogo ? "url(https://dojo.mindjuice.com/images/typetest-withMindjuice.png)" : "url(https://dojo.mindjuice.com/images/typetest.png)"
        }}
      >
        <div
          className="enneagrammyLoginBottons"
          style={{
            opacity: this.state.showButtons ? 1 : 0,
            bottom: 20
          }}
        >
          <div
            className="enneagrammyLoginBotton"
            onClick={() => this.props.appStore.showingSignUp = true}
          >
            {window.loc.strings.createAUser}
          </div>
          <div
            className="enneagrammyLoginBotton"
            onClick={() => this.props.appStore.showingLogin = true}
          >
            {window.loc.strings.iHaveAUser}
          </div>
        </div>
        {/* <div
          className="enneagrammyLoginBottons"
          style={{
            opacity: this.state.showLogo ? 1 : 0,
            bottom: 10,
            display: this.state.showButtons ? "none" : "block"
          }}
        >
          <div className={"enneagrammyBy"}>{window.loc.strings.by}</div>
          <div className={"enneagrammyByMindjuice"}>MINDJUICE</div>
        </div> */}
        {this.props.appStore.showingLogin &&
          <div
            className="enneagrammyLoginBoxContainer"
          >
            <div className="enneagrammyLoginBox">
              <div
                className="enneagrammyLoginBoxCloseButton"
                onClick={() => this.props.appStore.showingLogin = false}
              />
              <Login
                buttonColor={this.props.appStore.typeTestColor}
              />
            </div>
          </div>
        }
        {this.props.appStore.showingSignUp &&
          <div
            className="enneagrammyLoginBoxContainer"
          >
            <div className="enneagrammyLoginBox">
              <div
                className="enneagrammyLoginBoxCloseButton"
                onClick={() => this.props.appStore.showingSignUp = false}
              />
              <SignUp
                buttonColorLight
                buttonColor={this.props.appStore.typeTestColor}
              />
            </div>
          </div>
        }
        {this.props.appStore.showingForgottenPassword &&
          <ForgottenPassword
            buttonColor={this.props.appStore.typeTestColor}
          />
        }
        {this.props.appStore.showingForgottenPasswordConfirmation &&
          <ForgottenPasswordConfirmation />
        }
      </div>
    );
  }

}