import * as React from 'react';
import './Podcasts.css';
import { AppStore } from '../../stores';
import { inject, observer } from 'mobx-react';
import PodcastService from '../../services/PodcastService';
import { IPodcast } from '../../interfaces/IPodcast';
import PodcastCard from './podcastCard/PodcastCard';
import Header from '../header/Header';
import { CardViewService } from '../../services/CardView.service';
import VideoList from './videoList/VideoList';
import LogoBar from '../logoBar/LogoBar';
import Card from '../card/Card';

export interface IProps {
  appStore?: AppStore;
}

export interface IState {
  containerWidth: number;
}

@inject('appStore')
@observer
export default class Podcasts extends React.Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
      containerWidth: undefined
    }
  }

  public componentDidMount(): void {
    this.props.appStore.selectedTab = 3;
    this.getPodcasts();
    // Resize
    window.addEventListener('resize', () => this.resize());
    this.resize();
    setTimeout(() => {
      this.resize();
    }, 0);
    try {
      var resizeObserver = new ResizeObserver(() => {
        this.resize();
      });
      resizeObserver.observe(document.getElementById(`podcasts`));
    } catch {
      this.resize();
    }
  }

  private resize(): void {
    const programsContainer = document.getElementById("podcasts");
    const padding = this.props.appStore.isMobile ? 40 : 80;
    this.setState({ containerWidth: programsContainer?.clientWidth - padding }, () => this.forceUpdate());
  }

  private getPodcasts(): void {
    PodcastService.getPodcasts().then((podcasts: IPodcast[]) => {
      this.props.appStore.podcasts = podcasts.reverse();
      this.props.appStore.podcasts.forEach((podcast) => {
        if (location.search && location.search.indexOf("?podcast=") != -1) {
          if (podcast.objectId === location.search.replace("?podcast=", "")) {
            this.props.appStore.showPodcastPlayerInFullScreen = true;
            this.props.appStore.podcastToPlay = podcast;
          }
        }
      })
    });
  }

  render() {
    const mindjuice = [];
    const mindjuiceHighlighted = [];
    const speakers = [];
    const speakersHighlighted = [];
    if (this.props.appStore.podcasts) {
      const [numberOfCardsPerRow, maxCardWidthAvailable] = new CardViewService().getNumberOfCardsPerRowAndMaxCardWidthAvailable(this.state.containerWidth, this.props.appStore.isMobile ? 15 : 30, this.props.appStore.editMode ? 300 : 200, 6);
      let currentCardRowNumberMindjuiceHighlighted = 1;
      let currentCardRowNumberMindjuice = 1;
      const [numberOfVideoListElementsPerRow, maxVideoListElementWidthAvailable] = new CardViewService().getNumberOfCardsPerRowAndMaxCardWidthAvailable(this.state.containerWidth, this.props.appStore.editMode ? 30 : 40, this.props.appStore.editMode ? 300 : 330, 6);
      let currentVideoListElementRowNumberSpeakersHighlighted = 1;
      let currentVideoListElementRowNumberSpeakers = 1;
      this.props.appStore.podcasts.forEach((podcast: IPodcast) => {
        if (this.props.appStore.editMode) {
          if (this.props.appStore.podcastarea === "mindjuice" && (podcast.category === "Mindjuice" || podcast.category == undefined)) {
            mindjuiceHighlighted.push(
              <Card
                key={podcast.objectId}
                type={this.props.appStore.podcastarea}
                width={this.props.appStore.isMobile ? screen.availWidth - 40 : (maxCardWidthAvailable <= 350 ? maxCardWidthAvailable : 350)}
                marginRight={currentCardRowNumberMindjuiceHighlighted == numberOfCardsPerRow || this.props.appStore.isMobile ? 0 : 30}
                title={podcast.title}
                image={podcast.image}
                area='meditations'
                buttons={[
                  {
                    title: "Rediger",
                    style: {
                      backgroundColor: "#ffffff",
                      color: "#272727"
                    },
                    onClick: () => {
                      this.props.appStore.podcastToEdit = podcast;
                    }
                  }
                ]}
              />
            );
            if (currentCardRowNumberMindjuiceHighlighted == numberOfCardsPerRow) {
              currentCardRowNumberMindjuiceHighlighted = 1;
            } else {
              currentCardRowNumberMindjuiceHighlighted++;
            }
          }
          if (this.props.appStore.podcastarea === "speakers" && podcast.category === "Speakers") {
            speakersHighlighted.push(
              <Card
                key={podcast.objectId}
                type={this.props.appStore.podcastarea}
                width={this.props.appStore.isMobile ? screen.availWidth - 40 : (maxVideoListElementWidthAvailable <= 350 ? maxVideoListElementWidthAvailable : 350)}
                marginRight={currentVideoListElementRowNumberSpeakersHighlighted == numberOfVideoListElementsPerRow || this.props.appStore.isMobile ? 0 : 30}
                title={podcast.title}
                image={podcast.image}
                area='meditations'
                buttons={[
                  {
                    title: "Rediger",
                    style: {
                      backgroundColor: "#ffffff",
                      color: "#272727"
                    },
                    onClick: () => {
                      this.props.appStore.podcastToEdit = podcast;
                    }
                  }
                ]}
              />
            );
            if (currentVideoListElementRowNumberSpeakersHighlighted == numberOfVideoListElementsPerRow) {
              currentVideoListElementRowNumberSpeakersHighlighted = 1;
            } else {
              currentVideoListElementRowNumberSpeakersHighlighted++;
            }
          }
        } else {
          if (podcast.category === "Speakers" && this.props.appStore.podcastarea === "speakers" && podcast.released) {
            if (localStorage.getItem(`podcast_${podcast.objectId}`)) {
              const duration = localStorage.getItem(`podcast_${podcast.objectId}`);
              speakersHighlighted.push(
                <VideoList
                  key={podcast.objectId}
                  id={podcast.objectId}
                  width={this.props.appStore.isMobile ? screen.availWidth - 40 : (maxVideoListElementWidthAvailable <= 500 ? maxVideoListElementWidthAvailable : 500)}
                  marginRight={currentVideoListElementRowNumberSpeakersHighlighted == numberOfVideoListElementsPerRow || this.props.appStore.isMobile ? 0 : 30}
                  title={podcast.title}
                  speaker={podcast.speaker}
                  description={podcast.teaser}
                  label={podcast.speakerYear ? podcast.speakerYear : undefined}
                  image={podcast.image}
                  selected={this.props.appStore.podcastToPlay?.objectId === podcast.objectId}
                  duration={parseInt(duration.split("-")[1])}
                  progress={parseInt(duration.split("-")[0])}
                  views={podcast.views}
                  onClick={() => {
                    this.props.appStore.showPodcastPlayerInFullScreen = !(podcast.link != undefined || podcast.hideVideo);
                    this.props.appStore.podcastToPlay = podcast;
                  }}
                />
              );
              if (currentVideoListElementRowNumberSpeakersHighlighted == maxVideoListElementWidthAvailable) {
                currentVideoListElementRowNumberSpeakersHighlighted = 1;
              } else {
                currentVideoListElementRowNumberSpeakersHighlighted++;
              }
            }
            speakers.push(
              <VideoList
                key={podcast.objectId}
                id={podcast.objectId}
                width={this.props.appStore.isMobile ? screen.availWidth - 40 : (maxVideoListElementWidthAvailable <= 500 ? maxVideoListElementWidthAvailable : 500)}
                marginRight={currentVideoListElementRowNumberSpeakers == numberOfVideoListElementsPerRow || this.props.appStore.isMobile ? 0 : 30}
                title={podcast.title}
                speaker={podcast.speaker}
                description={podcast.teaser}
                label={podcast.speakerYear ? podcast.speakerYear : undefined}
                image={podcast.image}
                selected={this.props.appStore.podcastToPlay?.objectId === podcast.objectId}
                views={podcast.views}
                onClick={() => {
                  this.props.appStore.showPodcastPlayerInFullScreen = !(podcast.link != undefined || podcast.hideVideo);
                  this.props.appStore.podcastToPlay = podcast;
                }}
              />
            );
            if (currentVideoListElementRowNumberSpeakers == numberOfVideoListElementsPerRow) {
              currentVideoListElementRowNumberSpeakers = 1;
            } else {
              currentVideoListElementRowNumberSpeakers++;
            }
          }
          if ((podcast.category === "Mindjuice" || podcast.category == undefined) && this.props.appStore.podcastarea === "mindjuice" && podcast.released) {
            if (localStorage.getItem(`podcast_${podcast.objectId}`)) {
              const duration = localStorage.getItem(`podcast_${podcast.objectId}`);
              mindjuiceHighlighted.push(
                <PodcastCard
                  key={podcast.objectId}
                  id={podcast.objectId}
                  width={(maxCardWidthAvailable <= 350 ? maxCardWidthAvailable : 350)}
                  marginRight={currentCardRowNumberMindjuiceHighlighted == numberOfCardsPerRow ? 0 : (this.props.appStore.isMobile ? 15 : 30)}
                  title={podcast.title}
                  speaker={podcast.speaker}
                  podcastDuration={podcast.duration}
                  image={podcast.image}
                  description={podcast.teaser}
                  category={podcast.category === "Speakers" ? "Speakers" : "Mindjuice"}
                  selected={this.props.appStore.podcastToPlay?.objectId === podcast.objectId}
                  duration={parseInt(duration.split("-")[1])}
                  progress={parseInt(duration.split("-")[0])}
                  views={podcast.views}
                  onClick={() => {
                    this.props.appStore.showPodcastPlayerInFullScreen = !(podcast.link != undefined || podcast.hideVideo);
                    this.props.appStore.podcastToPlay = podcast;
                  }}
                />
              );
              if (currentCardRowNumberMindjuiceHighlighted == numberOfCardsPerRow) {
                currentCardRowNumberMindjuiceHighlighted = 1;
              } else {
                currentCardRowNumberMindjuiceHighlighted++;
              }
            }
            mindjuice.push(
              <PodcastCard
                key={podcast.objectId}
                id={podcast.objectId}
                width={(maxCardWidthAvailable <= 350 ? maxCardWidthAvailable : 350)}
                marginRight={currentCardRowNumberMindjuice == numberOfCardsPerRow ? 0 : (this.props.appStore.isMobile ? 15 : 30)}
                title={podcast.title}
                speaker={podcast.speaker}
                podcastDuration={podcast.duration}
                category={podcast.category === "Speakers" ? "Speakers" : "Mindjuice"}
                image={podcast.image}
                views={podcast.views}
                selected={this.props.appStore.podcastToPlay?.objectId === podcast.objectId}
                onClick={() => {
                  this.props.appStore.showPodcastPlayerInFullScreen = !(podcast.link != undefined || podcast.hideVideo);
                  this.props.appStore.podcastToPlay = podcast;
                }}
              />
            );
            if (currentCardRowNumberMindjuice == numberOfCardsPerRow) {
              currentCardRowNumberMindjuice = 1;
            } else {
              currentCardRowNumberMindjuice++;
            }
          }
        }
      });
    }
    return (
      <div
        id='podcasts'
        className='podcasts'
        style={{
          top: this.props.appStore.isMobile && window.platform !== "ios" ? 0 : 50,
          left: this.props.appStore.isMobile ? 0 : 250,
          width: this.props.appStore.isMobile ? "100vw" : "calc(100vw - 250px)",
          height: this.props.appStore.isMobile ? `calc(100% - ${this.props.appStore.isMobile && window.platform !== "ios" ? 80 : 130}px)` : (this.props.appStore.podcastToPlay ? `calc(100% - ${this.props.appStore.isMobile && window.platform !== "ios" ? 101 : 151}px)` : `calc(100% - ${this.props.appStore.isMobile && window.platform !== "ios" ? 0 : 50}px)`)
        }}
      >
        {location.href.indexOf("mindPaperId") == -1 && this.props.appStore.user && this.props.appStore.isMobile && !window.location.pathname.includes("/enneagrammy") &&
          <LogoBar />
        }
        <Header
          headline={window.loc.strings.podcastsHeader}
          button={this.props.appStore.editMode ? {
            title: "Tilføj podcast",
            onClick: () => this.props.appStore.podcastToEdit = {}
          } : undefined}
        />
        <div className='podcastsSelectors'>
          <div
            className='podcastsSelector'
            style={{
              float: "left",
              lineHeight: this.props.appStore.isMobile ? "21px" : "70px",
              paddingTop: this.props.appStore.isMobile ? 14 : 0,
              background: this.props.appStore.podcastarea === "mindjuice" ? "linear-gradient(90deg, rgba(182,61,216,1) 0%, rgba(102,45,145,1) 100%)" : "#272727"
            }}
            onClick={() => {
              this.props.appStore.podcastarea = "mindjuice";
            }}
          >
            Mindjuice originals
          </div>
          <div
            className='podcastsSelector'
            style={{
              float: "left",
              marginLeft: 20,
              background: this.props.appStore.podcastarea === "speakers" ? "linear-gradient(90deg, rgba(182,61,216,1) 0%, rgba(102,45,145,1) 100%)" : "#272727"
            }}
            onClick={() => {
              this.props.appStore.podcastarea = "speakers";
            }}
          >
            Speakers
          </div>
        </div>
        <div className="programsBox">
          <div className="container">
            {((mindjuiceHighlighted?.length > 0 && this.props.appStore.podcastarea === "mindjuice") || (speakersHighlighted?.length > 0 && this.props.appStore.podcastarea === "speakers")) &&
              <div className='podcastsHighlighted'>
                <div className='podcastsSelection'>{window.loc.strings.podcastSectionContinue}</div>
                {this.props.appStore.podcastarea === "mindjuice" ? mindjuiceHighlighted : speakersHighlighted}
              </div>
            }
            <div className='podcastsAll'>
              <div className='podcastsSelection'>{this.props.appStore.podcastarea === "mindjuice" ? window.loc.strings.podcastSectionMindjuice : window.loc.strings.podcastSectionSpeakers}</div>
              {this.props.appStore.podcastarea === "mindjuice" ? mindjuice : speakers}
            </div>
          </div>
        </div>
      </div >
    );
  }

  public componentWillUnmount(): void {
    window.removeEventListener('resize', () => this.resize());
  }

}