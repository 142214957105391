import * as React from 'react';
import './AdminUpdatePasswordPopup.css';
import { AppStore } from '../../stores';
import { inject, observer } from 'mobx-react';
import AuthenticationService from '../../services/AuthenticationService';
import AdminService from '../../services/AdminService';

export interface IProps {
  appStore?: AppStore;
}

export interface IState {
  newPassword?: string;
  newPasswordAgain?: string;
}

@inject('appStore')
@observer
export default class AdminUpdatePasswordPopup extends React.Component<IProps, IState> {

  constructor(props, context) {
    super(props, context);
    this.state = {
    }
  }

  render() {
    return (
      <div className="adminUpdatePasswordPopup">
        <div
          className="adminUpdatePasswordPopupBackground"
          onClick={() => {
            this.props.appStore.showingAdminUpdatePasswordForUser = undefined;
          }}
        />
        <div className="adminUpdatePasswordPopupBox">
          <h3>{`Opdater kodeord for ${this.props.appStore.showingAdminUpdatePasswordForUser?.name}`}</h3>
          <input
            type="password"
            placeholder={window.loc.strings.newPassword}
            onChange={(event: any) => {
              this.setState({ newPassword: event.target.value.toLowerCase() });
            }}
          />
          <input
            type="password"
            placeholder={window.loc.strings.newPasswordAgain}
            onChange={(event: any) => {
              this.setState({ newPasswordAgain: event.target.value.toLowerCase() });
            }}
          />
          <div
            className="adminUpdateBtn"
            style={{
              opacity: (this.state.newPassword && this.state.newPassword === this.state.newPasswordAgain) ? 1 : 0.5
            }}
            onClick={() => {
              if (this.state.newPassword && this.state.newPassword === this.state.newPassword) {
                this.props.appStore.showingAdminUpdatePasswordForUser.password = this.state.newPasswordAgain;
                AdminService.updateUser(this.props.appStore.showingAdminUpdatePasswordForUser, this.props.appStore.user.masterKey).then(() => {
                  this.props.appStore.showingAdminUpdatePasswordForUser = undefined;
                })
              }
            }}
          >
            {window.loc.strings.updatePassword}
          </div>
          <div
            className="cancelBtn"
            onClick={() => {
              this.props.appStore.showingAdminUpdatePasswordForUser = undefined;
            }}
          >
            {window.loc.strings.cancel}
          </div>
        </div>
      </div>
    );
  }

}