import * as React from 'react';
import { render } from 'react-dom';
import './index.css';
import App from './App';
import { AppStore } from './stores';
import { Provider } from 'mobx-react';

declare global {
  interface Window { webkit: any; app: any; android: WebAppInterface; platform: string }
  interface WebAppInterface { postMessage(message: string): any; }
}

render(
  <Provider appStore={new AppStore()}>
    <App
      ref={(app: any) => {
        if (app) {
          window.app = app
        }
      }}
    />
  </Provider>,
  document.getElementById('root')
);