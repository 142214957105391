import * as React from 'react';
import './SignUp.css';
import { AppStore } from '../../../stores';
import { inject, observer } from 'mobx-react';
import AuthenticationService from '../../../services/AuthenticationService';
import { IUser } from '../../../interfaces/IUser';
import * as ReactGA from 'react-ga';

export interface IProps {
  appStore?: AppStore;
  buttonColorLight?: boolean;
  buttonColor: string;
}

export interface IState {
  username: string;
  name: string;
  password: string;
  passwordAgain: string;
  savingEnabled: boolean;
  signUpError: boolean;
  validEmail: boolean;
  disclaimerAcceepted: boolean;
  showPassword: boolean;
  showPasswordAgain: boolean;
}

@inject('appStore')
@observer
export default class SignUp extends React.Component<IProps, IState> {

  constructor(props, context) {
    super(props, context);
    this.state = {
      username: undefined,
      password: undefined,
      passwordAgain: undefined,
      name: undefined,
      savingEnabled: false,
      signUpError: false,
      validEmail: true,
      disclaimerAcceepted: false,
      showPassword: false,
      showPasswordAgain: false
    }
  }

  public componentDidMount(): void {
    document.addEventListener("keyup", ((event) => {
      if (event.keyCode === 13) {
        event.preventDefault();
        this.signUp();
      }
    }));
  }

  private validate(): void {
    if (this.state.username && this.state.name && this.state.password && this.state.disclaimerAcceepted && this.state.password === this.state.passwordAgain) {
      this.setState({ savingEnabled: true });
    } else {
      this.setState({ savingEnabled: false });
    }
  }

  private validateEmail(email): boolean {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  private signUp(): void {
    if (this.state && this.state.savingEnabled) {
      if (this.validateEmail(this.state.username)) {
        this.props.appStore.loggingIn = true;
        AuthenticationService.signUp(this.state.username, this.state.password, this.state.name).then((user: IUser) => {
          ReactGA.event({
            category: 'SignUp',
            action: 'User created account'
          });
          if (user) {
            this.props.appStore.setUser(user);
          } else {
            this.setState({ signUpError: true });
            setTimeout(() => {
              this.setState({ signUpError: false });
            }, 1000);
          }
          this.props.appStore.loggingIn = false;
          if (location.href.includes("podcast=")) {
            location.reload();
          }
        });
      } else {
        this.setState({ validEmail: false });
      }
    }
  }

  render() {
    return (
      <div
        className="signUp"
        style={{
          animation: this.state.signUpError ? "shake 0.82s cubic-bezier(.36,.07,.19,.97) both" : "none",
          transform: "translate3d(0, 0, 0)",
          backfaceVisibility: "hidden",
          perspective: "1000px"
        }}
      >
        <input
          type="email"
          placeholder={window.loc.strings.email}
          style={{
            color: this.state.validEmail ? "#272727" : "red"
          }}
          onChange={(event: any) => {
            if (!this.state.validEmail) {
              this.setState({ validEmail: this.validateEmail(event.target.value) });
            }
            this.setState({ username: event.target.value.toLowerCase() }, () => this.validate());
          }}
        />
        <input
          type="text"
          placeholder={window.loc.strings.name}
          onPaste={() => {
            setTimeout(() => {
              this.validate();
            }, 100);
          }}
          onChange={(event: any) => {
            this.setState({ name: event.target.value }, () => this.validate());
          }}
        />
        <input
          type={this.state.showPassword ? "text" : "password"}
          placeholder={window.loc.strings.password}
          onPaste={() => {
            setTimeout(() => {
              this.validate();
            }, 100);
          }}
          onChange={(event: any) => {
            this.setState({ password: event.target.value }, () => this.validate());
          }}
        />
        <div
          className='showPasswordButton'
          style={{
            marginTop: 110,
            backgroundImage: this.state.showPassword ? 'url("https://img.icons8.com/fluency-systems-regular/48/invisible.png")' : 'url("https://img.icons8.com/fluency-systems-regular/48/000000/visible--v1.png")'
          }}
          onClick={() => this.setState({ showPassword: !this.state.showPassword })}
        />
        <input
          type={this.state.showPasswordAgain ? "text" : "password"}
          placeholder={window.loc.strings.passwordAgain}
          onPaste={() => {
            setTimeout(() => {
              this.validate();
            }, 100);
          }}
          style={{
            color: this.state.passwordAgain === this.state.password ? "#272727" : "red"
          }}
          onChange={(event: any) => {
            this.setState({ passwordAgain: event.target.value }, () => this.validate());
          }}
        />
        <div
          className='showPasswordButton'
          style={{
            marginTop: 35,
            backgroundImage: this.state.showPasswordAgain ? 'url("https://img.icons8.com/fluency-systems-regular/48/invisible.png")' : 'url("https://img.icons8.com/fluency-systems-regular/48/000000/visible--v1.png")'
          }}
          onClick={() => this.setState({ showPasswordAgain: !this.state.showPasswordAgain })}
        />
        <div
          className={this.props.buttonColorLight ? "disclaimerLight" : "disclaimer"}
          style={{
            color: this.props.buttonColorLight ? "#ffffff" : "#122c43"
          }}
        >
          <input
            checked={this.state.disclaimerAcceepted}
            type="checkbox"
            onChange={() => {
              this.setState({ disclaimerAcceepted: !this.state.disclaimerAcceepted }, () => this.validate());
            }}
          />
          {window.loc.strings.iAcceptThePrivacy} <a href={window.loc.strings.language === "Danish" ? "https://www.dojo.mindjuice.com/privacy-policy-da.html" : "https://www.dojo.mindjuice.com/privacy-policy-en.html"} target="_blank">{window.loc.strings.personalDataPolicy}</a>.
        </div>
        <div
          className="signUpBtn"
          style={{
            opacity: this.state.savingEnabled ? 1 : 0.5,
            cursor: this.state.savingEnabled ? "pointer" : "default",
          }}
          onClick={() => {
            this.signUp();
          }}
        >
          {window.loc.strings.signUp?.toUpperCase()}
        </div>
      </div>
    );
  }

}