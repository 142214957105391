import * as React from 'react';
import './EventOrderPanel.css';
import { AppStore } from '../../../stores';
import { inject, observer } from 'mobx-react';
import { IProduct } from '../../../interfaces/IProduct';
import { Icon } from '../../../mindjuiceApp/UI/icon';

export interface IProps {
  appStore?: AppStore;
}

export interface IState {
  hasChanged: boolean;
}

@inject('appStore')
@observer
export default class EventOrderPanel extends React.Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
      hasChanged: false
    }
  }

  private array_move(arr, old_index, new_index): any[] {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  };

  render() {
    const products = [];
    this.props.appStore.events.forEach((event: IProduct, index) => {
      products.push(
        <div
          key={`productModule_${index}`}
          className="productModule"
          style={{
            backgroundColor: event.public ? "#eeeeee" : "#eeeeee7a"
          }}
        >
          <div 
          className="productModuleTitle"
          style={{
            color: event.public ? "#333333" : "#999999"
          }}
          >
            {event.title}
          </div>

          <button
            style={{
              float: "right",
              opacity: index === this.props.appStore.events.length - 1 ? 0.5 : 1
            }}
            disabled={index === this.props.appStore.events.length - 1}
            onClick={() => {
              this.setState({ hasChanged: true });
              this.props.appStore.events = this.array_move(this.props.appStore.events, index, index + 1);
            }}
          >
            <Icon
              title={"ArrowDown"}
              size={18}
              color={"#ffffff"}
              style={{
              }}
              onClick={() => { }}
            />
          </button>
          <button
            style={{
              float: "right",
              opacity: index === 0 ? 0.5 : 1
            }}
            disabled={index === 0}
            onClick={() => {
              this.setState({ hasChanged: true });
              this.props.appStore.events = this.array_move(this.props.appStore.events, index, index - 1);
            }}
          >
            <Icon
              title={"ArrowUp"}
              size={18}
              color={"#ffffff"}
              style={{
              }}
              onClick={() => { }}
            />
          </button>
        </div>
      );
    });
    return (
      <div>
        <div
          className="eventEditPanelWrapper"
          style={{
            visibility: this.props.appStore.showEventOrderPanel ? "visible" : "hidden"
          }}
          onClick={() => {
            if (this.state.hasChanged) {
              this.props.appStore.showingEventOrderPopup = true;
            } else {
              this.props.appStore.showEventOrderPanel = false;
            }
          }}
        />
        <div
          className="eventEditPanel"
          style={{
            right: this.props.appStore.showEventOrderPanel ? 0 : -620
          }}
        >
          {this.props.appStore.showEventOrderPanel &&
            <div style={{
              width: "100%",
              position: "relative",
              float: "left"
            }}>
              <div className="editPanelCloseBtn"
                onClick={() => {
                  if (this.state.hasChanged) {
                    this.props.appStore.showingEventOrderPopup = true;
                  } else {
                    this.props.appStore.showEventOrderPanel = false;
                  }
                }}
              />
              <h3>Tilpas rækkefølgen</h3>
              {products}
            </div>
          }
        </div>
      </div>
    );
  }
}