import * as React from 'react';
import './ExtendedMenu.css';
import { AppStore } from '../../stores';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';

export interface IProps {
  appStore?: AppStore;
}

export interface IState {
}

@inject('appStore')
@observer
export default class ExtendedMenu extends React.Component<IProps, IState> {

  constructor(props,) {
    super(props);
    this.state = {
    }
  }

  render() {
    return (
      <div>
        {this.props.appStore.showExtendedMenu &&
          <div
            className="enneagrammyExtendedMenuOverlay"
            onClick={() => this.props.appStore.showExtendedMenu = false}
          />
        }
        <div
          className="enneagrammyExtendedMenu"
          style={{
            bottom: this.props.appStore.showExtendedMenu ? 80 : -80
          }}
        >
          {/* <a href={window.loc.strings.language === "Danish" ? "https://www.mindjuice.com/faq/" : "https://www.mindjuice.com/faq-the-mindjuice-dojo/"} target="_blank">
            <div className="enneagrammyExtendedMenuItem">
              <div
                className="enneagrammyExtendedMenuItemIcon"
                style={{
                  backgroundImage: `url(https://dojo.mindjuice.com/images/menu-faq.png)`,
                  filter: "invert(100%)"
                }}
              />
              <div className="enneagrammyExtendedMenuItemText">
                {window.loc.strings.tabBarFAQ}
              </div>
            </div>
          </a> */}
          <Link
            to={`${this.props.appStore.environment}/enneagrammy/`}
            key={"DeleteUser"}
          >
            <div
              className="enneagrammyExtendedMenuItem"
              onClick={() => {
                this.props.appStore.isShowingUserProfilePopup = true;
              }}
            >
              <div
                className="enneagrammyExtendedMenuItemIcon"
                style={{
                  backgroundImage: `url(https://img.icons8.com/fluency-systems-filled/96/ffffff/guest-male.png)`
                }}
              />
              <div className="enneagrammyExtendedMenuItemText">
                {window.loc.strings.userProfile}
              </div>
            </div>
          </Link>
          <Link
            to={`${this.props.appStore.environment}/enneagrammy/`}
            key={"Subscriptions"}
          >
            <div
              className="enneagrammyExtendedMenuItem"
              onClick={() => {
                this.props.appStore.isShowingSubscriptionPopup = true;
              }}
            >
              <div
                className="enneagrammyExtendedMenuItemIcon"
                style={{
                  backgroundImage: `url(https://img.icons8.com/fluency-systems-regular/48/FFFFFF/shop-department.png)`
                }}
              />
              <div className="enneagrammyExtendedMenuItemText">
                {window.loc.strings.subscription}
              </div>
            </div>
          </Link>
        </div>
      </div>
    );
  }

}