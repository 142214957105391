import * as React from 'react';
import './SideBar.css';
import { inject, observer } from 'mobx-react';
import { AppStore } from '../../stores';
import { Link } from 'react-router-dom';

export interface IProps {
  appStore?: AppStore;
}

export interface IState {
}

@inject('appStore')
@observer
export default class SideBar extends React.Component<IProps, IState> {

  constructor(props,) {
    super(props);
    this.state = {
    }
  }

  render() {
    return (
      <div className="enneagrammySideBar"
        style={{
          height: this.props.appStore.podcastToPlay ? "calc(100% - 101px)" : "100%"
        }}
      >

        <Link
          to={`${this.props.appStore.environment}/enneagrammy/`}
          key={"Frontpage"}
        >
          <div
            className="sideBarItem"
            style={{
              opacity: this.props.appStore.selectedTypeTestTab === 1 ? 1 : 0.5
            }}
            onClick={() => {
              this.props.appStore.showExtendedMenu = false;
              this.props.appStore.selectedTypeTestTab = 1;
              this.render();
            }}
          >
            <div
              className="sideBarItemIcon"
              style={{
                backgroundImage: `url(https://dojo.mindjuice.com/images/test-icon.png)`
              }}
            />
            <div className="sideBarItemText">
              {window.loc.strings.tabBarTest}
            </div>
          </div>
        </Link>

        <Link
          to={`${this.props.appStore.environment}/enneagrammy/Profile`}
          key={"Profile"}
        >
          <div
            className="sideBarItem"
            style={{
              opacity: this.props.appStore.selectedTypeTestTab === 2 ? 1 : 0.5
            }}
            onClick={() => {
              this.props.appStore.showExtendedMenu = false;
              this.props.appStore.selectedTypeTestTab = 2;
              this.render();
            }}
          >
            <div
              className="sideBarItemIcon"
              style={{
                backgroundImage: `url(https://dojo.mindjuice.com/images/user.png)`,
                filter: "invert(100%)"
              }}
            />
            <div className="sideBarItemText">
              {window.loc.strings.tabBarProfile}
            </div>
          </div>
        </Link>

        <Link
          to={`${this.props.appStore.environment}/enneagrammy/Results`}
          key={"Result"}
        >
          <div
            className="sideBarItem"
            style={{
              opacity: this.props.appStore.selectedTypeTestTab === 3 ? 1 : 0.5
            }}
            onClick={() => {
              this.props.appStore.showExtendedMenu = false;
              this.props.appStore.selectedTypeTestTab = 3;
              this.render();
            }}
          >
            <div
              className="sideBarItemIcon"
              style={{
                backgroundImage: `url(https://dojo.mindjuice.com/images/result-icon.png)`
              }}
            />
            <div className="sideBarItemText">
              {window.loc.strings.tabBarResults}
            </div>
          </div>
        </Link>

        {/* <Link
          to={`${this.props.appStore.environment}/enneagrammy/TypeTestAbout`}
          key={"TypeTestAbout"}
        >
          <div
            className="sideBarItem"
            style={{
              opacity: this.props.appStore.selectedTypeTestTab === 4 ? 1 : 0.5
            }}
            onClick={() => {
              this.props.appStore.showExtendedMenu = false;
              this.props.appStore.selectedTypeTestTab = 4;
              this.render();
            }}
          >
            <div
              className="sideBarItemIcon"
              style={{
                backgroundImage: `url(https://dojo.mindjuice.com/images/info-icon.png)`
              }}
            />
            <div className="sideBarItemText">
              {window.loc.strings.tabBarAbout}
            </div>
          </div>
        </Link> */}
      </div>
    );
  }
}


