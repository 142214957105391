import * as React from 'react';
import './DeleteAccountPopup.css';
import { AppStore } from '../../stores';
import { inject, observer } from 'mobx-react';
import AuthenticationService from '../../services/AuthenticationService';
import { IDeletedUser } from '../../interfaces/IUser';

export interface IProps {
  appStore?: AppStore;
}

export interface IState {
}

@inject('appStore')
@observer
export default class DeleteAccountPopup extends React.Component<IProps, IState> {

  constructor(props, context) {
    super(props, context);
    this.state = {
    }
  }

  render() {
    return (
      <div className="deleteAccountPopup">
        <div
          className="deleteAccountPopupBackground"
          onClick={() => {
            this.props.appStore.showingDeleteAccount = false;
          }}
        />
        <div className="deleteAccountPopupBox">
          <h3>{window.loc.strings.deleteAccountPopupHeadline}</h3>
          <div
            className="deleteBtn"
            onClick={() => {
              const userToDelete: IDeletedUser = {
                username: this.props.appStore.user?.username,
                name: this.props.appStore.user?.name,
                signUpDate: this.props.appStore.user?.createdAt,
              }
              AuthenticationService.addDeletedUser(userToDelete).then(() => {
                AuthenticationService.delete(this.props.appStore.user.id, this.props.appStore.user.sessionToken).then(() => {
                  this.props.appStore.logOut();
                  this.props.appStore.showingDeleteAccount = false;
                  this.props.appStore.showSettings = false;
                });
              })
            }}
          >
            {window.loc.strings.delete}
          </div>
          <div
            className="cancelBtn"
            onClick={() => {
              this.props.appStore.showingDeleteAccount = false;
            }}
          >
            {window.loc.strings.cancel}
          </div>
        </div>
      </div>
    );
  }

}