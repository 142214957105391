import * as React from 'react';
import './ProductAccessPanel.css';
import { AppStore } from '../../../stores';
import { inject, observer } from 'mobx-react';
import AdminService from '../../../services/AdminService';
import { IProductAccess } from '../../../interfaces/IProductAccess';
import ProductService from '../../../services/ProductService';
import { ITeam } from '../../../interfaces/ITeam';

export interface IProps {
  appStore?: AppStore;
  productId: string;
}

export interface IState {
  username: string;
  selectedTeam: string;
  availableTeamsForProduct: ITeam[];
  availableTeamsForProductForNewUser: ITeam[];
  selectedNewTeam: string;
}

@inject('appStore')
@observer
export default class ProductAccessPanel extends React.Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
      username: undefined,
      selectedTeam: "all",
      availableTeamsForProduct: undefined,
      availableTeamsForProductForNewUser: undefined,
      selectedNewTeam: "noTeam",
    }
  }

  public componentWillReceiveProps(props: IProps): void {
    if (props.productId !== undefined) {
      setTimeout(() => {
        this.getUsersWithAccess(props.productId);
        this.getTeams(props.productId);
      }, 500);
    }
  }

  private getUsersWithAccess(productId: string): void {
    ProductService.getUsersWithAccessToProduct(productId).then((usersWithAccess: IProductAccess[]) => {
      if (usersWithAccess) {
        this.props.appStore.usersWithAccessToProduct = usersWithAccess;
        this.props.appStore.usersWithAccessToProductDivs = [];
        usersWithAccess.forEach((userWithAccess: IProductAccess) => {
          if (this.state.selectedTeam === "all" || this.state.selectedTeam === userWithAccess.team || (this.state.selectedTeam === "noTeam" && userWithAccess.team == undefined)) {
            this.props.appStore.usersWithAccessToProductDivs.push(
              <div
                key={"usersWithAccess_" + userWithAccess.objectId}
                className="emailLabel"
              >
                {userWithAccess.user}
                <div
                  className="emailLabelDeleteBtn"
                  onClick={() => {
                    ProductService.removeAccessToProduct(userWithAccess.objectId).then((status: number) => {
                      if (status === 200) {
                        this.getUsersWithAccess(productId);
                      }
                    });
                  }}
                />
              </div>
            );
          }
        })
        this.forceUpdate();
      }
    });
  }

  private getTeams(productId: string): void {
    ProductService.getTeamsForProduct(productId).then((teams: ITeam[]) => {
      if (teams) {
        const availableTeamsForProduct = [];
        const availableTeamsForProductForNewUser = [];
        availableTeamsForProduct.push(
          <option value="all">Alle hold</option>
        );
        availableTeamsForProduct.push(
          <option value="noTeam">Uden hold</option>
        );
        availableTeamsForProductForNewUser.push(
          <option value="noTeam">Uden hold</option>
        );
        teams.forEach((team: ITeam) => {
          availableTeamsForProduct.push(
            <option value={team.team}>{team.team}</option>
          );
          availableTeamsForProductForNewUser.push(
            <option value={team.team}>{team.team}</option>
          );
        });
        this.setState({ availableTeamsForProduct, availableTeamsForProductForNewUser });
        this.forceUpdate();
      }
    });
  }

  render() {
    return (
      <div>
        <div
          className="productAccessPanelWrapper"
          style={{
            visibility: this.props.appStore.showProductAccessPanelForProduct !== undefined ? "visible" : "hidden"
          }}
          onClick={() => {
            this.props.appStore.showProductAccessPanelForProduct = undefined;
          }}
        />
        <div
          className="productAccessPanel"
          style={{
            right: this.props.appStore.showProductAccessPanelForProduct !== undefined ? 0 : -620
          }}
        >
          <div className="editPanelCloseBtn"
            onClick={() => this.props.appStore.showProductAccessPanelForProduct = undefined}
          />
          <div className="addUserAccess">
            <h3>Tilføj bruger:</h3>
            <select
              name="cars"
              id="cars"
              placeholder="Vælg et hold"
              value={this.state.selectedNewTeam}
              onChange={(selectedOption: any) => {
                let selectedNewTeam = selectedOption.target.value !== "Vælg produkt" ? selectedOption.target.value : undefined;
                this.setState({ selectedNewTeam });
                this.forceUpdate();
              }}
            >
              {this.state.availableTeamsForProductForNewUser}
            </select>
            <div className="inputFieldLabel">Tilføj brugere fra CSV-fil</div>
            <input
              type="file"
              id="myFile"
              name="filename"
              onChange={(event) => {
                var files = event.target.files[0];
                if (files) {
                  var r = new FileReader();
                  r.onload = (e) => {
                    let target: any = e.target;
                    const users = target.result.split(/\r\n|\r|\n/g);
                    console.log(users);
                    users.forEach((newUser: string) => {
                      let alreadyAdded = false;
                      this.props.appStore.usersWithAccessToProduct.forEach((user: any) => {
                        if (user.user.toLowerCase() === newUser.toLowerCase()) {
                          alreadyAdded = true;
                        }
                      });
                      if (!alreadyAdded) {
                        ProductService.allowAccessToProduct(this.props.productId, newUser.toLowerCase(), this.state.selectedNewTeam === "noTeam" ? undefined : this.state.selectedNewTeam).then((status: number) => {
                          this.getUsersWithAccess(this.props.productId);
                        });
                      }
                    });
                  }
                  r.readAsText(files);
                } else {
                  alert("Failed to load file");
                }
              }}
            />
            <div className="inputFieldLabel">Email</div>
            <input
              type="text"
              value={this.state.username}
              onChange={(event: any) => {
                this.setState({ username: event.target.value.toLowerCase() });
              }}
            />
            <button
              onClick={() => {
                let alreadyAdded = false;
                this.props.appStore.usersWithAccessToProduct.forEach((user: any) => {
                  if (user.user === this.state.username) {
                    alreadyAdded = true;
                  }
                });
                if (!alreadyAdded) {
                  ProductService.allowAccessToProduct(this.props.productId, this.state.username, this.state.selectedNewTeam === "noTeam" ? undefined : this.state.selectedNewTeam).then((status: number) => {
                    this.setState({ username: "" });
                    this.getUsersWithAccess(this.props.productId);
                  });
                } else {
                  this.setState({ username: "" });
                }
              }}
            >
              Tilføj
            </button>
          </div>
          <h3>{`Brugere med adgang: (${this.props.appStore.usersWithAccessToProductDivs.length})`}</h3>
          {/* <div className="inputFieldLabel">Brugere med adgang til produkt</div> */}
          <select
            name="cars"
            id="cars"
            placeholder="Vælg et hold"
            value={this.state.selectedTeam}
            onChange={(selectedOption: any) => {
              let selectedTeam = selectedOption.target.value !== "Vælg produkt" ? selectedOption.target.value : undefined;
              this.setState({ selectedTeam }, () => this.getUsersWithAccess(this.props.productId));
              this.forceUpdate();
            }}
          >
            {this.state.availableTeamsForProduct}
          </select>
          <div style={{
            width: "100%",
            position: "relative",
            float: "left"
          }}>
            {this.props.appStore.usersWithAccessToProductDivs}
          </div>
        </div>
      </div>
    );
  }
}