import * as React from 'react';
import './LandingPage.css';
import { AppStore } from '../../stores';
import { inject, observer } from 'mobx-react';
import Login from './login/Login';
import SignUp from './signUp/SignUp';
import ForgottenPassword from './forgottenPassword/ForgottenPassword';
import ForgottenPasswordConfirmation from './forgottenPasswordConfirmation/ForgottenPasswordConfirmation';
import AuthenticationService from '../../services/AuthenticationService';
import { IUser } from '../../interfaces/IUser';
import * as ReactGA from 'react-ga';

export interface IProps {
  appStore?: AppStore;
}

export interface IState {
  showLoginButtons: boolean;
  username: string;
  password: string;
  loginEnabled: boolean;
  loginError: boolean;
  showPassword: boolean;
}

@inject('appStore')
@observer
export default class LandingPage extends React.Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
      showLoginButtons: false,
      username: undefined,
      password: undefined,
      loginEnabled: false,
      loginError: false,
      showPassword: false
    }
    document.addEventListener("keyup", ((event) => {
      if (event.keyCode === 13) {
        event.preventDefault();
        if (this.state.loginEnabled) {
          this.logIn();
        }
      }
    }));
  }

  public componentDidMount(): void {
    setTimeout(() => {
      this.setState({ showLoginButtons: true });
    }, 1000);
  }


  private validate(): void {
    if (this.state.username && this.state.password) {
      this.setState({ loginEnabled: true });
    } else {
      this.setState({ loginEnabled: false });
    }
  }

  private logIn(): void {
    if (this.state) {
      this.props.appStore.loggingIn = true;
      AuthenticationService.login(this.state.username, this.state.password).then((user: IUser) => {
        if (user) {
          ReactGA.event({
            category: 'User',
            action: `User logged in`
          });
          this.props.appStore.setUser(user);
        } else {
          this.setState({ loginError: true });
          setTimeout(() => {
            this.setState({ loginError: false });
          }, 1000);
        }
        this.props.appStore.loggingIn = false;
      });
    }
  }

  render() {
    return (
      <div className="landingPage">
        {this.props.appStore.isMobile ?
          <>
            <div className='landingPageLogo' />
            <div
              className="landingPageButtons"
              style={{
                bottom: this.state.showLoginButtons ? (this.props.appStore.isMobile && window.webkit == undefined ? 100 : 30) : -200,
                opacity: this.state.showLoginButtons ? 1 : 0,
              }}
            >
              <div
                className="landingPageBotton"
                onClick={() => this.props.appStore.showingSignUp = true}
              >
                {window.loc.strings.createAUser}
              </div>
              <div
                className="landingPageBotton"
                style={{
                  color: "#ffffff",
                  borderColor: "#ffffff",
                  backgroundColor: "transparent"
                }}
                onClick={() => this.props.appStore.showingLogin = true}
              >
                {window.loc.strings.iHaveAUser}
              </div>
            </div>
            {this.props.appStore.showingLogin &&
              <div
                className="mobileFrontpageLoginBoxContainer"
              >
                <div className="mobileFrontpageLoginBox">
                  <div
                    className="mobileFrontpageLoginBoxCloseButton"
                    onClick={() => this.props.appStore.showingLogin = false}
                  />
                  <Login
                    buttonColor="#122c43"
                  />
                </div>
              </div>
            }
          </>
          :
          <>
            <div className='frontpageBoxContainer'>
              <div className="frontpageBox">
                <div>
                  <h1>{window.loc.strings.loginHeadline}</h1>
                  <div className="frontpageText" dangerouslySetInnerHTML={{ __html: window.loc.strings.loginDescription }} />
                  <div
                    style={{
                      transform: "translate3d(0, 0, 0)",
                      backfaceVisibility: "hidden",
                      perspective: "1000px",
                      marginTop: 30
                    }}
                  >
                    <div className="frontpageTagline">{window.loc.strings.joinTheTribe}</div>
                    <div
                      className="login"
                      style={{
                        animation: this.state.loginError ? "shake 0.82s cubic-bezier(.36,.07,.19,.97) both" : "none",
                        transform: "translate3d(0, 0, 0)",
                        backfaceVisibility: "hidden",
                        perspective: "1000px",
                      }}
                    >
                      <input
                        type="email"
                        placeholder={window.loc.strings.email}
                        onPaste={() => {
                          setTimeout(() => {
                            this.validate();
                          }, 100);
                        }}
                        onChange={(event: any) => {
                          this.setState({ username: event.target.value.toLowerCase() });
                          this.validate();
                        }}
                        autoFocus
                      />
                      <input
                        type={this.state.showPassword ? "text" : "password"}
                        placeholder={window.loc.strings.password}
                        onPaste={() => {
                          setTimeout(() => {
                            this.validate();
                          }, 100);
                        }}
                        onChange={(event: any) => {
                          this.setState({ password: event.target.value });
                          this.validate();
                        }}
                      />
                      <div
                        className='showPasswordButton'
                        style={{
                          backgroundImage: this.state.showPassword ? 'url("https://img.icons8.com/fluency-systems-regular/48/invisible.png")' : 'url("https://img.icons8.com/fluency-systems-regular/48/000000/visible--v1.png")'
                        }}
                        onClick={() => this.setState({ showPassword: !this.state.showPassword })}
                      />
                      {this.props.appStore.loggingIn ?
                        <div /> // Show spinner
                        :
                        <div
                          className="loginBtn"
                          style={{
                            opacity: this.state.loginEnabled ? 1 : 0.5,
                            cursor: this.state.loginEnabled ? "pointer" : "default",
                          }}
                          onClick={() => {
                            if (this.state.loginEnabled) {
                              this.logIn();
                            }
                          }}
                        >
                          {window.loc.strings.logIn?.toUpperCase()}
                        </div>
                      }
                      <div
                        className="forgottonPasswordBtn"
                        style={{
                          color: "#ffffff"
                        }}
                        onClick={() => {
                          this.props.appStore.showForgottenPassword();
                        }}
                      >
                        {window.loc.strings.forgottenPassword}
                      </div>
                      <div
                        className="landingPageBotton"
                        onClick={() => this.props.appStore.showingSignUp = true}
                      >
                        {window.loc.strings.createAUser}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='landingPageLogoContainer'>
              <div className='landingPageLogo' />
            </div>
          </>
        }
        {this.props.appStore.showingSignUp &&
          <div
            className="mobileFrontpageLoginBoxContainer"
          >
            <div className="mobileFrontpageLoginBox">
              <div
                className="mobileFrontpageLoginBoxCloseButton"
                onClick={() => this.props.appStore.showingSignUp = false}
              />
              <SignUp
                buttonColorLight
                buttonColor="#122c43"
              />
            </div>
          </div>
        }
        {this.props.appStore.showingForgottenPassword &&
          <ForgottenPassword
            buttonColor="#122c43"
          />
        }
        {this.props.appStore.showingForgottenPasswordConfirmation &&
          <ForgottenPasswordConfirmation />
        }
        {(this.props.appStore.loggingIn || this.props.appStore.loading) &&
          <div className="loading">
            <div className="loader"></div>
          </div>
        }
      </div>
    );
  }
}