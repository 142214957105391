import * as React from 'react';
import './EnneagrammyResultTypeText.css';
import { AppStore } from '../../../stores';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';

export interface IProps {
  appStore?: AppStore;
}

@inject('appStore')
@observer
export default class EnneagrammyResultTypeText extends React.Component<IProps> {

  render() {
    return (
      <div className="enneagrammyResultTypeText">
        <div className="enneagrammyResultTypeTextContainer">
          <div className="enneagrammyResultTextContainer">

            <div
              className="enneagrammyResultIllustration"
              style={{
                backgroundColor: this.props.appStore.typeTestColor
              }}
            >
              ?
            </div>
            <div className="enneagrammyResultTagline">DIT RESULTAT ER KLART!</div>
            <h1>{window.loc.strings.resultTextHeadline}</h1>
            <div dangerouslySetInnerHTML={{ __html: window.loc.strings.resultText }} />
          </div>
          {this.props.appStore.isMobile ?
            <Link
              to={`${this.props.appStore.environment}/enneagrammy/Results`}
              key={"Result"}
            >
              <div
                className="enneagrammyStartBtn"
                onClick={() => {
                  this.props.appStore.testProgression = undefined;
                  this.props.appStore.selectedTypeTestTab = 3;
                  this.props.appStore.isTesting = false;

                  this.props.appStore.selectedTestResult = this.props.appStore.testResults[0];
                  var resultToDisplay;
                  if (this.props.appStore.selectedTestResult.splitTestResult != null) {
                    resultToDisplay = this.props.appStore.selectedTestResult.splitTestResult;
                  } else {
                    resultToDisplay = this.props.appStore.selectedTestResult.pointsForTypes[0].id;
                  }
                  this.props.appStore.selectedType = resultToDisplay;
                  this.props.appStore.selectedTypeInfoTab = "result";
                }}
                style={{
                  backgroundColor: this.props.appStore.typeTestColor,
                  marginTop: 10,
                  marginBottom: 10
                }}
              >
                {window.loc.strings.resultTextBtn}
              </div>
            </Link>
            :
            <div
              className="enneagrammyStartBtn"
              onClick={() => {
                this.props.appStore.testProgression = undefined;
                this.props.appStore.isTesting = false;
                this.props.appStore.showEnneagrammyTest = false;
                this.props.appStore.selectedTestResult = this.props.appStore.testResults[0];
                this.props.appStore.myResult = this.props.appStore.testResults[0];
                this.props.appStore.selectedTypeInfoTab = "result";
                this.props.appStore.showEnneagrammyTestResults = true;
              }}
              style={{
                backgroundColor: "#ffffff",
                color: "#272727",
                marginTop: 10,
                marginBottom: 10,
                width: 200,
                padding: "5px 0px",
                marginLeft: 225
              }}
            >
              {window.loc.strings.resultTextBtn}
            </div>
          }
        </div>
      </div>
    );
  }

}