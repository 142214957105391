import * as React from 'react';
import './Pivot.css';
import { Icon } from '../icon';

export interface IIAPivotProps {
  items: { key: string, text: string, disabled?: boolean, icon?: string, error?: boolean }[];
  highlightColor: string;
  textColor?: string;
  selectedKey: string;
  style?: React.CSSProperties;
  select: (key: string) => void;
  asTabs?: boolean;
  tabBackgrondColor?: string;
  selectedTabBackgrondColor?: string;
  showNumber?: boolean;
  showIcon?: boolean;
  isMobile?: boolean;
  autoTabWidth?: boolean;
  height?: number;
}

export class IAPivot extends React.Component<IIAPivotProps, {}> {

  constructor(props: IIAPivotProps) {
    super(props);
  }

  public render(): JSX.Element {
    let items = [];
    this.props.items.forEach((pivotItem: { key: string, text: string, disabled?: boolean, icon?: string, error?: boolean }, index) => {
      if (this.props.asTabs) {
        items.push(
          <div
            tabIndex={0}
            key={pivotItem.key}
            className={["IA_tabItem", "IA_OnFocus"].join(" ")}
            onKeyPress={(event: React.KeyboardEvent<HTMLDivElement>) => {
              if (event.key === "Enter") {
                this.props.select(pivotItem.key);
              }
            }}
            onClick={() => {
              if (!pivotItem.disabled) {
                this.props.select(pivotItem.key);
              }
            }}
            style={{
              width: this.props.autoTabWidth ? "auto" : `${100 / this.props.items.length}%`,
              backgroundColor: this.props.selectedKey === pivotItem.key ? this.props.selectedTabBackgrondColor : this.props.tabBackgrondColor,
              color: pivotItem.disabled ? "#999999" : "#ffffff",
              borderColor: this.props.selectedTabBackgrondColor,
              borderRight: this.props.autoTabWidth ? "none" : "1px solid #ffffff",
              height: this.props.height ? this.props.height : 30,
              lineHeight: this.props.height ? `${this.props.height}px` : "30px",
              textAlign: "center",
              borderRadius: index === 0 ? "10px 0 0 10px" : (index === this.props.items?.length - 1 ? "0 10px 10px 0" : "none")
            }}
          >
            {pivotItem.text}
          </div>
        );
      } else {
        items.push(
          <div
            tabIndex={0}
            key={pivotItem.key}
            className={["IA_pivotItem", "IA_OnFocus"].join(" ")}
            onKeyPress={(event: React.KeyboardEvent<HTMLDivElement>) => {
              if (event.key === "Enter") {
                this.props.select(pivotItem.key);
              }
            }}
            onClick={() => {
              if (!pivotItem.disabled) {
                this.props.select(pivotItem.key);
              }
            }}
            style={{
              borderBottom: this.props.selectedKey === pivotItem.key ? `2px solid ${this.props.highlightColor}` : "none",
              opacity: pivotItem.disabled ? 0.5 : 1
            }}
          >
            {pivotItem.text}
          </div>
        );
      }
    });
    return (
      <div
        className={"IA_pivot"}
        style={this.props.style}
      >
        {items}
      </div>
    );
  }
}