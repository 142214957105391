import * as React from 'react';
import './Card.css';
import { AppStore } from '../../stores';
import { inject, observer } from 'mobx-react';
import ProductService from '../../services/ProductService';
import { Link } from 'react-router-dom';
import { IProduct } from '../../interfaces/IProduct';
import { IProductAccess } from '../../interfaces/IProductAccess';
import IconService from '../../services/IconService';

export interface IProps {
  appStore?: AppStore;
  id?: string;
  isPublic?: boolean;
  image: string;
  width: number;
  marginRight: number;
  title: string;
  type?: string;
  private?: boolean;
  embedLink?: string;
  area?: "tests" | "programs" | "home" | "meditations";
  hasAccess?: boolean;
  teaser?: string;
  openReadMoreLinkEmbedded?: boolean;
  duration?: string;
  index?: number;
  reload?: () => void;
  open?: () => void;
  showActiveUsers?: boolean;
  product?: IProduct;
  coverColor?: string;
  buttons?: any[];
}

export interface IState {
  titleHeight: number;
  hasAccess: boolean;
  isCheckingAccess: boolean;
  activeUsers?: number;
}

@inject('appStore')
@observer
export default class Card extends React.Component<IProps, IState> {

  constructor(props, context) {
    super(props, context);
    this.state = {
      titleHeight: 0,
      hasAccess: this.props.hasAccess,
      isCheckingAccess: this.props.hasAccess ? false : true,
    }
  }

  render() {
    const coverHeight = this.props.width / 6 * 4;
    const cardHeight = coverHeight + 235;
    const buttons = [];
    this.props.buttons?.forEach((button: any) => {
      buttons.push(
        <div
          className='cardButton'
          style={{ ...button.style }}
          onClick={() => button.onClick()}
        >
          {button.title}
        </div>
      );
    });
    const card = (
      <div
        className="card"
        style={{
          height: this.props.appStore.isMobile ? "auto" : cardHeight,
          width: this.props.width,
          marginRight: this.props.marginRight,
        }}
        onClick={() => {
          if (this.props.embedLink) {
            this.props.appStore.loadingEmbeddedPage = true;
            setTimeout(() => {
              this.props.appStore.loadingEmbeddedPage = false;
            }, 2000);
            this.props.appStore.pageEmbed = this.props.embedLink;
          } else {
            if (this.props.open) {
              this.props.open();
            }
          }
        }}
      >
        <div
          className="cardImage"
          style={{
            backgroundImage: `url(${this.props.image}`,
            backgroundColor: this.props.coverColor ?? "#212121",
            height: coverHeight
          }}
        >
          {this.props.private &&
            <div className='cardLock' />
          }
          {this.props.duration &&
            <>
              <div className="durationIconBox">
                <div className="durationIcon" />
              </div>
              <div className="durationLabelBox">
                {this.props.duration}
              </div>
            </>
          }
        </div>
        <div className="cardType">
          {`${this.props.type}`}
        </div>
        <div
          className="cardTitle"
          id={`cardTitle_${this.props.title}`}
        >
          {this.props.title}
        </div>
        <div
          className="cardTeaser"
          style={{
            lineClamp: this.state.titleHeight > 30 ? 3 : 4,
            WebkitLineClamp: this.state.titleHeight > 30 ? 3 : 4,
          }}
        >
          {this.props.teaser}
        </div>
        {this.props.product?.numberOfusers &&
          <div
            className="cardUsers"
          >
            <div className='cardUsersIcon'>
              {IconService.getIcon("People Team", "#aeaeae")}
            </div>
            <div className='cardUsersText'>
              {this.props.product.numberOfusers} {this.props.product.productType === "Event" ? window.loc.strings.hasSignedUpForThisEvent : window.loc.strings.completedThisEducation}
            </div>
          </div>
        }
        {buttons?.length > 0 &&
          <div className='cardButtons'>
            {buttons}
          </div>
        }
      </div>
    );
    let cardToShow: JSX.Element = (
      <Link
        to={`${this.props.appStore.environment}/${this.props.area}/${this.props.id}`}
        key={this.props.id}
      >
        {card}
      </Link>
    );
    if (this.props.appStore.isMobile && this.props.area === "tests") {
      cardToShow = (
        <a
          href="https://apps.apple.com/us/app/enneagrammy/id1033881087"
          target="_blank"
        >
          {card}
        </a>
      );
    }
    if (this.props.area === "meditations") {
      cardToShow = card;
    }
    if (this.props.appStore.editMode && this.props.product?.productType === "Event") {
      cardToShow = card;
    }
    return (
      cardToShow
    );
  }
}