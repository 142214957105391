import * as React from 'react';
import './EnneagrammyResults.css';
import { AppStore } from '../../../../stores';
import { inject, observer } from 'mobx-react';
import TypeTestProfile from '../../../../enneagrammyApp/profile/TypeTestProfile';
import EnneagrammyTypeInfoPopup from '../../../../enneagrammyApp/typeInfoPopup/EnneagrammyTypeInfoPopup';
import { Pivot } from '../../../pivot/Pivot';
import { IType } from '../../../../interfaces/IType';
import TypeTestChangeName from '../../../../enneagrammyApp/changeName/EnneagrammyChangeName';
import TypeTestResults from './results/TypeTestResults';

export interface IProps {
  show: boolean;
  appStore?: AppStore;
}

export interface IState {
  showEnneagrammyTestResultsBackground: boolean;
}

@inject('appStore')
@observer
export default class EnneagrammyResults extends React.Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
      showEnneagrammyTestResultsBackground: false,
    }
  }

  componentWillReceiveProps(props: IProps): void {
    if (props.show && !this.props.show) {
      let resultToShow;
      if (this.props.appStore.selectedTestResult) {
        resultToShow = this.props.appStore.selectedTestResult.pointsForTypes.map((type: IType) => {
          return {
            id: type.id,
            points: this.props.appStore.selectedTestResult.splitTestResult && type.id === this.props.appStore.selectedTestResult.splitTestResult ? type.points + 1 : type.points
          }
        });
        resultToShow = this.props.appStore.sortByKey(resultToShow, "points");
        if (resultToShow?.length > 0) {
          this.props.appStore.selectedType = resultToShow[0].id;
        }
      }
      setTimeout(() => {
        this.setState({ showEnneagrammyTestResultsBackground: true });
      }, 0)
    };
  }

  render() {
    let videoLink;
    let typeTest;
    switch (this.props.appStore.selectedType) {
      case 1:
        videoLink = window.loc.strings.type1Video;
        typeTest = window.loc.strings.type1Test;
        break;
      case 2:
        videoLink = window.loc.strings.type2Video;
        typeTest = window.loc.strings.type2Test;
        break;
      case 3:
        videoLink = window.loc.strings.type3Video;
        typeTest = window.loc.strings.type3Test;
        break;
      case 4:
        videoLink = window.loc.strings.type4Video;
        typeTest = window.loc.strings.type4Test;
        break;
      case 5:
        videoLink = window.loc.strings.type5Video;
        typeTest = window.loc.strings.type5Test;
        break;
      case 6:
        videoLink = window.loc.strings.type6Video;
        typeTest = window.loc.strings.type6Test;
        break;
      case 7:
        videoLink = window.loc.strings.type7Video;
        typeTest = window.loc.strings.type7Test;
        break;
      case 8:
        videoLink = window.loc.strings.type8Video;
        typeTest = window.loc.strings.type8Test;
        break;
      case 9:
        videoLink = window.loc.strings.type9Video;
        typeTest = window.loc.strings.type9Test;
        break;
      default:
        break;
    }
    return (
      <>
        {this.props.appStore.showEnneagrammyTestResults &&
          <div
            className='enneagrammyResultsOverlay'
            style={{
              opacity: this.state.showEnneagrammyTestResultsBackground ? 0.6 : 0
            }}
          />
        }
        <div
          className='enneagrammyResults'
          style={{
            top: this.props.appStore.isMobile ? (this.props.appStore.showEnneagrammyTestResults ? 0 : screen.height) : 50,
            right: this.props.appStore.isMobile ? "unset" : (this.props.appStore.showEnneagrammyTestResults ? 0 : -580),
            height: this.props.appStore.isMobile ? "100%" : "calc(100% - 50px)",
            overflow: "auto"
          }}
        >
          <Pivot
            highlightColor="#ffffff"
            textColor="#919191"
            items={[
              {
                key: "result",
                text: window.loc.strings.result,
              },
              {
                key: "type",
                text: window.loc.strings.typeInfo,
              },
              {
                key: "allResults",
                text: window.loc.strings.allResults2,
              }
            ]}
            style={{
              fontSize: "14px",
              textTransform: "uppercase",
            }}
            selectedKey={this.props.appStore.selectedTypeInfoTab}
            select={(selectedPivot: string) => {
              this.props.appStore.selectedTypeInfoTab = selectedPivot;
            }}
          />
          {this.props.appStore.selectedTypeInfoTab === "result" &&
            <TypeTestProfile />
          }
          {this.props.appStore.selectedTypeInfoTab === "type" &&
            <div>
              <div
                className="enneagrammyInfoPopupIllustration"
                style={{
                  backgroundImage: this.props.appStore.isMobile ? `url("https://dojo.mindjuice.com/images/typetest-enneagram-grid-${this.props.appStore.selectedType}.png")` : `url("https://dojo.mindjuice.com/images/typetest-enneagram-grid-${this.props.appStore.selectedType}-white.png")`
                }}
              >
                <div
                  className="enneagrammyType1"
                  style={{
                    backgroundColor: "#064859",
                    transform: this.props.appStore.selectedType === 1 ? "scale(1.3)" : "scale(1.0)"
                  }}
                  onClick={() => this.props.appStore.selectedType = 1}
                >
                  1
                </div>
                <div
                  className="enneagrammyType2"
                  style={{
                    backgroundColor: "#064859",
                    transform: this.props.appStore.selectedType === 2 ? "scale(1.3)" : "scale(1.0)"
                  }}
                  onClick={() => this.props.appStore.selectedType = 2}
                >
                  2
                </div>
                <div
                  className="enneagrammyType3"
                  style={{
                    backgroundColor: "#064859",
                    transform: this.props.appStore.selectedType === 3 ? "scale(1.3)" : "scale(1.0)"
                  }}
                  onClick={() => this.props.appStore.selectedType = 3}
                >
                  3
                </div>
                <div
                  className="enneagrammyType4"
                  style={{
                    backgroundColor: "#064859",
                    transform: this.props.appStore.selectedType === 4 ? "scale(1.3)" : "scale(1.0)"
                  }}
                  onClick={() => this.props.appStore.selectedType = 4}
                >
                  4
                </div>
                <div
                  className="enneagrammyType5"
                  style={{
                    backgroundColor: "#064859",
                    transform: this.props.appStore.selectedType === 5 ? "scale(1.3)" : "scale(1.0)"
                  }}
                  onClick={() => this.props.appStore.selectedType = 5}
                >
                  5
                </div>
                <div
                  className="enneagrammyType6"
                  style={{
                    backgroundColor: "#064859",
                    transform: this.props.appStore.selectedType === 6 ? "scale(1.3)" : "scale(1.0)"
                  }}
                  onClick={() => this.props.appStore.selectedType = 6}
                >
                  6
                </div>
                <div
                  className="enneagrammyType7"
                  style={{
                    backgroundColor: "#064859",
                    transform: this.props.appStore.selectedType === 7 ? "scale(1.3)" : "scale(1.0)"
                  }}
                  onClick={() => this.props.appStore.selectedType = 7}
                >
                  7
                </div>
                <div
                  className="enneagrammyType8"
                  style={{
                    backgroundColor: "#064859",
                    transform: this.props.appStore.selectedType === 8 ? "scale(1.3)" : "scale(1.0)"
                  }}
                  onClick={() => this.props.appStore.selectedType = 8}
                >
                  8
                </div>
                <div
                  className="enneagrammyType9"
                  style={{
                    backgroundColor: "#064859",
                    transform: this.props.appStore.selectedType === 9 ? "scale(1.3)" : "scale(1.0)"
                  }}
                  onClick={() => this.props.appStore.selectedType = 9}
                >
                  9
                </div>
              </div>
              <div className="enneagrammyInfoPopupIllustrationInfo">&#9432; Klik på illustrationen for at læse om en anden type</div>
              <div className="enneagrammyVideo">
                <iframe
                  src={`https://player.vimeo.com/video/${videoLink}`}
                  width={500}
                  height={500 / 16 * 9}
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  allowFullScreen
                  style={{
                    backgroundColor: "#064859"
                  }}
                />
              </div>
              <div className="enneagrammyInfo" dangerouslySetInnerHTML={{ __html: typeTest }} />
            </div>
          }
          {this.props.appStore.selectedTypeInfoTab === "allResults" &&
            <TypeTestResults />
          }
          <div
            className='closeButton'
            onClick={() => {
              this.props.appStore.typeTestChangeName = false;
              history.back();
            }}
          />
        </div>

        {this.props.appStore.typeTestChangeName && !this.props.appStore.isMobile &&
          <div
            className="enneagrammyChangeNameBoxContainer"
          >
            <div className="enneagrammyChangeNameBox">
              <TypeTestChangeName />
            </div>
          </div>
        }
      </>
    );
  }

}