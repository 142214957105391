import * as React from 'react';
import './EnneagrammyTabBar.css';
import { AppStore } from '../../stores';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';

export interface IProps {
  appStore?: AppStore;
}

export interface IState {
}

@inject('appStore')
@observer
export default class EnneagrammyTabBar extends React.Component<IProps, IState> {

  constructor(props,) {
    super(props);
    this.state = {
    }
  }

  render() {
    console.log("selectedTypeTestTab: ");
    console.log(this.props.appStore.selectedTypeTestTab);
    return (
      <div className="enneagrammyTabBar">
        <Link
          to={`${this.props.appStore.environment}/enneagrammy/`}
          key={"Frontpage"}
        >
          <div
            className="enneagrammyTabBarItem"
            style={{
              opacity: this.props.appStore.selectedTypeTestTab === 1 ? 1 : 0.5
            }}
            onClick={() => {
              this.props.appStore.showExtendedMenu = false;
              this.props.appStore.selectedTypeTestTab = 1;
              this.render();
            }}
          >
            <div
              className="enneagrammyTabBarItemIcon"
              style={{
                backgroundImage: `url(https://dojo.mindjuice.com/images/test-icon.png)`
              }}
            />
            <div className="enneagrammyTabBarItemText">
              {window.loc.strings.tabBarTest}
            </div>
          </div>
        </Link>

        <Link
          to={`${this.props.appStore.environment}/enneagrammy/Profile`}
          key={"Profile"}
        >
          <div
            className="enneagrammyTabBarItem"
            style={{
              opacity: this.props.appStore.selectedTypeTestTab === 2 ? 1 : 0.5
            }}
            onClick={() => {
              this.props.appStore.showExtendedMenu = false;
              this.props.appStore.selectedTypeTestTab = 2;
              this.render();
            }}
          >
            <div
              className="enneagrammyTabBarItemIcon"
              style={{
                backgroundImage: `url(https://dojo.mindjuice.com/images/user.png)`,
                filter: "invert(100%)"
              }}
            />
            <div className="enneagrammyTabBarItemText">
              {window.loc.strings.tabBarProfile}
            </div>
          </div>
        </Link>

        <Link
          to={`${this.props.appStore.environment}/enneagrammy/Results`}
          key={"Result"}
        >
          <div
            className="enneagrammyTabBarItem"
            style={{
              opacity: this.props.appStore.selectedTypeTestTab === 3 ? 1 : 0.5
            }}
            onClick={() => {
              this.props.appStore.showExtendedMenu = false;
              this.props.appStore.selectedTypeTestTab = 3;
              this.render();
            }}
          >
            <div
              className="enneagrammyTabBarItemIcon"
              style={{
                backgroundImage: `url(https://dojo.mindjuice.com/images/result-icon.png)`
              }}
            />
            <div className="enneagrammyTabBarItemText">
              {window.loc.strings.tabBarResults}
            </div>
          </div>
        </Link>

        <Link
          to={`${this.props.appStore.environment}/enneagrammy/TypeTestAbout`}
          key={"TypeTestAbout"}
        >
          <div
            className="enneagrammyTabBarItem"
            style={{
              opacity: this.props.appStore.selectedTypeTestTab === 4 ? 1 : 0.5
            }}
            onClick={() => {
              this.props.appStore.showExtendedMenu = false;
              this.props.appStore.selectedTypeTestTab = 4;
              this.render();
            }}
          >
            <div
              className="enneagrammyTabBarItemIcon"
              style={{
                backgroundImage: `url(https://dojo.mindjuice.com/images/info-icon.png)`
              }}
            />
            <div className="enneagrammyTabBarItemText">
              {window.loc.strings.tabBarAbout}
            </div>
          </div>
        </Link>
        <div
          className="enneagrammyTabBarItem"
          style={{
            opacity: this.props.appStore.selectedTypeTestTab === 5 ? 1 : 0.5
          }}
          onClick={() => {
            this.props.appStore.showExtendedMenu = !this.props.appStore.showExtendedMenu;
            this.props.appStore.selectedTypeTestTab = 5;
            this.render();
          }}
        >
          <div
            className="enneagrammyTabBarItemIcon"
            style={{
              backgroundImage: `url(https://dojo.mindjuice.com/images/more-icon.png)`
            }}
          />
          <div className="enneagrammyTabBarItemText">
            {window.loc.strings.tabBarMore}
          </div>
        </div>
      </div>
    );
  }

}