import * as React from 'react';
import './EnneagrammyChangeName.css';
import { AppStore } from '../../stores';
import { inject, observer } from 'mobx-react';
import AuthenticationService from '../../services/AuthenticationService';
import { IUser } from '../../interfaces/IUser';
import * as ReactGA from 'react-ga';
import TestService from '../../services/TestService';
import { ITestResult } from '../../interfaces/ITestResult';

export interface IProps {
  appStore?: AppStore;
  buttonColor?: string;
}

export interface IState {
  username: string;
  password: string;
  loginEnabled: boolean;
  loginError: boolean;
}

@inject('appStore')
@observer
export default class EnneagrammyChangeName extends React.Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
      username: undefined,
      password: undefined,
      loginEnabled: false,
      loginError: false
    }
    document.addEventListener("keyup", ((event) => {
      if (event.keyCode === 13) {
        event.preventDefault();
        if (this.props.appStore.showingLogin) {
          this.logIn();
        }
      }
    }));
  }

  private logIn(): void {
    if (this.state) {
      this.props.appStore.loggingIn = true;
      AuthenticationService.login(this.state.username, this.state.password).then((user: IUser) => {
        if (user) {
          ReactGA.event({
            category: 'User',
            action: `User logged in`
          });
          this.props.appStore.setUser(user);
        } else {
          this.setState({ loginError: true });
          setTimeout(() => {
            this.setState({ loginError: false });
          }, 1000);
        }
        this.props.appStore.loggingIn = false;
      });
    }
  }

  render() {
    return (
      <div className="enneagrammyChangeName">
        <input
          type="text"
          value={this.props.appStore.selectedTestResult && this.props.appStore.selectedTestResult.name ? this.props.appStore.selectedTestResult.name : undefined}
          onChange={(event: any) => {
            this.props.appStore.selectedTestResult.name = event.target.value;
          }}
          placeholder={window.loc.strings.nameTestHeadline}
          autoFocus
        />
        <div
          className="enneagrammyChangeNameBtn"
          style={{
            backgroundColor: this.props.appStore.isMobile ? this.props.appStore.typeTestColor : "#064859",
            color: this.props.appStore.isMobile ? "#272727" : "#ffffff"
          }}
          onClick={() => {
            TestService.update(this.props.appStore.user.id, this.props.appStore.selectedTestResult, this.props.appStore.selectedTestResult.name).then((testResults: ITestResult[]) => {
              this.props.appStore.typeTestChangeName = false;
            });
          }}
        >
          {window.loc.strings.update}
        </div>
        <div
          className="enneagrammyChangeNameBtn"
          style={{
            backgroundColor: "lightgray"
          }}
          onClick={() => this.props.appStore.typeTestChangeName = false}
        >
          {window.loc.strings.cancel}
        </div>
      </div >
    );
  }

}