import * as React from 'react';
import './AdminDeleteAccountPopup.css';
import { AppStore } from '../../stores';
import { inject, observer } from 'mobx-react';
import AuthenticationService from '../../services/AuthenticationService';
import { IDeletedUser } from '../../interfaces/IUser';
import AdminService from '../../services/AdminService';

export interface IProps {
  appStore?: AppStore;
  onReload: () => void;
}

export interface IState {
}

@inject('appStore')
@observer
export default class AdminDeleteAccountPopup extends React.Component<IProps, IState> {

  constructor(props, context) {
    super(props, context);
    this.state = {
    }
  }

  render() {
    return (
      <div className="deleteAccountPopup">
        <div
          className="deleteAccountPopupBackground"
          onClick={() => {
            this.props.appStore.showingAdminDeleteAccountForUser = undefined;
          }}
        />
        <div className="deleteAccountPopupBox">
          <h3>{`Er du sikker på at du vil slette ${this.props.appStore.showingAdminDeleteAccountForUser.name}?`}</h3>
          <div
            className="deleteBtn"
            onClick={() => {
              const userToDelete: IDeletedUser = {
                username: this.props.appStore.showingAdminDeleteAccountForUser?.username,
                name: this.props.appStore.showingAdminDeleteAccountForUser?.name,
                signUpDate: this.props.appStore.showingAdminDeleteAccountForUser?.createdAt
              }
              AuthenticationService.addDeletedUser(userToDelete).then(() => {
                AdminService.deleteUser(this.props.appStore.showingAdminDeleteAccountForUser, this.props.appStore.user.masterKey).then(() => {
                  this.props.appStore.showingAdminDeleteAccountForUser = undefined;
                  this.props.onReload();
                });
              })
            }}
          >
            {window.loc.strings.delete}
          </div>
          <div
            className="cancelBtn"
            onClick={() => {
              this.props.appStore.showingAdminDeleteAccountForUser = undefined;
            }}
          >
            {window.loc.strings.cancel}
          </div>
        </div>
      </div>
    );
  }

}