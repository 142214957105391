import * as React from 'react';
import './mindPaperEditPanel.css';
import { AppStore } from '../../../../stores';
import { inject, observer } from 'mobx-react';
import ProductService from '../../../../services/ProductService';

export interface IProps {
  appStore?: AppStore;
  reloadMindPapers: (selectedId: string) => void;
}

export interface IState {

}

@inject('appStore')
@observer
export default class MindPaperEditPanel extends React.Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
      availablemindPapers: []
    }
  }

  render() {
    return (
      <div>
        <div
          className="mindPaperEditPanelWrapper"
          style={{
            visibility: this.props.appStore.mindPaperToEdit != undefined ? "visible" : "hidden"
          }}
          onClick={() => {
            this.props.appStore.mindPaperToEdit = undefined;
          }}
        />
        <div
          className="mindPaperEditPanel"
          style={{
            right: this.props.appStore.mindPaperToEdit != undefined ? 0 : -620,
            color: "#272727"
          }}
        >
          {this.props.appStore.mindPaperToEdit != undefined &&
            <div>
              <h3>MindPaper</h3>
              <div
                className="inputFieldLabel"
                style={{
                  clear: "both"
                }}
              >
                Titel
              </div>
              <input
                type="text"
                value={this.props.appStore.mindPaperToEdit.title}
                placeholder={"Titel"}
                onChange={(event: any) => {
                  this.props.appStore.mindPaperToEdit.title = event.target.value;
                }}
              />
              <div
                className="inputFieldLabel"
                style={{
                  clear: "both"
                }}
              >
                Antal sider
              </div>
              <input
                type="number"
                value={this.props.appStore.mindPaperToEdit.pages}
                placeholder={"Antal sider"}
                onChange={(event: any) => {
                  this.props.appStore.mindPaperToEdit.pages = event.target.value;
                }}
              />
              <div
                className="inputFieldLabel"
                style={{
                  clear: "both"
                }}
              >
                Sti til indhold
              </div>
              <input
                type="text"
                value={this.props.appStore.mindPaperToEdit.url}
                placeholder={"URL"}
                onChange={(event: any) => {
                  let path = event.target.value;
                  if (path.includes("/dojo.mindjuice.com/materialer/")) {
                    let splittedPath = path.split("/dojo.mindjuice.com/materialer/");
                    if (splittedPath.length > 1) {
                      let splittedUrl = splittedPath[1].split("/");
                      path = "https://dojo.mindjuice.com/materialer";
                      for (let i = 0; i < splittedUrl.length - 1; i++) {
                        path += "/" + splittedUrl[i];
                      }
                      let fileName = splittedUrl[splittedUrl.length - 1];
                      if (fileName.includes("_Side_")) {
                        const splittedFileName = fileName.split("_Side_");
                        if (splittedFileName.length > 1) {
                          fileName = splittedFileName[0];
                        }
                      }

                      this.props.appStore.mindPaperToEdit.fileName = fileName.replace(/%20/g, " ");
                    }
                  }
                  this.props.appStore.mindPaperToEdit.url = path.replace(/%20/g, " ");
                }}
              />
              <div
                className="inputFieldLabel"
                style={{
                  clear: "both"
                }}
              >
                Filnavn
              </div>
              <input
                type="text"
                value={this.props.appStore.mindPaperToEdit.fileName}
                placeholder={"URL"}
                onChange={(event: any) => {
                  this.props.appStore.mindPaperToEdit.fileName = event.target.value;
                }}
              />
              <div className="flowpaper">
                <input
                  type="checkbox"
                  checked={this.props.appStore.mindPaperToEdit.printEnabled}
                  onChange={() => {
                    this.props.appStore.mindPaperToEdit.printEnabled = this.props.appStore.mindPaperToEdit.printEnabled != undefined ? !this.props.appStore.mindPaperToEdit.printEnabled : true
                  }}
                  style={{
                    width: 20,
                    marginTop: -2
                  }}
                />
                Vis udskriv knap?
              </div>
              <button
                onClick={() => {
                  if (this.props.appStore.mindPaperToEdit.objectId) {
                    ProductService.updateMindPaper(this.props.appStore.mindPaperToEdit).then((objectId: string) => {
                      console.log(objectId);
                      this.props.reloadMindPapers(objectId);
                    });
                  } else {
                    ProductService.createMindPaper(this.props.appStore.mindPaperToEdit).then((objectId: string) => {
                      console.log(objectId);
                      this.props.reloadMindPapers(objectId);
                    });
                  }
                }}
                style={{
                  clear: "both"
                }}
              >
                Gem
              </button>
              {/* <button
                onClick={() => {
                }}
                style={{
                  backgroundColor: "gray",
                  marginLeft: 10
                }}
              >
                SlAnnnet
              </button> */}
            </div>
          }
        </div>
      </div>
    );
  }
}