import * as React from 'react';
import './SharePopup.css';
import { AppStore } from '../../stores';
import { inject, observer } from 'mobx-react';
import { InlineShareButtons } from 'sharethis-reactjs';

export interface IProps {
  appStore?: AppStore;
}

export interface IState {
}

@inject('appStore')
@observer
export default class SharePopup extends React.Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    return (
      <div className="sharePopup">
        <div
          className="sharePopupBackground"
          onClick={() => {
            this.props.appStore.shareContent = undefined;
          }}
        />
        <div className="sharePopupBox">
          <div
            className='closeButton'
            onClick={() => {
              this.props.appStore.shareContent = undefined;
            }}
          />
          <h3>{window.loc.strings.share}</h3>
          {this.props.appStore.shareContent?.image &&
            <div
              className="image"
              style={{
                backgroundImage: `url("${this.props.appStore.shareContent?.image}")`
              }}
            />
          }
          {this.props.appStore.shareContent?.title &&
            <div className="title">
              {this.props.appStore.shareContent?.title}
            </div>
          }
          {this.props.appStore.shareContent?.description &&
            <div className="description">
              {this.props.appStore.shareContent?.description}
            </div>
          }
          <div
            style={{
              marginTop: 20,
              float: "left",
              clear: "both"
            }}
          >
            <InlineShareButtons
              config={{
                alignment: 'left',  // alignment of buttons (left, center, right)
                color: 'social',      // set the color of buttons (social, white)
                enabled: true,        // show/hide buttons (true, false)
                font_size: 16,        // font size for the buttons
                labels: null,        // button labels (cta, counts, null)
                language: 'en',       // which language to use (see LANGUAGES)
                networks: [
                  'linkedin',
                  'facebook',
                  'twitter',
                  'email',
                  'sms'
                ],
                padding: 12,          // padding within buttons (INTEGER)
                radius: 4,            // the corner radius on each button (INTEGER)
                show_total: false,
                size: 30,             // the size of each button (INTEGER)
                url: `https://dojo.mindjuice.com/metadata.php?&title=${encodeURIComponent(this.props.appStore.shareContent?.title)}&description=${encodeURIComponent(this.props.appStore.shareContent?.description)}&image=${encodeURIComponent(this.props.appStore.shareContent?.image)}&url=${encodeURIComponent(location.href)}`,
                message: `${this.props.appStore.shareContent?.description} Se video'en her: ${location.href}`,     // (only for email sharing)
                subject: this.props.appStore.shareContent?.title,  // (only for email sharing)
              }}
            />
          </div>
        </div>
      </div>
    );
  }

}