import * as React from 'react';
import './EnneagrammyTest.css';
import { inject, observer } from 'mobx-react';
import { AppStore } from '../../../../stores';
import EnneagrammyFlow from '../../../../enneagrammyApp/flow/EnneagrammyFlow';
import { Link } from 'react-router-dom';

export interface IProps {
  close: () => void;
  appStore?: AppStore;
}

export interface IState {
}

@inject('appStore')
@observer
export default class EnneagrammyTest extends React.Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  public componentDidMount(): void {
  }

  public componentWillReceiveProps(props: IProps): void {
  }

  render() {

    return (
      <div
        className='enneagrammyTest'
        style={{
          top: this.props.appStore.showEnneagrammyTest ? (this.props.appStore.isMobile ? 0 : 50) : screen.height,
          height: this.props.appStore.podcastToPlay ? (this.props.appStore.isMobile ? "calc(100% - 101px)" : "calc(100% - 151px)") : (this.props.appStore.isMobile ? "100%" : "calc(100% - 50px)")
        }}
      >
        <Link to={this.props.appStore.selectedTab === 1 ? `${this.props.appStore.environment}` : `${this.props.appStore.environment}/tests`}>
          <div
            className='closeButton'
            onClick={() => {
              history.back();
              this.props.close();
            }}
          />
        </Link>
        <EnneagrammyFlow />
      </div>
    );
  }
}