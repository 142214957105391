import * as React from 'react';
import './EnneagrammyFlow.css';
import { AppStore } from '../../stores';
import { inject, observer } from 'mobx-react';
import Question from './question/EnneagrammyQuestion';
import ProgressBar from './progressBar/EnneagrammyProgressBar';
import SplitTestQuestion from './testQuestion/EnneagrammySplitTestQuestion';
import NavigationBar from './navigationBar/EnneagrammyNavigationBar';
import ResultText from './resultText/EnneagrammyResultTypeText';
import NameYourTest from './nameYourTest/NameYourTest';

export interface IProps {
  appStore?: AppStore;
}

@inject('appStore')
@observer
export default class EnneagrammyFlow extends React.Component<IProps> {

  constructor(props, context) {
    super(props, context);
    this.props.appStore.loadQuestions();
    this.props.appStore.backPath = `${this.props.appStore.environment}/tests/19067/TestIntro`;
  }

  public componentDidMount(): void {
    window.addEventListener('resize', () => this.forceUpdate());
    this.props.appStore.updateBackground();
  }

  public componentWillUnmount(): void {
    window.removeEventListener('resize', () => this.forceUpdate());
  }

  render() {
    const questions = [];
    let index = 0;
    this.props.appStore.questions.forEach(() => {
      questions.push(
        <Question
          key={`question_${index}`}
          index={index}
        />
      );
      index++;
    });
    let headline;
    switch (this.props.appStore.testProgression) {
      case "Test":
        headline = "";
        break;
      case "SplitTestIntro":
        headline = window.loc.strings.testHeadlineSplitTest;
        break;
      case "SplitTest":
        headline = window.loc.strings.testHeadline;
        break;
      case "Name":
        headline = window.loc.strings.nameTestHeadline;
        break;
      default:
        headline = "";
        break;
    }
    return (
      <div
        className="enneagrammyFlow"
        style={{
          top: this.props.appStore.isUniversityApp || this.props.appStore.isTesting ? 0 : screen.height,
          position: this.props.appStore.isUniversityApp ? "relative" : "fixed",
          backgroundColor: ""
        }}
      >
        {!this.props.appStore.isUniversityApp &&
          <div
            className="enneagrammyFlowCloseButton"
            onClick={() => {
              this.setState({ top: window.innerHeight });
              setTimeout(() => {
                this.props.appStore.isTesting = false;
              }, 0);
            }}
          />
        }
        <div
          className="headline"
          style={{
            marginTop: this.props.appStore.testProgression === "SplitTestIntro" ? window.innerHeight / 4 : (this.props.appStore.splitTest && this.props.appStore.splitTest.length > 4 ? 20 : window.innerHeight / 8),
          }}
        >
          {headline}
        </div>
        <div style={{
          width: screen.width * this.props.appStore.questions.length + 20000,
          left: -window.innerWidth * this.props.appStore.currentQuestionIndex - 1,
          transition: "all 1s",
          position: "absolute",
          top: "calc(50% - 120px)"
        }}>
          {this.props.appStore.testProgression === "Test" &&
            questions
          }
        </div>
        {this.props.appStore.testProgression === "SplitTest" &&
          <SplitTestQuestion />
        }
        {this.props.appStore.testProgression === "Name" &&
          <NameYourTest />
        }
        {this.props.appStore.testProgression === "CalculationResult" &&
          <div className="spinner">
            <div className="spinnerLabel">{window.loc.strings.calculatingResult}</div>
          </div>
        }
        <NavigationBar />
        <ProgressBar />
        {this.props.appStore.testProgression === "ResultIsReady" &&
          <ResultText />
        }
      </div>
    );
  }

}