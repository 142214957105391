import * as React from 'react';
import './Slider.css';
import { AppStore } from '../../stores';
import { inject, observer } from 'mobx-react';
import Slide from './slide/Slide';
import { IProduct } from '../../interfaces/IProduct';
import IconService from '../../services/IconService';
import Swipe from 'react-easy-swipe';

export interface IProps {
  slides: IProduct[];
  wrapperWidth: number;
  appStore?: AppStore;
}

export interface IState {
}

@inject('appStore')
@observer
export default class Slider extends React.Component<IProps, IState> {

  private timer: number;

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  public componentDidMount(): void {
    if (this.props.slides.length > 1) {
      this.changeSlide();
    }
  }

  private changeSlide(): void {
    this.timer = window.setTimeout(() => {
      (this.props.slides.length > 2 ? 210 : 260);
      if (this.props.appStore.slideIndex < this.props.slides.length) {
        this.props.appStore.slideIndex = this.props.appStore.slideIndex + 1;
        this.props.appStore.slideMarginLeft = this.props.appStore.slideMarginLeft - this.amountToMoveForward();
      } else {
        this.props.appStore.slideIndex = 1;
        this.props.appStore.slideMarginLeft = 0;
      }
      this.changeSlide();
    }, 10000);
  }

  private amountToMoveForward(): number {
    let amountToMove;
    if (this.props.appStore.isMobile) {
      amountToMove = this.props.wrapperWidth - 20;
    } else {
      switch (this.props.appStore.slideIndex) {
        case 1:
          amountToMove = this.props.wrapperWidth - 210;
          break;
        default:
          amountToMove = this.props.wrapperWidth - 160;
          break;
      }
      if (this.props.slides.length === this.props.appStore.slideIndex + 1) {
        if (this.props.slides.length === 2) {
          amountToMove = this.props.wrapperWidth - 260;
        } else {
          amountToMove = this.props.wrapperWidth - 210;
        }
      }
    }
    return amountToMove;
  }

  private amountToMoveBack(): number {
    let amountToMove;
    if (this.props.appStore.isMobile) {
      amountToMove = this.props.wrapperWidth - 20;
    } else {
      switch (this.props.appStore.slideIndex) {
        case 2:
          amountToMove = this.props.wrapperWidth - 210;
          break;
        default:
          amountToMove = this.props.wrapperWidth - 160;
          break;
      }
      if (this.props.slides.length === this.props.appStore.slideIndex) {
        if (this.props.slides.length === 2) {
          amountToMove = this.props.wrapperWidth - 260;
        } else {
          amountToMove = this.props.wrapperWidth - 210;
        }
      }
    }
    return amountToMove;
  }

  private onSwipeLeft(): void {
    if (this.props.appStore.slideIndex !== this.props.slides.length) {
      clearTimeout(this.timer);
      this.props.appStore.slideIndex = this.props.appStore.slideIndex + 1;
      this.props.appStore.slideMarginLeft = this.props.appStore.slideMarginLeft - this.amountToMoveForward();
      this.changeSlide();
    }
  }

  private onSwipeRight(): void {
    if (this.props.appStore.slideIndex !== 1) {
      clearTimeout(this.timer);
      this.props.appStore.slideIndex = this.props.appStore.slideIndex - 1;
      this.props.appStore.slideMarginLeft = this.props.appStore.slideMarginLeft + this.amountToMoveBack();
      this.changeSlide()
    }
  }

  render() {
    const slides = [];
    const dots = [];
    let width = this.props.appStore.isMobile ? this.props.wrapperWidth - 40 : this.props.wrapperWidth - 80;
    if (!this.props.appStore.isMobile && this.props.slides.length > 1) {
      width = width - 100;
    }
    this.props.slides.forEach((content: IProduct, index) => {
      slides.push(
        <Slide
          key={content.objectId}
          width={width}
          content={content}
        />
      );
      dots.push(
        <div
          key={"dot_" + index}
          className='dot'
          style={{
            backgroundColor: this.props.appStore.slideIndex - 1 === index ? "#ffffff" : "#777777"
          }}
        />
      );
    });
    return (
      <Swipe
        onSwipeLeft={() => this.onSwipeLeft()}
        onSwipeRight={() => this.onSwipeRight()}
      >
        <div
          className="slider"
          style={{
            width: this.props.wrapperWidth,
            height: this.props.appStore.isMobile ? this.props.wrapperWidth / 1.5 + 20 : this.props.wrapperWidth / 4 + 20
          }}
        >
          <div
            className="sliderWrapper"
            style={{
              width: this.props.wrapperWidth * this.props.slides.length,
              marginLeft: this.props.appStore.slideMarginLeft
            }}
          >
            {slides}
          </div>
          {!this.props.appStore.isMobile && this.props.slides.length > 1 &&
            <div
              className='arrowLeft'
              style={{
                marginTop: this.props.wrapperWidth / 8 - 40,
                opacity: this.props.appStore.slideIndex === 1 ? 0.5 : 1
              }}
              onClick={() => this.onSwipeRight()}
            >
              {IconService.getIcon("Arrow", "#272727")}
            </div>
          }
          {!this.props.appStore.isMobile && this.props.slides.length > 1 &&
            <div
              className='arrowRight'
              style={{
                marginTop: this.props.wrapperWidth / 8 - 40,
                opacity: this.props.appStore.slideIndex === this.props.slides.length ? 0.5 : 1
              }}
              onClick={() => this.onSwipeLeft()}
            >
              {IconService.getIcon("Arrow", "#272727")}
            </div>
          }
          {this.props.slides.length > 1 &&
            <div
              className='dots'
              style={{
                width: this.props.slides.length * 20,
                marginLeft: (this.props.appStore.isMobile ? this.props.wrapperWidth / 2 - 20 : this.props.wrapperWidth / 2 - 40) - (this.props.slides.length * 25 / 2)
              }}
            >
              {dots}
            </div>
          }
        </div>
      </Swipe>
    )
  }
}