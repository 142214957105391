import * as React from 'react';
import './Users.css';
import { AppStore } from '../../stores';
import { inject, observer } from 'mobx-react';
import { IProduct } from '../../interfaces/IProduct';
import Header from '../header/Header';
import LogoBar from '../logoBar/LogoBar';
import AdminService from '../../services/AdminService';
import { IDeletedUser, IUser } from '../../interfaces/IUser';
import moment = require('moment');
import User from './user/User';
import UserProductTeamPanel from './userProductTeamPanel/UserProductTeamPanel';
import CountUp from "react-countup";
import AuthenticationService from '../../services/AuthenticationService';
import Moment from 'react-moment';
import AdminDeleteAccountPopup from '../adminDeleteAccountPopup/AdminDeleteAccountPopup';

export interface IProps {
  appStore?: AppStore;
}

export interface IState {
  containerWidth: number;
  teams: any[];
  userEmail: string;
  product: string;
  team: string;
  totalUsers?: number;
  newUsers?: IUser[];
  deletedUsers?: IDeletedUser[];
  showNewUsers: boolean;
  showDeletedUsers: boolean;
}

@inject('appStore')
@observer
export default class Users extends React.Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
      containerWidth: undefined,
      teams: [],
      userEmail: undefined,
      product: undefined,
      team: undefined,
      showNewUsers: false,
      showDeletedUsers: false
    }
  }

  public componentDidMount(): void {
    if (this.props.appStore.user) {
      if (this.props.appStore.products && this.props.appStore.products.length > 0) {
        this.forceUpdate();
      } else {
        this.props.appStore.getProducts().then(() => {
          this.forceUpdate();
        });
      }
      this.getUsers();
      this.getDeletedUsers();
    }
    // Resize
    window.addEventListener('resize', () => this.resize());
    this.resize();
    setTimeout(() => {
      this.resize();
    }, 0);
    try {
      var resizeObserver = new ResizeObserver(() => {
        this.resize();
      });
      resizeObserver.observe(document.getElementById(`programs`));
    } catch {
      this.resize();
    }
  }

  private getDeletedUsers(): void {
    AuthenticationService.getDeletedUsers().then((deletedUsers: IDeletedUser[]) => {
      this.setState({ deletedUsers });
    })
  }

  private getUsers(): void {
    AdminService.getUsers().then((users: IUser[]) => {
      if (users && users.length > 0) {
        let newUsers = [];
        users.forEach((user: IUser) => {
          if (moment(user.createdAt) >= moment().subtract(30, 'days')) {
            newUsers.push(user);
          }
        });
        //const showNewUsers = newUsers.length > 0;
        this.setState({ totalUsers: users.length, newUsers });
      } else {
        this.setState({ totalUsers: 0, newUsers: undefined });
      }
    });
  }

  private filterUsers(): void {
    const usersToRender = [];
    AdminService.getUsers(this.state.userEmail).then((users: IUser[]) => {
      if (users && users.length > 0) {
        users.forEach(async (user: IUser) => {
          usersToRender.push(
            <User
              user={user}
              update={() => this.getUsers()}
            />
          );
          this.props.appStore.usersToRender = usersToRender;
        });
      }
    });
  }

  private resize(): void {
    const programsContainer = document.getElementById("programs");
    this.setState({ containerWidth: programsContainer?.clientWidth - 80 }, () => this.forceUpdate());
  }

  render() {
    const products = [];
    products.push(
      <option value={undefined}>Vælg produkt</option>
    );
    this.props.appStore.products.forEach((product: IProduct) => {
      products.push(
        <option value={product.productId}>{product.title}</option>
      );
    });
    const newUsers = [];
    if (this.state.newUsers?.length > 0) {
      this.state.newUsers.forEach((newUser: IUser) => {
        newUsers.push(
          <div className='userListElement'>
            <div className='userListElementName'>{newUser.name}</div>
            <div className='userListElementEmail'>{newUser.username}</div>
            <div className='userListElementCreated'>
              <Moment format="DD/MM YYYY - HH:MM" local>{newUser.createdAt}</Moment>
            </div>
          </div >
        );
      })
    }
    const deletedUsers = [];
    if (this.state.deletedUsers?.length > 0) {
      this.state.deletedUsers.forEach((deletedUser: IDeletedUser) => {
        deletedUsers.push(
          <div className='userListElement'>
            <div className='userListElementName'>{deletedUser.name}</div>
            <div className='userListElementEmail'>{deletedUser.username}</div>
          </div>
        );
      })
    }
    return (
      <div
        id="users"
        className="users"
        style={{
          left: this.props.appStore.isMobile ? 0 : 250,
          width: this.props.appStore.isMobile ? "100vw" : "calc(100vw - 250px)",
          height: this.props.appStore.isMobile ? "calc(100% - 130px)" : (this.props.appStore.podcastToPlay ? "calc(100% - 151px)" : "calc(100% - 50px)")
        }}
      >
        {location.href.indexOf("mindPaperId") == -1 && this.props.appStore.user && this.props.appStore.isMobile && !window.location.pathname.includes("/enneagrammy") &&
          <LogoBar />
        }
        <Header
          headline={"Brugere"}
        />
        <div className="container">
          <div className="boxInfo">
            {this.state.totalUsers != undefined ?
              <>
                <div className="boxInfoCount">
                  <CountUp className="CountUp" start={0} end={this.state.totalUsers} duration={1.8} />

                </div>
                <div className="boxInfoLabel">brugere</div>
              </>
              :
              <div className="boxInfoLoading">Tjekker...</div>
            }
          </div>

          <div
            className="boxInfo"
            style={{
              backgroundColor: this.state.showNewUsers ? "#77777720" : "#00000020"
            }}
            onClick={() => this.setState({
              showNewUsers: !this.state.showNewUsers,
              showDeletedUsers: false
            })}
          >
            {this.state.newUsers != undefined ?
              <>
                <div className="boxInfoCount">
                  <CountUp className="CountUp" start={0} end={this.state.newUsers?.length} duration={1.8} />
                </div>
                <div className="boxInfoLabel">nye brugere</div>
              </>
              :
              <div className="boxInfoLoading">Tjekker...</div>
            }
          </div>

          <div
            className="boxInfo"
            style={{
              backgroundColor: this.state.showDeletedUsers ? "#77777720" : "#00000020"
            }}
            onClick={() => this.setState({
              showDeletedUsers: !this.state.showDeletedUsers,
              showNewUsers: false
            })}
          >
            {this.state.deletedUsers != undefined ?
              <>
                <div className="boxInfoCount">
                  <CountUp className="CountUp" start={0} end={this.state.deletedUsers?.length} duration={1.8} />
                </div>
                <div className="boxInfoLabel">{this.state.deletedUsers?.length === 1 ? "slettet bruger" : "slettede brugere"}</div>
              </>
              :
              <div className="boxInfoLoading">Tjekker...</div>
            }
          </div>
        </div>
        {this.state.showNewUsers &&
          <div className="container">
            {newUsers}
          </div>
        }
        {this.state.showDeletedUsers &&
          <div className="container">
            {deletedUsers}
          </div>
        }
        <div className="container">
          <div className="filter">
            <div className="inputFieldLabel">Bruger</div>
            <input
              type="text"
              placeholder="Søg efter brugere (email)"
              value={undefined}
              onChange={(event: any) => {
                this.setState({ userEmail: event.target.value.toLowerCase() });
              }}
            />
            <div
              className="searchButton"
              onClick={() => this.filterUsers()}
            />
          </div>
          <div className="filter">
            <div className="inputFieldLabel">Brugere med adgang til produkt</div>
            <select
              name="cars"
              id="cars"
              placeholder="Vælg et produkt"
              value={this.state.product}
              onChange={(selectedOption: any) => {
                let product = selectedOption.target.value !== "Vælg produkt" ? selectedOption.target.value : undefined;
                this.setState({ product }, () => this.filterUsers());
                this.forceUpdate();
              }}
            >
              {products}
            </select>
          </div>
          <div className="usersContainer">
            {this.props.appStore.usersToRender}
          </div>
          <UserProductTeamPanel
            productAccess={this.props.appStore.productAccess}
            update={() => this.filterUsers()}
          />
        </div>
        {this.props.appStore.showingAdminDeleteAccountForUser &&
          <AdminDeleteAccountPopup 
            onReload={() => {
              this.filterUsers();
            }}
          />
        }
      </div>
    );
  }

  public componentWillUnmount(): void {
    window.removeEventListener('resize', () => this.resize());
  }

}