import * as React from 'react';
import { AppStore } from '../../../stores';
import { inject, observer } from 'mobx-react';
import './NameYourTest.css';
import TestService from '../../../services/TestService';
import { ITestResult } from '../../../interfaces/ITestResult';

export interface IProps {
  appStore?: AppStore;
}

@inject('appStore')
@observer
export default class NameYourTest extends React.Component<IProps> {

  render() {
    return (
      <div className="nameYourTest">
        <input
          type="text"
          value={this.props.appStore.result && this.props.appStore.result.name ? this.props.appStore.result.name : undefined}
          onChange={(event: any) => {
            this.props.appStore.result.name = event.target.value;
          }}
          autoFocus
        />
        <div
          className="enneagrammyNavigationBtn"
          id="doneBtn"
          style={{
            float: "left",
            marginTop: 40
          }}
          onClick={() => {
            this.props.appStore.testProgression = "CalculationResult";
            TestService.save(this.props.appStore.user.id, this.props.appStore.result, this.props.appStore.result?.name ? this.props.appStore.result.name : window.loc.strings.myTest).then((testResults: ITestResult[]) => {
              if (testResults) {
                this.props.appStore.setTestResults(testResults);
                this.props.appStore.testProgression = "ResultIsReady";
              }
            });
          }}
        >
          OK
        </div>
      </div>
    );
  }

}