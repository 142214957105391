export default class SubscriptionService {

  public static getCustomer(id: string, environment: "test" | "production"): Promise<any> {
    return new Promise<any>((resolve: (result: any) => void, reject: (error: any) => void): void => {
      fetch(`https://api.reepay.com/v1/list/customer?email=${id}`, {
        method: "GET",
        credentials: "same-origin",
        headers: {
          "Authorization": environment === "test" ? "Basic cHJpdl9hYjhhN2MzYTIwNDg2N2NmNDU5MDExYmM3M2ZkNzAzOA==" : "Basic cHJpdl85NTk2NDE1MmVkZjA1MGQyMDhlZjBlZDFlOGNhNzRjNA==",
          "Content-Type": "application/json"
        }
      }).then((response) => {
        if (!response.ok) {
          reject(response);
          return;
        }
        return response.json();
      }).then((result) => {
        resolve(result);
      })
        .catch(error => {
          reject(null);
        });
    });
  }


  public static get(id: string, environment: "test" | "production"): Promise<any> {
    return new Promise<any>((resolve: (result: any) => void, reject: (error: any) => void): void => {
      fetch(`https://api.reepay.com/v1/subscription?search=customer.handle:${id},state:active`, {
        method: "GET",
        credentials: "same-origin",
        headers: {
          "Authorization": environment === "test" ? "Basic cHJpdl9hYjhhN2MzYTIwNDg2N2NmNDU5MDExYmM3M2ZkNzAzOA==" : "Basic cHJpdl85NTk2NDE1MmVkZjA1MGQyMDhlZjBlZDFlOGNhNzRjNA==",
          "Content-Type": "application/json"
        }
      }).then((response) => {
        if (!response.ok) {
          reject(response);
          return;
        }
        return response.json();
      }).then((result) => {
        resolve(result);
      })
        .catch(error => {
          reject(null);
        });
    });
  }

}