import * as React from 'react';
import './NotificationCenter.css';
import { AppStore } from '../../stores';
import { inject, observer } from 'mobx-react';
import NotificationService from '../../services/NotificationService';
import { INotification } from '../../interfaces/INotification';
import Moment from 'react-moment';
import Header from '../header/Header';
import LogoBar from '../logoBar/LogoBar';
import PushService from '../../services/PushService';

export interface IProps {
  appStore?: AppStore;
}

export interface IState {
  pushedNotificationsToRender?: JSX.Element[];
}

@inject('appStore')
@observer
export default class NotificationCenter extends React.Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  public componentDidMount(): void {
    this.getNotifications();
  }

  private getNotifications(): void {
    NotificationService.getNotifications().then((notifications: INotification[]) => {
      if (notifications) {
        this.showNotifications(notifications);
      }
    });
  }

  private showNotifications(notifications: INotification[]): void {
    const pushedNotificationsToRender = [];
    notifications.forEach((notification: INotification) => {
      const receivers = [];
      if (notification.sendToAll) {
        receivers.push(<div className="notificationAdminElementReceiver">{notification.releaseDate ? "Sendt til alle" : "Sendes til alle"}</div>);
      }
      if (notification.receivers && notification.receivers.length > 0) {
        notification.receivers.forEach((receiver: any) => {
          if (receiver.product) {
            if (receiver.teams && receiver.teams.length > 0) {
              receiver.teams.forEach((team: any) => {
                if (team.selected) {
                  receivers.push(
                    <div className="notificationAdminElementReceiver">{`${receiver.product.title} > ${team.team}`}</div>
                  );
                }
              })
            } else {
              receivers.push(
                <div className="notificationAdminElementReceiver">{receiver.product.title}</div>
              );
            }
          }
        })
      }
      const note = (
        <div>
          <div className='notificationTime'>
            <Moment fromNow local>{notification.createdAt}</Moment>
          </div>
          <div className='notificationAdminElement'>
            <div className='notificationAdminElementReceivers'>
              {receivers}
            </div>
            <div
              className="title"
              style={{ width: "calc(100% - 20px)", float: "left" }}
            >
              {notification.title}
            </div>
            <div
              className='text'
              style={{ width: "calc(100% - 20px)", float: "left" }}
            >
              {notification.text}
            </div>
            {notification.link &&
              <a href={notification.link}>
                <div className='pushPopupBtn'>
                  {notification.linkText ? notification.linkText : window.loc.strings.goToURL}
                </div>
              </a>
            }
            <div
              className="notificationAdminElementEditButton"
              onClick={() => {
                this.props.appStore.notificationToEdit = notification;
              }}
            >
              Rediger
            </div>
            {notification.releaseDate == undefined &&
              <div
                className="notificationAdminElementEditButton"
                onClick={() => {
                  notification.releaseDate = new Date();
                  NotificationService.updateNotification(notification).then(() => {
                    this.props.appStore.notificationToEdit = undefined;
                    this.getNotifications();
                  });
                }}
              >
                Push
              </div>
            }
          </div>
        </div>
      );
      pushedNotificationsToRender.push(
        note
      );
    });
    this.setState({ pushedNotificationsToRender });
  }

  render() {
    return (
      <div
        className="notificationsAdmin"
        style={{
          left: this.props.appStore.isMobile ? 0 : 250,
          width: this.props.appStore.isMobile ? "100vw" : "calc(100vw - 250px)",
          height: this.props.appStore.isMobile ? "calc(100% - 130px)" : (this.props.appStore.podcastToPlay ? "calc(100% - 151px)" : "calc(100% - 50px)")
        }}
      >
        {location.href.indexOf("mindPaperId") == -1 && this.props.appStore.user && this.props.appStore.isMobile && !window.location.pathname.includes("/enneagrammy") &&
          <LogoBar />
        }
        <Header
          headline={"Notifikationer"}
          button={this.props.appStore.editMode ? {
            title: "Tilføj notifikation",
            onClick: () => this.props.appStore.notificationToEdit = {
              objectId: "new",
              sendToAll: true,
              title: "",
              text: "",
              linkText: "",
              link: ""
            }
          } : undefined}
        />
        <div className="notificationsContent">
          {this.state.pushedNotificationsToRender}
        </div>
      </div >
    );
  }
}