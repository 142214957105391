import * as React from 'react';
import './NoAccess.css';
import { AppStore } from '../../stores';
import { inject, observer } from 'mobx-react';

export interface IProps {
  appStore?: AppStore;
}

export interface IState {
  newPassword?: string;
  newPasswordAgain?: string;
}

@inject('appStore')
@observer
export default class NoAccess extends React.Component<IProps, IState> {

  constructor(props, context) {
    super(props, context);
    this.state = {
    }
  }

  render() {
    return (
      <div className="noAccess">
        {window.loc.strings.noAccess}
      </div>
    );
  }

}