import * as React from 'react';
import './ChangeLanguagePopup.css';
import { AppStore } from '../../stores';
import { inject, observer } from 'mobx-react';

export interface IProps {
  appStore?: AppStore;
}

export interface IState {
}

@inject('appStore')
@observer
export default class ChangeLanguagePopup extends React.Component<IProps, IState> {

  constructor(props, context) {
    super(props, context);
    this.state = {
    }
  }

  render() {
    return (
      <div className="changeLanguagePopup">
        <div
          className="changeLanguagePopupBackground"
          onClick={() => {
            this.props.appStore.showChangeLanguagePopup = false;
          }}
        />
        <div className="changeLanguagePopupBox">
          <h3>{window.loc.strings.changeLanguagePopupHeadline}</h3>
          <select
            name="cars"
            id="cars"
            placeholder="Vælg et produkt"
            value={localStorage.getItem("language")}
            onChange={(selectedOption: any) => {
              localStorage.setItem("language", selectedOption.target.value );
              location.reload();
            }}
          >
            <option value={"English"}>{window.loc.strings.English}</option>
            <option value={"Danish"}>{window.loc.strings.Danish}</option>
          </select>
        </div>
      </div>
    );
  }

}