import * as React from 'react';
import './MeditationsEditPanel.css';
import { AppStore } from '../../../stores';
import { inject, observer } from 'mobx-react';
import DateTimeService from '../../../services/DateTimeService';
import MeditationService from '../../../services/MeditationService';
import { IPodcast } from '../../../interfaces/IPodcast';
import { FilePicker } from '../../../mindjuiceApp/UI/filePicker/FilePicker';
import AdminService from '../../../services/AdminService';

export interface IProps {
  appStore?: AppStore;
  update: () => void;
}

export interface IState {
}

@inject('appStore')
@observer
export default class MeditationsEditPanel extends React.Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  private delete(): void {
    MeditationService.deleteMeditation(this.props.appStore.meditationToEdit.objectId).then(() => {
      this.props.appStore.meditationToEdit = undefined;
      this.getMeditations();
    });
  }

  private save(): void {
    if (this.props.appStore.meditationToEdit.objectId !== undefined) {
      MeditationService.updateMeditation(this.props.appStore.meditationToEdit.objectId, this.props.appStore.meditationToEdit).then(() => {
        this.props.appStore.meditationToEdit = undefined;
        this.getMeditations();
      });
    } else {
      MeditationService.createMeditation(this.props.appStore.meditationToEdit).then(() => {
        this.props.appStore.meditationToEdit = undefined;
        this.getMeditations();
      });
    }
  }

  private getMeditations(): void {
    MeditationService.getMeditations().then((meditations: IPodcast[]) => {
      this.props.appStore.meditations = meditations;
      this.props.update();
    });
  }

  render() {
    return (
      <div>
        <div
          className="meditationEditPanelWrapper"
          style={{
            visibility: this.props.appStore.meditationToEdit != undefined ? "visible" : "hidden"
          }}
          onClick={() => {
            this.props.appStore.meditationToEdit = undefined;
          }}
        />
        <div
          className="meditationEditPanel"
          style={{
            right: this.props.appStore.meditationToEdit != undefined ? 0 : -620
          }}
        >
          {this.props.appStore.meditationToEdit != undefined &&
            <div>
              <div className="editPanelCloseBtn"
                onClick={() => this.props.appStore.meditationToEdit = undefined}
              />
              <h3>Rediger meditation:</h3>
              <div className="inputFieldLabel">Billed</div>
              <FilePicker
                url={this.props.appStore.meditationToEdit.imageUrl}
                placeholderText={"Indsæt et link eller upload en billedfil..."}
                inputWidth={this.props.appStore.isMobile ? "81%" : "87%"}
                onChange={(url: string) => {
                  this.props.appStore.meditationToEdit.imageUrl = url;
                  if (url && url != undefined && url !== "" && url.indexOf("mindjuice.herokuapp.com/parse/files") !== -1) {
                    const splittedUrl = url.split(".");
                    let fileType = splittedUrl[splittedUrl.length - 1];
                    AdminService.addFile({
                      url: url,
                      fileType: fileType,
                      type: "meditationImage"
                    });
                  }
                }}
              />
              <div className="inputFieldLabel">Titel</div>
              <input
                type="text"
                value={this.props.appStore.meditationToEdit.title}
                onChange={(event: any) => {
                  this.props.appStore.meditationToEdit.title = event.target.value;
                }}
              />
              <div className="inputFieldLabel">Speaker</div>
              <input
                type="text"
                value={this.props.appStore.meditationToEdit.speaker}
                onChange={(event: any) => {
                  this.props.appStore.meditationToEdit.speaker = event.target.speaker;
                }}
              />
              <div className="inputFieldLabel">Link til MP3</div>
              <input
                type="text"
                value={this.props.appStore.meditationToEdit.link}
                onChange={(event: any) => {
                  this.props.appStore.meditationToEdit.link = event.target.value;
                }}
              />
              <div className="inputFieldLabel">Baggrundsfarve</div>
              <input
                type="color"
                value={this.props.appStore.meditationToEdit.backgroundColor}
                onChange={(event: any) => {
                  this.props.appStore.meditationToEdit.backgroundColor = event.target.value;
                }}
                style={{
                  width: 50,
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
              />
              <div
                className="inputFieldLabel"
                style={{
                  clear: "both"
                }}
              >
                Visninger
              </div>
              <input
                type="number"
                value={this.props.appStore.meditationToEdit.views}
                onChange={(event: any) => {
                  this.props.appStore.meditationToEdit.views = event.target.value;
                }}
              />
              <div
                style={{
                  marginTop: 20,
                  float: "left"
                }}
              >
                <button
                  onClick={() => this.save()}
                >
                  {this.props.appStore.meditationToEdit.objectId !== undefined ? "Opdater" : "Opret"}
                </button>
                <button
                  style={{
                    marginLeft: 10,
                    backgroundColor: "gray",
                  }}
                  onClick={() => {
                    this.props.appStore.meditationToEdit = undefined;
                  }}
                >
                  Annuller
                </button>
                <button
                  onClick={() => this.delete()}
                  style={{
                    backgroundColor: "red",
                    marginLeft: 10
                  }}
                >
                  Slet
                </button>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}