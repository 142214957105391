import * as React from 'react';
import './EventOrderPopup.css';
import { inject, observer } from 'mobx-react';
import { AppStore } from '../../../../stores';
import { IProduct } from '../../../../interfaces/IProduct';
import ProductService from '../../../../services/ProductService';

export interface IProps {
  appStore?: AppStore;
}

export interface IState {
}

@inject('appStore')
@observer
export default class EventOrderPopup extends React.Component<IProps, IState> {

  constructor(props, context) {
    super(props, context);
    this.state = {
    }
  }

  render() {
    return (
      <div className="eventOrderPopup">
        <div
          className="eventOrderPopupBackground"
          onClick={() => {
            this.props.appStore.showingEventOrderPopup = false;
          }}
        />
        <div className="eventOrderPopupBox">
          <h3>{`Vil du gemme dine ændringer?`}</h3>
          <div
            className="saveBtn"
            onClick={() => {
              this.props.appStore.showingEventOrderPopup = false;
              this.props.appStore.showEventOrderPanel = false;
              this.props.appStore.events.forEach((product: IProduct, index: number) => {
                product.index = index;
                ProductService.updateProduct(product).then(() => {
                  if (index === this.props.appStore.products.length - 1) {
                    this.props.appStore.showingEventOrderPopup = false;
                  }
                })
              });
            }}
          >
            {window.loc.strings.save}
          </div>
          <div
            className="cancelBtn"
            onClick={() => {
              location.reload();
            }}
          >
            {window.loc.strings.cancel}
          </div>
        </div>
      </div>
    );
  }

}