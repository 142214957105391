import * as React from 'react';
import './VideoList.css';
import { AppStore } from '../../../stores';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import IconService from '../../../services/IconService';
import NativeService from '../../../services/NativeService';

export interface IProps {
  id: string;
  title: string;
  image?: string;
  speaker?: string;
  description?: string;
  label?: string;
  duration?: number;
  progress?: number;
  width: number;
  marginRight: number;
  selected: boolean;
  views?: number;
  onClick: () => void;
  appStore?: AppStore;
}

export interface IState {
}

@inject('appStore')
@observer
export default class VideoList extends React.Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    let height = (this.props.width / 5 * 2.8) + 270;
    if (this.props.duration && this.props.duration) {
      height = height + 20;
    }
    return (
      <Link to={`${this.props.appStore.environment}/inspiration/?podcast=${this.props.id}`}>
        <div
          className='videoList'
          style={{
            width: this.props.width,
            height: height,
            marginRight: this.props.marginRight,
            backgroundColor: this.props.selected && !this.props.appStore.isMobile ? "#353535" : "#282828"
          }}
          onClick={() => this.props.onClick()}
        >
          <div
            className="videoListImage"
            style={{
              backgroundImage: `url(${this.props.image}`,
              width: this.props.width,
              height: this.props.width / 5 * 2.8,
            }}
          />
          {this.props.label &&
            <div className="videoListLabel">
              {`${this.props.label}`}
            </div>
          }
          <div
            className="videoListTitle"
          >
            {this.props.title}
          </div>
          <div
            className="videoListSpeaker"
          >
            {this.props.speaker}
          </div>
          <div
            className="videoListDescription"
          >
            {this.props.description}
          </div>
          <div
            className='share'
            style={{
              bottom: this.props.duration && this.props.duration ? 35 : 15
            }}
            onClick={() => {
              if (window.platform === "ios" || window.platform === "android") {
                setTimeout(() => {
                  NativeService.share(window.loc.strings.shareVideo, `https://dojo.mindjuice.com/metadata.php?&title=${encodeURIComponent(this.props.title)}&description=${encodeURIComponent(this.props.description)}&image=${encodeURIComponent(this.props.image)}&url=${encodeURIComponent(location.href)}`);
                }, 100);
              } else {
                this.props.appStore.shareContent = {
                  title: this.props.title,
                  description: this.props.description,
                  image: this.props.image
                }
              }
              setTimeout(() => {
                this.props.appStore.podcastToPlay = undefined;
              }, 0);
            }}
          />
          {this.props?.views &&
            <div
              className='views'
              style={{
                bottom: this.props.duration && this.props.duration ? 40 : 20
              }}
            >
              <div className='viewsIcon'>
                {IconService.getIcon("Headphones Sound Wave", "#ffffff")}
              </div>
              {`${this.props?.views} ${window.loc.strings.views}`}
            </div>
          }
          {this.props.duration && this.props.duration &&
            <progress
              max={this.props.duration}
              value={this.props.progress}
            />
          }
        </div>
      </Link>
    );
  }
}