import * as React from 'react';
import './PodcastEditPanel.css';
import { AppStore } from '../../../stores';
import { inject, observer } from 'mobx-react';
import DateTimeService from '../../../services/DateTimeService';
import { FilePicker } from '../../../mindjuiceApp/UI/filePicker/FilePicker';
import AdminService from '../../../services/AdminService';
import PodcastService from '../../../services/PodcastService';

export interface IProps {
  appStore?: AppStore;
  update: () => void;
}

export interface IState {
}

@inject('appStore')
@observer
export default class PodcastEditPanel extends React.Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  private save(): void {
    if (this.props.appStore.podcastToEdit.objectId !== undefined) {
      PodcastService.updatePodcast(this.props.appStore.podcastToEdit.objectId, this.props.appStore.podcastToEdit).then(() => {
        this.props.appStore.podcastToEdit = undefined;
        this.props.update();
      });
    } else {
      PodcastService.createPodcast(this.props.appStore.podcastToEdit).then(() => {
        this.props.appStore.podcastToEdit = undefined;
        this.props.update();
      });
    }
  }

  private delete(): void {
    PodcastService.deletePodcast(this.props.appStore.podcastToEdit.objectId).then(() => {
      this.props.appStore.podcastToEdit = undefined;
      this.props.update();
    });
  }

  render() {
    return (
      <div>
        <div
          className="podcastEditPanelWrapper"
          style={{
            visibility: this.props.appStore.podcastToEdit != undefined ? "visible" : "hidden"
          }}
          onClick={() => {
            this.props.appStore.podcastToEdit = undefined;
          }}
        />
        <div
          className="podcastEditPanel"
          style={{
            right: this.props.appStore.podcastToEdit != undefined ? 0 : -620
          }}
        >
          {this.props.appStore.podcastToEdit != undefined &&
            <div
              style={{
                marginTop: 20,
                float: "left",
                width: "100%"
              }}
            >
              <div className="editPanelCloseBtn"
                onClick={() => this.props.appStore.podcastToEdit = undefined}
              />
              <h3>Rediger podcast</h3>
              <div className="inputFieldLabel">Kategori</div>
              <select
                name="cars"
                id="cars"
                value={this.props.appStore.podcastToEdit.category ? this.props.appStore.podcastToEdit.category : "Mindjuice"}
                onChange={(selectedOption: any) => {
                  this.props.appStore.podcastToEdit.category = selectedOption.target.value;
                  this.forceUpdate();
                }}
              >
                <option value="Mindjuice">Mindjuice</option>
                <option value="Speakers">Speakers</option>
              </select>
              {this.props.appStore.podcastToEdit.category === "Speakers" &&
                <div>
                  <div className="inputFieldLabel">År</div>
                  <input
                    type="text"
                    value={this.props.appStore.podcastToEdit.speakerYear}
                    onChange={(event: any) => {
                      this.props.appStore.podcastToEdit.speakerYear = event.target.value;
                    }}
                  />
                </div>
              }
              <div className="inputFieldLabel">Titel</div>
              <input
                type="text"
                value={this.props.appStore.podcastToEdit.title}
                onChange={(event: any) => {
                  this.props.appStore.podcastToEdit.title = event.target.value;
                }}
              />
              <div className="inputFieldLabel">Medvirkende</div>
              <input
                type="text"
                value={this.props.appStore.podcastToEdit.speaker}
                onChange={(event: any) => {
                  this.props.appStore.podcastToEdit.speaker = event.target.value;
                }}
              />
              <div className="inputFieldLabel">Teaser</div>
              <textarea
                onChange={(event: any) => this.props.appStore.podcastToEdit.teaser = event.target.value}
              >
                {this.props.appStore.podcastToEdit.teaser}
              </textarea>
              <div className="inputFieldLabel">Billed URL</div>
              <FilePicker
                url={this.props.appStore.podcastToEdit.image}
                placeholderText={"Indsæt et link eller upload en billedfil..."}
                inputWidth={this.props.appStore.isMobile ? "81%" : "87%"}
                onChange={(url: string) => {
                  this.props.appStore.podcastToEdit.image = url;
                  if (url && url != undefined && url !== "" && url.indexOf("mindjuice.herokuapp.com/parse/files") !== -1) {
                    const splittedUrl = url.split(".");
                    let fileType = splittedUrl[splittedUrl.length - 1];
                    AdminService.addFile({
                      url: url,
                      fileType: fileType,
                      product: undefined,
                      team: this.props.appStore.podcastToEdit.speakerYear,
                      type: "podcast"
                    });
                  }
                }}
              />
              <div className="inputFieldLabel">Link</div>
              <FilePicker
                url={this.props.appStore.podcastToEdit.link}
                placeholderText={"Indsæt et link eller upload en lydfil..."}
                inputWidth={this.props.appStore.isMobile ? "81%" : "87%"}
                onChange={(url: string) => {
                  this.props.appStore.podcastToEdit.link = url;
                  if (url && url != undefined && url !== "" && url.indexOf("mindjuice.herokuapp.com/parse/files") !== -1) {
                    const splittedUrl = url.split(".");
                    let fileType = splittedUrl[splittedUrl.length - 1];
                    AdminService.addFile({
                      url: url,
                      fileType: fileType,
                      product: this.props.appStore.podcastToEdit.objectId,
                      team: undefined,
                      type: "podcast"
                    });
                  }
                }}
              />
              <div className="inputFieldLabel">Video url</div>
              <FilePicker
                url={this.props.appStore.podcastToEdit.videoUrl}
                placeholderText={"Indsæt et link eller upload en videofil..."}
                inputWidth={this.props.appStore.isMobile ? "81%" : "87%"}
                onChange={(url: string) => {
                  this.props.appStore.podcastToEdit.videoUrl = url;
                  if (url && url != undefined && url !== "" && url.indexOf("mindjuice.herokuapp.com/parse/files") !== -1) {
                    const splittedUrl = url.split(".");
                    let fileType = splittedUrl[splittedUrl.length - 1];
                    AdminService.addFile({
                      url: url,
                      fileType: fileType,
                      product: this.props.appStore.podcastToEdit.objectId,
                      team: undefined,
                      type: "podcast"
                    });
                  }
                }}
              />
              <div className="inputFieldLabel">Varighed</div>
              <input
                type="text"
                placeholder="00:00"
                value={this.props.appStore.podcastToEdit.duration}
                onChange={(event: any) => {
                  this.props.appStore.podcastToEdit.duration = event.target.value;
                }}
              />

              <div className="inputFieldLabel">Udgivelsesdato</div>
              <input
                type={"date"}
                id="start"
                name="trip-start"
                value={DateTimeService.ConvertToYYYYMMDD(this.props.appStore.podcastToEdit.releaseDate)}
                onChange={(date: any) => {
                  if (date.target.value && date.target.value !== "") {
                    this.props.appStore.podcastToEdit.releaseDate = new Date(date.target.value);
                  }
                }}
              />
              <div
                className="flowpaper"
                style={{
                  clear: "both",
                  width: "100%"
                }}
              >
                <input
                  type="checkbox"
                  checked={this.props.appStore.podcastToEdit.released}
                  onChange={() => {
                    this.props.appStore.podcastToEdit.released = this.props.appStore.podcastToEdit.released != undefined ? !this.props.appStore.podcastToEdit.released : true
                  }}
                  style={{
                    width: 20,
                    marginTop: -2
                  }}
                />
                Udgivet
              </div>
              <button
                onClick={() => this.save()}
              >
                {this.props.appStore.podcastToEdit.objectId !== undefined ? "Opdater" : "Opret"}
              </button>
              <button
                style={{
                  marginLeft: 10,
                  backgroundColor: "gray",
                }}
                onClick={() => {
                  this.props.appStore.podcastToEdit = undefined;
                }}
              >
                Annuller
              </button>
              <button
                onClick={() => this.delete()}
                style={{
                  backgroundColor: "red",
                  marginLeft: 10
                }}
              >
                Slet
              </button>
            </div>
          }
        </div>
      </div>
    );
  }
}