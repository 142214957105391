import * as React from 'react';
import './EnneagrammyProfile.css';
import { AppStore } from '../../stores';
import { inject, observer } from 'mobx-react';

export interface IProps {
  appStore?: AppStore;
}

export interface IState {
  top: number;
}

@inject('appStore')
@observer
export default class EnneagrammyProfile extends React.Component<IProps, IState> {

  constructor(props, context) {
    super(props, context);
    this.state = {
      top: window.innerHeight
    }
  }

  public componentDidMount(): void {
    setTimeout(() => {
      this.setState({ top: 0 });
    }, 0);
  }

  render() {

    let slideHeight;
    if (document.getElementById("enneagrammyInfoPopupSlide_results")) {
      const enneagrammyInfoPopupSlide = document.getElementById("enneagrammyInfoPopupSlide_results");
      slideHeight = enneagrammyInfoPopupSlide.clientHeight;
    }
    return (
      <div
        className="enneagrammyProfilePopup"
        style={{
          top: this.state.top
        }}
      >
        <div className="enneagrammyProfilePopupBox">
          <div
            className="enneagrammyProfilePopupCloseButton"
            onClick={() => {
              this.setState({ top: window.innerHeight });
              setTimeout(() => {
                this.props.appStore.isShowingUserProfilePopup = false;
              }, 500);
            }}
          />
          <div className='enneagrammyInfo'>
          {window.loc.strings.loginUser}
          </div>
          <div className='enneagrammyInfo'>
            <strong>{this.props.appStore.user.username}</strong>
          </div>
          <div
            className="enneagrammyProfileDeleteUserButton"
            style={{
              backgroundColor: "#064859",
            }}
            onClick={() => {
              this.props.appStore.showExtendedMenu = false;
              setTimeout(() => {
                this.props.appStore.logOut();
                this.props.appStore.selectedTypeTestTab = 1;
              }, 400);
            }}
          >
            {window.loc.strings.tabBarLogOut}
          </div>     
          <div
            className="enneagrammyProfileDeleteUserButton"
            style={{
              backgroundColor: "red",
            }}
            onClick={() => {
              this.props.appStore.showDeleteAccountPopup();
            }}
          >
            {window.loc.strings.deleteAccount.toUpperCase()}
          </div>
        </div>
      </div>
    );
  }

}