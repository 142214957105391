import { IMeditationIntro } from "../interfaces/IMeditationIntro";
import { IPodcast } from "../interfaces/IPodcast";

export default class MeditationService {

  public static getMeditations(): Promise<IPodcast[]> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/Meditations/`, {
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        }
      }).then((response) => {
        if (!response.ok) {
          resolve(undefined);
        } else {
          return response.json();
        }
      }).then(response => {
        resolve(response.results);
      }).catch((error) => {
        console.log(error);
        resolve(undefined);
      });
    });
  }

  public static getMeditation(id: string): Promise<IPodcast> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/Meditations/${id}`, {
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        }
      }).then((response) => {
        if (!response.ok) {
          resolve(undefined);
        } else {
          return response.json();
        }
      }).then(response => {
        resolve(response);
      }).catch((error) => {
        console.log(error);
        resolve(undefined);
      });
    });
  }

  public static createMeditation(meditation: IPodcast): Promise<void> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/Meditations/`, {
        method: "POST",
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        },
        body: JSON.stringify(
          meditation
        )
      }).then((response) => {
        if (!response.ok) {
          resolve();
        } else {
          resolve();
        }
      }).catch((error) => {
        console.log(error);
        resolve();
      });
    });
  }

  public static updateMeditation(id: string, meditation: IPodcast): Promise<void> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/Meditations/${id}`, {
        method: "PUT",
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        },
        body: JSON.stringify({
          videoUrl: meditation.videoUrl,
          videoUrlEN: meditation.videoUrlEN,
          title: meditation.title,
          backgroundColor: meditation.backgroundColor,
          views: meditation.views,
          speaker: meditation.speaker,
          imageUrl: meditation.imageUrl,
          link: meditation.link
        })
      }).then((response) => {
        if (!response.ok) {
          resolve();
        } else {
          resolve();
        }
      }).catch((error) => {
        console.log(error);
        resolve();
      });
    });
  }

  public static deleteMeditation(id: string): Promise<void> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/Meditations/${id}`, {
        method: "DELETE",
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        }
      }).then((response) => {
        if (!response.ok) {
          resolve();
        } else {
          resolve();
        }
      }).catch((error) => {
        console.log(error);
        resolve();
      });
    });
  }

  ///////////////////////////// MeditationIntro ////////////////////////////////

  public static getMeditationIntro(): Promise<IMeditationIntro> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/MeditationIntro/`, {
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        }
      }).then((response) => {
        if (!response.ok) {
          resolve(undefined);
        } else {
          return response.json();
        }
      }).then(response => {
        resolve(response.results[0]);
      }).catch((error) => {
        console.log(error);
        resolve(undefined);
      });
    });
  }

  public static createMeditationIntro(meditationIntro: IMeditationIntro): Promise<void> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/MeditationIntro/`, {
        method: "POST",
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        },
        body: JSON.stringify(meditationIntro)
      }).then((response) => {
        if (!response.ok) {
          resolve();
        } else {
          resolve();
        }
      }).catch((error) => {
        console.log(error);
        resolve();
      });
    });
  }

  public static updateMeditationIntro(meditationIntro: IMeditationIntro): Promise<void> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/MeditationIntro/${meditationIntro.objectId}`, {
        method: "PUT",
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        },
        body: JSON.stringify({
          title: meditationIntro.title,
          text: meditationIntro.text,
          titleEN: meditationIntro.titleEN,
          textEN: meditationIntro.textEN
        })
      }).then((response) => {
        if (!response.ok) {
          resolve();
        } else {
          resolve();
        }
      }).catch((error) => {
        console.log(error);
        resolve();
      });
    });
  }
}