import React = require("react");

export default class IconService {

  public static getIcon(name: string, color: string): JSX.Element {
    switch (name) {

      case "Calendar":
        return (
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M14.5 3C15.8807 3 17 4.11929 17 5.5V9.59971C16.6832 9.43777 16.3486 9.30564 16 9.20703V7H4V14.5C4 15.3284 4.67157 16 5.5 16H9.20703C9.30564 16.3486 9.43777 16.6832 9.59971 17H5.5C4.11929 17 3 15.8807 3 14.5V5.5C3 4.11929 4.11929 3 5.5 3H14.5ZM14.5 4H5.5C4.67157 4 4 4.67157 4 5.5V6H16V5.5C16 4.67157 15.3284 4 14.5 4ZM13.875 12.5C13.875 12.1548 14.1548 11.875 14.5 11.875C14.8452 11.875 15.125 12.1548 15.125 12.5C15.125 12.8452 14.8452 13.125 14.5 13.125C14.1548 13.125 13.875 12.8452 13.875 12.5ZM15 16.5C15 16.7761 14.7761 17 14.5 17C14.2239 17 14 16.7761 14 16.5V14.5C14 14.2239 14.2239 14 14.5 14C14.7761 14 15 14.2239 15 14.5V16.5ZM10 14.5C10 12.0147 12.0147 10 14.5 10C16.9853 10 19 12.0147 19 14.5C19 16.9853 16.9853 19 14.5 19C12.0147 19 10 16.9853 10 14.5ZM11 14.5C11 16.433 12.567 18 14.5 18C16.433 18 18 16.433 18 14.5C18 12.567 16.433 11 14.5 11C12.567 11 11 12.567 11 14.5Z"
              fill={color}
            />
          </svg>
        );
      case "Learning App":
        return (
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" >
            <path
              d="M5.5 2C4.11929 2 3 3.11929 3 4.5V19.5C3 20.8807 4.11929 22 5.5 22H12.8096C12.3832 21.5557 12.0194 21.051 11.7322 20.5H5.5C4.94772 20.5 4.5 20.0523 4.5 19.5H11.3135C11.159 19.0218 11.0585 18.5195 11.0189 18H4.5V4.5C4.5 3.94772 4.94772 3.5 5.5 3.5H17C17.5523 3.5 18 3.94772 18 4.5V11.0189C18.5195 11.0585 19.0218 11.159 19.5 11.3135V4.5C19.5 3.11929 18.3807 2 17 2H5.5ZM23 17.5C23 14.4624 20.5376 12 17.5 12C14.4624 12 12 14.4624 12 17.5C12 20.5376 14.4624 23 17.5 23C20.5376 23 23 20.5376 23 17.5ZM16.9138 15.097L19.7203 16.9373C19.8073 16.9933 19.8757 17.0677 19.9254 17.1604C19.9751 17.251 20 17.3502 20 17.458C20 17.5614 19.9762 17.6606 19.9285 17.7555C19.8809 17.8504 19.8146 17.9258 19.7296 17.9819L16.9231 19.8965C16.8713 19.931 16.8184 19.9569 16.7646 19.9741C16.7128 19.9914 16.6568 20 16.5967 20C16.518 20 16.4424 19.9838 16.3699 19.9515C16.2973 19.917 16.2331 19.8717 16.1772 19.8157C16.1233 19.7574 16.0798 19.6906 16.0466 19.6151C16.0155 19.5397 16 19.461 16 19.379V15.621C16 15.5369 16.0155 15.4571 16.0466 15.3816C16.0798 15.3062 16.1233 15.2404 16.1772 15.1843C16.231 15.1283 16.2942 15.0841 16.3667 15.0517C16.4393 15.0172 16.5159 15 16.5967 15C16.7128 15 16.8184 15.0323 16.9138 15.097ZM6 6C6 5.44772 6.44772 5 7 5H15C15.5523 5 16 5.44772 16 6V8C16 8.55228 15.5523 9 15 9H7C6.44772 9 6 8.55228 6 8V6ZM7.5 7.5H14.5V6.5H7.5V7.5Z"
              fill={color}
            />
          </svg>
        );

      case "People Team":
        return (
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32" fill="none">
            <path d="M16 5C14.8954 5 14 5.89543 14 7C14 8.10457 14.8954 9 16 9C17.1046 9 18 8.10457 18 7C18 5.89543 17.1046 5 16 5ZM12 7C12 4.79086 13.7909 3 16 3C18.2091 3 20 4.79086 20 7C20 9.20914 18.2091 11 16 11C13.7909 11 12 9.20914 12 7ZM25.5 6C24.6716 6 24 6.67157 24 7.5C24 8.32843 24.6716 9 25.5 9C26.3284 9 27 8.32843 27 7.5C27 6.67157 26.3284 6 25.5 6ZM22 7.5C22 5.567 23.567 4 25.5 4C27.433 4 29 5.567 29 7.5C29 9.433 27.433 11 25.5 11C23.567 11 22 9.433 22 7.5ZM5 7.5C5 6.67157 5.67157 6 6.5 6C7.32843 6 8 6.67157 8 7.5C8 8.32843 7.32843 9 6.5 9C5.67157 9 5 8.32843 5 7.5ZM6.5 4C4.567 4 3 5.567 3 7.5C3 9.433 4.567 11 6.5 11C8.433 11 10 9.433 10 7.5C10 5.567 8.433 4 6.5 4ZM8.65108 24.5052C8.17749 24.8179 7.61001 25 7 25C5.34315 25 4 23.6569 4 22V15.5C4 15.2239 4.22386 15 4.5 15H8.53095C8.62463 14.2488 8.92663 13.5622 9.37731 13H4.5C3.11929 13 2 14.1193 2 15.5V22C2 24.7614 4.23858 27 7 27C7.84011 27 8.63182 26.7928 9.32683 26.4267C9.01901 25.8285 8.78927 25.1835 8.65108 24.5052ZM22.6732 26.4267C23.3682 26.7928 24.1599 27 25 27C27.7614 27 30 24.7614 30 22V15.5C30 14.1193 28.8807 13 27.5 13H22.6227C23.0734 13.5622 23.3754 14.2488 23.4691 15H27.5C27.7761 15 28 15.2239 28 15.5V22C28 23.6569 26.6569 25 25 25C24.39 25 23.8225 24.8179 23.3489 24.5052C23.2107 25.1835 22.981 25.8285 22.6732 26.4267ZM12.5 13C11.1193 13 10 14.1193 10 15.5V23C10 26.3137 12.6863 29 16 29C19.3137 29 22 26.3137 22 23V15.5C22 14.1193 20.8807 13 19.5 13H12.5ZM12 15.5C12 15.2239 12.2239 15 12.5 15H19.5C19.7761 15 20 15.2239 20 15.5V23C20 25.2091 18.2091 27 16 27C13.7909 27 12 25.2091 12 23V15.5Z"
              fill={color}
            />
          </svg>
        );
      case "People Audience":
        return (
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" >
            <path
              d="M14.754 10C15.7205 10 16.504 10.7835 16.504 11.75V15H16.5V15.25C16.5 15.6642 16.1642 16 15.75 16C15.3358 16 15 15.6642 15 15.25V13H15.004V11.75C15.004 11.6119 14.8921 11.5 14.754 11.5H9.25193C9.11386 11.5 9.00193 11.6119 9.00193 11.75V15H9V15.25C9 15.6642 8.66421 16 8.25 16C7.83579 16 7.5 15.6642 7.5 15.25V13H7.50193V11.75C7.50193 10.7835 8.28543 10 9.25193 10H14.754ZM20.5 11.75V15.25C20.5 15.6642 20.8358 16 21.25 16C21.6642 16 22 15.6642 22 15.25V11.75C22 10.7835 21.2165 10 20.25 10L16.8747 9.99906C17.218 10.4145 17.4417 10.9325 17.4928 11.5002L20.25 11.5C20.3881 11.5 20.5 11.6119 20.5 11.75ZM2 15.25C2 15.6642 2.33579 16 2.75 16C3.16421 16 3.5 15.6642 3.5 15.25V11.75C3.5 11.6119 3.61193 11.5 3.75 11.5L6.51312 11.5002C6.56424 10.9325 6.78791 10.4145 7.13128 9.99906L3.75 10C2.7835 10 2 10.7835 2 11.75V15.25ZM12 3C13.6569 3 15 4.34315 15 6C15 7.65685 13.6569 9 12 9C10.3431 9 9 7.65685 9 6C9 4.34315 10.3431 3 12 3ZM12 4.5C11.1716 4.5 10.5 5.17157 10.5 6C10.5 6.82843 11.1716 7.5 12 7.5C12.8284 7.5 13.5 6.82843 13.5 6C13.5 5.17157 12.8284 4.5 12 4.5ZM18.5 4C19.8807 4 21 5.11929 21 6.5C21 7.88071 19.8807 9 18.5 9C17.1193 9 16 7.88071 16 6.5C16 5.11929 17.1193 4 18.5 4ZM18.5 5.5C17.9477 5.5 17.5 5.94772 17.5 6.5C17.5 7.05228 17.9477 7.5 18.5 7.5C19.0523 7.5 19.5 7.05228 19.5 6.5C19.5 5.94772 19.0523 5.5 18.5 5.5ZM5.5 4C6.88071 4 8 5.11929 8 6.5C8 7.88071 6.88071 9 5.5 9C4.11929 9 3 7.88071 3 6.5C3 5.11929 4.11929 4 5.5 4ZM5.5 5.5C4.94772 5.5 4.5 5.94772 4.5 6.5C4.5 7.05228 4.94772 7.5 5.5 7.5C6.05228 7.5 6.5 7.05228 6.5 6.5C6.5 5.94772 6.05228 5.5 5.5 5.5ZM2.75 17C2.33579 17 2 17.3358 2 17.75V18.25C2 20.3211 3.67893 22 5.75 22H18.25C20.3211 22 22 20.3211 22 18.25V17.75C22 17.3358 21.6642 17 21.25 17H2.75ZM5.75 20.5C4.59186 20.5 3.63809 19.625 3.51373 18.5H20.4863C20.3619 19.625 19.4081 20.5 18.25 20.5H5.75Z"
              fill={color}
            />
          </svg>
        );
      case "Video Clip":
        return (
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" >
            <path
              d="M9.5 9.38455V14.6162C9.5 15.1858 10.1099 15.5475 10.6097 15.2743L15.3959 12.6582C15.9163 12.3737 15.9162 11.6263 15.3958 11.3419L10.6097 8.72641C10.1099 8.45328 9.5 8.81499 9.5 9.38455ZM5.25 3C3.45507 3 2 4.45507 2 6.25V17.75C2 19.5449 3.45507 21 5.25 21H18.75C20.5449 21 22 19.5449 22 17.75V6.25C22 4.45507 20.5449 3 18.75 3H5.25ZM3.5 6.25C3.5 5.2835 4.2835 4.5 5.25 4.5H18.75C19.7165 4.5 20.5 5.2835 20.5 6.25V17.75C20.5 18.7165 19.7165 19.5 18.75 19.5H5.25C4.2835 19.5 3.5 18.7165 3.5 17.75V6.25Z"
              fill={color}
            />
          </svg>
        );
      case "Headphones Sound Wave":
        return (
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 48 48" fill="none">
            <path d="M6.5 24C6.5 14.335 14.335 6.5 24 6.5C33.665 6.5 41.5 14.335 41.5 24V28H36.25C35.5596 28 35 28.5596 35 29.25V42.75C35 43.4404 35.5596 44 36.25 44H38.25C41.4256 44 44 41.4256 44 38.25V24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24V38.25C4 41.4256 6.57436 44 9.75 44H11.75C12.4404 44 13 43.4404 13 42.75V29.25C13 28.5596 12.4404 28 11.75 28H6.5V24ZM37.5 30.5H41.5V38.25C41.5 40.0449 40.0449 41.5 38.25 41.5H37.5V30.5ZM6.5 30.5H10.5V41.5H9.75C7.95507 41.5 6.5 40.0449 6.5 38.25V30.5ZM22.75 23.25L22.75 44.75C22.75 45.4404 23.3096 46 24 46C24.6904 46 25.25 45.4404 25.25 44.75L25.25 23.25C25.25 22.5596 24.6904 22 24 22C23.3096 22 22.75 22.5596 22.75 23.25ZM18.25 28C18.9404 28 19.5 28.5596 19.5 29.25V38.75C19.5 39.4404 18.9404 40 18.25 40C17.5596 40 17 39.4404 17 38.75V29.25C17 28.5596 17.5596 28 18.25 28ZM31 29.25C31 28.5596 30.4404 28 29.75 28C29.0596 28 28.5 28.5596 28.5 29.25V38.75C28.5 39.4404 29.0596 40 29.75 40C30.4404 40 31 39.4404 31 38.75V29.25Z"
              fill={color}
            />
          </svg>
        );
      case "Info":
        return (
          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
            <path
              d="M15 8C15 8.55228 14.5523 9 14 9C13.4477 9 13 8.55228 13 8C13 7.44772 13.4477 7 14 7C14.5523 7 15 7.44772 15 8ZM13.25 11.75L13.25 19.25C13.25 19.6642 13.5858 20 14 20C14.4142 20 14.75 19.6642 14.75 19.25L14.75 11.75C14.75 11.3358 14.4142 11 14 11C13.5858 11 13.25 11.3358 13.25 11.75ZM2 14C2 7.37258 7.37258 2 14 2C20.6274 2 26 7.37258 26 14C26 20.6274 20.6274 26 14 26C7.37258 26 2 20.6274 2 14ZM14 3.5C8.20101 3.5 3.5 8.20101 3.5 14C3.5 19.799 8.20101 24.5 14 24.5C19.799 24.5 24.5 19.799 24.5 14C24.5 8.20101 19.799 3.5 14 3.5Z"
              fill={color}
            />
          </svg>
        );
      case "Arrow":
        return (
          <svg width="18" height="18" viewBox="0 0 15 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 2L2.4 9.6L10 17.2" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"></path>
          </svg>
        );
    }
  }


}