import * as React from 'react';
import './ProductOrderPopup.css';
import { inject, observer } from 'mobx-react';
import { AppStore } from '../../../../stores';
import { IProduct } from '../../../../interfaces/IProduct';
import ProductService from '../../../../services/ProductService';

export interface IProps {
  appStore?: AppStore;
}

export interface IState {
}

@inject('appStore')
@observer
export default class ProductOrderPopup extends React.Component<IProps, IState> {

  constructor(props, context) {
    super(props, context);
    this.state = {
    }
  }

  render() {
    return (
      <div className="productOrderPopup">
        <div
          className="productOrderPopupBackground"
          onClick={() => {
            this.props.appStore.showingProductOrderPopup = false;
          }}
        />
        <div className="productOrderPopupBox">
          <h3>{`Vil du gemme dine ændringer?`}</h3>
          <div
            className="saveBtn"
            onClick={() => {
              this.props.appStore.showingProductOrderPopup = false;
              this.props.appStore.showProductOrderPanel = false;
              this.props.appStore.products.forEach((product: IProduct, index: number) => {
                product.index = index;
                ProductService.updateProduct(product).then(() => {
                  if (index === this.props.appStore.products.length - 1) {
                    this.props.appStore.showingProductOrderPopup = false;
                  }
                })
              });
            }}
          >
            {window.loc.strings.save}
          </div>
          <div
            className="cancelBtn"
            onClick={() => {
              location.reload();
            }}
          >
            {window.loc.strings.cancel}
          </div>
        </div>
      </div>
    );
  }

}