import * as React from 'react';
import './EnneagrammyIntro.css';
import { inject, observer } from 'mobx-react';
import { AppStore } from '../../stores';
import NativeService from '../../services/NativeService';
import ProductService from '../../services/ProductService';

export interface IProps {
  appStore?: AppStore;
}

@inject('appStore')
@observer
export default class EnneagrammyIntro extends React.Component<IProps> {

  public componentDidMount(): void {
    this.props.appStore.selectedTypeTestTab = 1;
  }

  render() {
    return (
      <div
        className="enneagrammyIntro"
      >
        <div className="enneagrammyIntroContainer">
          <iframe
            src={`https://player.vimeo.com/video/${window.loc.strings.enneagramVideo}`}
            width="100%"
            height={window.innerWidth < 768 ? (window.innerWidth * 0.9) / 16 * 9 : (window.innerWidth - 290) / 16 * 9 }
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
            style={{
              backgroundColor: "#064859"
            }}
          />
          <h1>{window.loc.strings.testIntroHeadline}</h1>
          <div
            className="enneagrammyIntroTextContainer"
            dangerouslySetInnerHTML={{ __html: window.loc.strings.frontpageAboutText }}
          />
        </div>
        <div className="startBtnContainer">
          <div
            className="startBtn"
            style={{
              backgroundColor: "#064859"
            }}
            onClick={() => !localStorage.getItem("typeTestAccess") && this.props.appStore.user.userType !== "admin" ? this.props.appStore.isShowingSubscriptionPopup = true : this.props.appStore.startTest()}
          // onClick={() => this.props.appStore.startTest()}
          >
            {!localStorage.getItem("typeTestAccess") && this.props.appStore.user.userType !== "admin" ? window.loc.strings.openShop : window.loc.strings.testIntroStartBtn}
            {/* {window.loc.strings.testIntroStartBtn} */}
          </div>
        </div>
      </div>
    );
  }
}