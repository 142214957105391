import * as React from 'react';
import './TabBar.css';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { AppStore } from '../../stores';

export interface IProps {
  appStore?: AppStore;
}

export interface IState {
}

@inject('appStore')
@observer
export default class TabBar extends React.Component<IProps, IState> {

  constructor(props,) {
    super(props);
    this.state = {
    }
  }

  render() {
    return (
      <div className="tabBar">
        {/* Today */}
        <Link
          to={`${this.props.appStore.environment}/`}
          key={"home"}
        >
          <div
            className="tabBarItem"
            style={{
              opacity: this.props.appStore.selectedTab === 1 ? 1 : 0.5,
              marginLeft: "10%"
            }}
            onClick={() => {
              this.props.appStore.showPodcastPlayerInFullScreen = false;
              this.props.appStore.showExtendedMenu = false;
              this.props.appStore.selectedTab = 1;
              this.render();
            }}
          >
            <div
              className="tabBarItemIcon"
              style={{
                backgroundImage: `url(https://dojo.mindjuice.com/images/tabbar/home${this.props.appStore.selectedTab === 1 ? "-selected" : ""}.png)`
              }}
            />
            <div className="tabBarItemText">
              {window.loc.strings.tabBarToday}
            </div>
          </div>
        </Link>
        {/* Programs */}
        {/* <Link
          to={`${this.props.appStore.environment}/programs/`}
          key={"programs"}
        >
          <div
            className="tabBarItem"
            style={{
              opacity: this.props.appStore.selectedTab === 2 ? 1 : 0.5
            }}
            onClick={() => {
              this.props.appStore.showPodcastPlayerInFullScreen = false;
              this.props.appStore.showExtendedMenu = false;
              this.props.appStore.selectedTab = 2;
              this.render();
            }}
          >
            <div
              className="tabBarItemIcon"
              style={{
                backgroundImage: `url(https://dojo.mindjuice.com/images/tabbar/programs${this.props.appStore.selectedTab === 2 ? "-selected" : ""}.png)`
              }}
            />
            <div className="tabBarItemText">
              {window.loc.strings.tabBarPrograms}
            </div>
          </div>
        </Link> */}
        {/* Tests */}
        <Link
          to={`${this.props.appStore.environment}/tests/`}
          key={"tests"}
        >
          <div
            className="tabBarItem"
            style={{
              opacity: this.props.appStore.selectedTab === 2 ? 1 : 0.5
            }}
            onClick={() => {
              this.props.appStore.showPodcastPlayerInFullScreen = false;
              this.props.appStore.showExtendedMenu = false;
              this.props.appStore.selectedTab = 2;
              this.render();
            }}
          >
            <div
              className="tabBarItemIcon"
              style={{
                backgroundImage: `url(https://dojo.mindjuice.com/images/tabbar/tests${this.props.appStore.selectedTab === 3 ? "-selected" : ""}.png)`
              }}
            />
            <div className="tabBarItemText">
              {window.loc.strings.tabBarTests}
            </div>
          </div>
        </Link>
        {/* Podcasts */}
        <Link
          to={`${this.props.appStore.environment}/inspiration/`}
          key={"podcasts"}
        >
          <div
            className="tabBarItem"
            style={{
              opacity: this.props.appStore.selectedTab === 3 ? 1 : 0.5
            }}
            onClick={() => {
              this.props.appStore.showPodcastPlayerInFullScreen = false;
              this.props.appStore.showExtendedMenu = false;
              this.props.appStore.selectedTab = 3;
              this.render();
            }}
          >
            <div
              className="tabBarItemIcon"
              style={{
                backgroundImage: `url(https://dojo.mindjuice.com/images/tabbar/podcasts${this.props.appStore.selectedTab === 4 ? "-selected" : ""}.png)`
              }}
            />
            <div className="tabBarItemText">
              {window.loc.strings.tabBarPodcasts}
            </div>
          </div>
        </Link>
        {/* Meditations */}
        <Link
          to={`${this.props.appStore.environment}/meditations/`}
          key={"meditations"}
        >
          <div
            className="tabBarItem"
            style={{
              opacity: this.props.appStore.selectedTab === 4 ? 1 : 0.5
            }}
            onClick={() => {
              this.props.appStore.showPodcastPlayerInFullScreen = false;
              this.props.appStore.showExtendedMenu = false;
              this.props.appStore.selectedTab = 4;
              this.render();
            }}
          >
            <div
              className="tabBarItemIcon"
              style={{
                backgroundImage: `url(https://dojo.mindjuice.com/images/tabbar/meditations${this.props.appStore.selectedTab === 5 ? "-selected" : ""}.png)`
              }}
            />
            <div className="tabBarItemText">
              {window.loc.strings.tabBarMeditations}
            </div>
          </div>
        </Link>
      </div>
    );
  }

}