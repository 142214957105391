import * as React from 'react';
import './Accordion.css'
import { AccordionItem } from './accordionItem/AccordionItem';

export interface IProps {
  items: any[];
  isMobile?: boolean;
  expandedAsDefault?: boolean;
  openInPopup: (url: string) => void;
  openInPageEmbed: (url: string) => void;
}

export class Accordion extends React.Component<IProps> {

  constructor(props: IProps) {
    super(props);
  }

  public render(): JSX.Element {
    const items = [];
    this.props.items.forEach((item: any, index) => {
      items.push(
        <AccordionItem
          key={item.id}
          item={item}
          hideBorder={index === this.props.items.length - 1}
          isMobile={this.props.isMobile}
          expandedAsDefault={this.props.expandedAsDefault}
          openInPopup={(url: string) => this.props.openInPopup(url)}
          openInPageEmbed={(url: string) => this.props.openInPageEmbed(url)}
        />
      )
    });
    return (
      <div
        className="acccordion"
      >
        {items}
      </div>
    );
  }
}