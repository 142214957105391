import * as React from 'react';
import './FilePicker.css';
import { Icon } from '../icon';
import AdminService from '../../../services/AdminService';

export interface IFilePickerProps {
  url: string;
  helpLabel?: string;
  imageHeight?: number;
  showPreview?: boolean;
  style?: React.CSSProperties;
  uploadButtomDisabled?: boolean;
  onChange: (text: string) => void;
  modified?: Date;
  placeholderText?: string;
  previewImageFit?: "cover" | "contain";
  imageName?: string; // Only for Azure uploads
  previewSpinnerWidth?: number;
  inputWidth?: string;
}

export interface IFilePickerState {
  isUploading: boolean;
  imageHeight: number;
  showConfirmOverwrite: boolean;
  lastUploadedDate?: Date;
  previewHasLoaded?: boolean;
  onDragOver: boolean;
  saving: boolean;
}

export class FilePicker extends React.Component<IFilePickerProps, IFilePickerState> {

  constructor(props: IFilePickerProps) {
    super(props);
    this.state = {
      isUploading: false,
      imageHeight: 0,
      showConfirmOverwrite: false,
      onDragOver: false,
      saving: false
    };
    document.addEventListener('dragend', (event: any) => {
      this.setState({ onDragOver: false });
    });
  }

  public render(): JSX.Element {
    return (
      <div
        className={"filePicker"}
        id="FilePicker"
        style={this.props.style}
      >
        <input
          style={{
            clear: "both",
            marginTop: 9,
            paddingLeft: "10px",
            width: this.props.inputWidth ? this.props.inputWidth : "87%",
            border: this.state.onDragOver ? "1px dashed green" : "1px solid #cccccc",
            opacity: this.state.saving ? 0.5 : 1
          }}
          onDragOver={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onDragEnter={e => {
            e.preventDefault();
            e.stopPropagation();
            this.setState({ onDragOver: true });
          }}
          onDragLeave={e => {
            e.preventDefault();
            e.stopPropagation();
            this.setState({ onDragOver: false });
          }}
          onDrop={e => {
            e.preventDefault();
            e.stopPropagation();
            if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
              this.setState({ saving: true });
              AdminService.upload(e.dataTransfer.files[0]).then((url: string) => {
                this.setState({ saving: false });
                this.setState({ onDragOver: false });
                if (url) {
                  this.props.onChange(url)
                } else {
                  alert("Filen var enten for stor eller der skete en fejl!")
                }
              })
            }
          }}
          type="text"
          placeholder={this.props.placeholderText}
          value={this.props.url != undefined ? this.props.url : ""}
          onChange={(event: any) => this.props.onChange(event.target.value)}
        />
        {!this.state.saving ?
          <>
            <div
              className={"FilePickerClearButton"}
              style={{
                marginTop: 16
              }}
            >
              <Icon
                title="Close"
                size={22}
                color={"red"}
                onClick={() => {
                  this.props.onChange("");
                }}
              />
            </div>
            <div className={this.state.isUploading ? "uploadButton" + " " + "uploading" : "uploadButton"}
              style={{
                marginTop: 16,
                marginLeft: 5
              }}>
              <Icon
                title="Upload"
                size={22}
                color={"#272727"}
                onClick={() => {
                  this.props.onChange(undefined);
                }}
              />
              <input
                className={"inputfile"}
                type="file"
                disabled={this.state.isUploading ? true : this.props.uploadButtomDisabled}
                onChange={(event) => {
                  if (event.target.files[0]) {
                    this.setState({ saving: true });
                    AdminService.upload(event.target.files[0]).then((url: string) => {
                      this.setState({ saving: false });
                      if (url) {
                        this.props.onChange(url);
                      } else {
                        alert("Filen var enten for stor eller der skete en fejl!")
                      }
                    });
                  }
                }}
                style={{
                  marginTop: -30,
                  cursor: "pointer"
                }}
              />
            </div>
          </>
          :
          <div className={this.state.isUploading ? "uploadButton" + " " + "uploading" : "uploadButton"}
            style={{
              marginTop: 16,
              marginLeft: 5
            }}>
            <div
              className={"FilePicker_Spinner"}
            >
              <Icon
                title="Spinner"
                size={22}
                color={"#aaaaaa"}
              />
            </div>
          </div>
        }
        {this.props.helpLabel &&
          <div className={"helpLabel"}><label style={{ float: "left" }}>{this.props.helpLabel}</label></div>
        }
        {this.props.showPreview && this.props.url != undefined && this.props.url != "" &&
          <>
            {!this.state.previewHasLoaded &&
              <div
                className={"FilePicker_SpinnerContainer"}
                style={{ width: this.props.previewSpinnerWidth ? this.props.previewSpinnerWidth : "100%" }}
              >
                <div
                  className={"FilePicker_Spinner"}
                >
                  <Icon
                    title="Spinner"
                    size={40}
                    color={"#aaaaaa"}
                  />
                </div>
              </div>
            }
            <img
              className={"FilePickerPreview"}
              src={this.props.url}
              style={{
                opacity: this.state.previewHasLoaded ? 1 : 0
              }}
              onLoad={() => this.setState({ previewHasLoaded: true })}
            />
          </>
        }
      </div>
    );
  }
}