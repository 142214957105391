import { IDeletedUser, IUser } from "../interfaces/IUser";

export default class AuthenticationService {

  public static getUser(user: IUser): Promise<IUser> {
    return new Promise((resolve) => {
      const url = `https://mindjuice.herokuapp.com/parse/users/${user.id}`;
      fetch(
        url, {
        method: "GET",
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        }
      }).then((response) => {
        if (!response.ok) {
          resolve(undefined);
        } else {
          return response.json();
        }
      }).then(user => {
        if (user) {
          user.id = user.objectId
        }
        resolve(user);
      }).catch((error) => {
        resolve(undefined);
      });
    });
  }

  public static login(username: string, password: string): Promise<IUser> {
    return new Promise((resolve) => {
      const url = `https://mindjuice.herokuapp.com/parse/login?username=${username}&password=${password}`;
      fetch(
        url, {
        method: "GET",
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        }
      }).then((response) => {
        if (!response.ok) {
          resolve(undefined);
        } else {
          return response.json();
        }
      }).then(response => {
        let user: IUser = {
          id: response.objectId,
          username: response.username,
          name: response.name,
          userType: response.userType,
          onDemandAccess: response.onDemandAccess,
          sessionToken: response.sessionToken,
          createdAt: response.createdAt
        };
        resolve(user);
      }).catch((error) => {
        resolve(undefined);
      });
    });
  }

  public static signUp(username: string, password: string, name: string): Promise<IUser> {
    return new Promise((resolve) => {
      const url = `https://mindjuice.herokuapp.com/parse/users`;
      fetch(
        url, {
        method: "POST",
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        },
        body: JSON.stringify({
          name: name,
          username: username,
          password: password
        })
      }).then((response) => {
        if (!response.ok) {
          resolve(undefined);
        } else {
          return response.json();
        }
      }).then(response => {
        let user: IUser = {
          id: response.objectId,
          username: username,
          name: name,
          sessionToken: response.sessionToken,
          createdAt: response.createdAt
        };
        resolve(user);
      }).catch((error) => {
        console.log(error);
        resolve(undefined);
      });
    });
  }

  public static updateUser(user: IUser): Promise<void> {
    return new Promise((resolve) => {
      const url = `https://mindjuice.herokuapp.com/parse/users/${user.id}`;
      fetch(
        url, {
        method: "PUT",
        headers: {
          'X-Parse-Application-Id': 'mindjuice',
          'X-Parse-Session-Token': user.sessionToken
        },
        body: JSON.stringify({
          name: user.name,
          password: user.password,
        })
      }).then((response) => {
        if (!response.ok) {
          resolve();
        } else {
          return response.json();
        }
      }).then(response => {
        resolve();
      }).catch((error) => {
        console.log(error);
        resolve();
      });
    });
  }

  public static delete(id: string, sessionToken: string): Promise<number> {
    return new Promise((resolve) => {
      const url = `https://mindjuice.herokuapp.com/parse/users/${id}`;
      fetch(
        url, {
        method: "DELETE",
        headers: {
          'X-Parse-Application-Id': 'mindjuice',
          'X-Parse-Session-Token': sessionToken
        }
      }).then((response) => {
        if (!response.ok) {
          resolve(500);
        } else {
          resolve(200);
        }
      }).catch((error) => {
        console.log(error);
        resolve(500);
      });
    });
  }

  public static resetPassword(username: string): Promise<Number> {
    return new Promise((resolve) => {
      const url = `https://mindjuice.herokuapp.com/parse/requestPasswordReset`;
      fetch(
        url, {
        method: "POST",
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        },
        body: JSON.stringify({ email: username.toLowerCase() })
      }).then((response) => {
        if (!response.ok) {
          resolve(500);
        } else {
          return response.json();
        }
      }).then(() => {
        resolve(200);
      }).catch((error) => {
        console.log(error);
        resolve(500);
      });
    });
  }

  //

  public static addDeletedUser(user: IDeletedUser): Promise<void> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/DeletedUsers/`, {
        method: "POST",
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        },
        body: JSON.stringify(
          user
        )
      }).then((response) => {
        if (!response.ok) {
          resolve();
        } else {
          resolve();
        }
      }).catch((error) => {
        console.log(error);
        resolve();
      });
    });
  }

  public static getDeletedUsers(): Promise<IDeletedUser[]> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/DeletedUsers/`, {
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        }
      }).then((response) => {
        if (!response.ok) {
          resolve(undefined);
        } else {
          return response.json();
        }
      }).then(response => {
        resolve(response.results);
      }).catch((error) => {
        console.log(error);
        resolve(undefined);
      });
    });
  }

}