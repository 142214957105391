import * as React from 'react';
import './EnneagrammyShop.css';
import { AppStore } from '../../stores';
import { inject, observer } from 'mobx-react';
import NativeService from '../../services/NativeService';

export interface IProps {
  appStore?: AppStore;
}

export interface IState {
  top: number;
}

@inject('appStore')
@observer
export default class EnneagramyShop extends React.Component<IProps, IState> {

  constructor(props, context) {
    super(props, context);
    this.state = {
      top: window.innerHeight
    }
  }

  public componentDidMount(): void {
    setTimeout(() => {
      this.setState({ top: 0 });
    }, 0);
  }

  render() {

    let slideHeight;
    if (document.getElementById("enneagrammyInfoPopupSlide_results")) {
      const enneagrammyInfoPopupSlide = document.getElementById("enneagrammyInfoPopupSlide_results");
      slideHeight = enneagrammyInfoPopupSlide.clientHeight;
    }
    return (
      <div
        className="enneagrammyShopInfoPopup"
        style={{
          top: this.state.top
        }}
      >
        <div
          className="enneagrammyShopInfoPopupCloseButton"
          onClick={() => {
            this.setState({ top: window.innerHeight });
            setTimeout(() => {
              this.props.appStore.isShowingSubscriptionPopup = false;
            }, 500);
          }}
        />
        <div className="enneagrammyShopInfoPopupBox">
          {this.props.appStore.user.userType === "admin" &&
            <div
              className='enneagrammyShopTeaser'
              style={{
                paddingBottom: 2
              }}
            >
              Dit abonnemnet:
            </div>
          }
          {(this.props.appStore.user.userType === "admin" || this.props.appStore.subscription === "accessFromAdmin") &&
            <div
              className='enneagrammyShopTeaser'
              style={{
                paddingBottom: 40,
                color: "#064859",
                fontWeight: "bold",
                fontSize: 24
              }}
            >
              UBEGRÆNSET
            </div>
          }
          <div style={{
            opacity: this.props.appStore.user.userType === "admin" ? 0.5 : 1,
            pointerEvents: this.props.appStore.user.userType === "admin" ? "none" : "unset"
          }}>
            {this.props.appStore.subscription == undefined && this.props.appStore.user.userType !== "admin" &&
              <div className='enneagrammyShopHeadine'>
                NEMMERE, NÅR DU KENDER VEJEN
              </div>
            }
            {this.props.appStore.subscription == undefined && this.props.appStore.user.userType !== "admin" &&
              <div className='enneagrammyShopTeaser'>
                <p>I Enneagrammy får du, guidet af Pernille W. Lauritsen, et indblik i Enneagramtypernes typiske styrker, svagheder og udviklingsveje i forhold til at lykkes i en personlig forandringsproces.</p>
              </div>
            }
            {localStorage.getItem("typeTestAccess") && this.props.appStore.user.userType !== "admin" &&
              <div
                className='enneagrammyShopTeaser'
                style={{
                  paddingBottom: 2
                }}
              >
                Dit abonnemnet:
              </div>
            }
            {localStorage.getItem("typeTestAccess") && this.props.appStore.user.userType !== "admin" &&
              <div
                className='enneagrammyShopTeaser'
                style={{
                  paddingBottom: 40,
                  color: "#064859",
                  fontWeight: "bold",
                  fontSize: 24
                }}
              >
                {this.props.appStore.selectedSubscription === "enneagramapp.mindjuice.dk.yearlySubscription" ? "ÅRLIGT ABONNEMENT" : "MÅNEDLIGT ABONNEMENT"}
              </div>
            }
            <div
              className='enneagrammyShopButton'
              style={{
                border: this.props.appStore.selectedSubscription === "enneagramapp.mindjuice.dk.monthlySubscription" ? `2px solid #064859` : `2px solid transparent`,
                backgroundColor: this.props.appStore.selectedSubscription === "enneagramapp.mindjuice.dk.monthlySubscription" ? "#beeeee" : "#eeeeee"
              }}
              onClick={() => {

                this.props.appStore.selectedSubscription = "enneagramapp.mindjuice.dk.monthlySubscription";
              }}
            >
              <div className='enneagrammyShopButtonDiscountLabel' style={{ opacity: 0 }}>58% RABAT</div>
              <div className='enneagrammyShopButtonTitle'>MÅNEDLIG</div>
              <div className='enneagrammyShopButtonPrize'>79,-</div>
              <div className='enneagrammyShopButtonSubtitle'></div>
            </div>
            <div
              className='enneagrammyShopButton'
              style={{
                border: this.props.appStore.selectedSubscription === "enneagramapp.mindjuice.dk.yearlySubscription" ? `2px solid #064859` : `2px solid transparent`,
                backgroundColor: this.props.appStore.selectedSubscription === "enneagramapp.mindjuice.dk.yearlySubscription" ? "#beeeee" : "#eeeeee"
              }}
              onClick={() => {
                this.props.appStore.selectedSubscription = "enneagramapp.mindjuice.dk.yearlySubscription";
              }}
            >
              <div className='enneagrammyShopButtonDiscountLabel'>58% RABAT</div>
              <div className='enneagrammyShopButtonTitle'>ÅRLIG</div>
              <div className='enneagrammyShopButtonPrize'>399,-</div>
              <div className='enneagrammyShopButtonSubtitle'>33,25 per måned</div>

            </div>
            {window.platform === "ios" ?
              <div
                className="enneagrammyShopButtonBuy"
                style={{
                  backgroundColor: "#064859",
                  opacity: this.props.appStore.subscription === this.props.appStore.selectedSubscription ? 0.5 : 1
                }}
                onClick={() => {
                  if (this.props.appStore.subscription !== this.props.appStore.selectedSubscription) {

                    if (this.props.appStore.selectedSubscription === "enneagramapp.mindjuice.dk.monthlySubscription") {
                      NativeService.buyMonthlySubscription();
                      this.props.appStore.isWaitingForApple = true;
                    } else {
                      NativeService.buyYearlySubscription();
                      this.props.appStore.isWaitingForApple = true;
                    }
                    setTimeout(() => {
                      NativeService.isHandlingATrsnsaction();
                    }, 500);
                  }
                }}
              >
                {this.props.appStore.subscription == undefined ? window.loc.strings.subscrineNow : window.loc.strings.changeSubscription}
              </div>
              :
              <a href="https://www.mindjuice.com/produkt/enneagramtest/" target="_blank">
                <div
                  className="enneagrammyShopButtonBuy"
                  style={{
                    backgroundColor: "#064859",
                    opacity: this.props.appStore.subscription === this.props.appStore.selectedSubscription ? 0.5 : 1
                  }}
                >
                  {this.props.appStore.subscription == undefined ? window.loc.strings.subscrineNow : window.loc.strings.changeSubscription}
                </div>
              </a>
            }
            {window.platform === "ios" &&
              <div
                className='enneagrammyShopRestoreButton'
                style={{
                  color: "#064859"
                }}
                onClick={() => {
                  NativeService.restore();
                  this.props.appStore.isWaitingForApple = true;
                  this.props.appStore.isRestoringSubscription = true;
                  setTimeout(() => {
                    NativeService.isHandlingATrsnsaction();
                  }, 500);
                  setTimeout(() => {
                    NativeService.isUserSubscribed();
                  }, 5000);
                }}
              >
                {window.loc.strings.restore}
              </div>
            }
            <div className='enneagrammyShopDisclaimer'>
              {window.loc.strings.subscriptionDisclaimer}
            </div>
            <a href={"https://www.apple.com/legal/internet-services/itunes/dev/stdeula/"} target="_blank">
              <div
                className='enneagrammyShopLink'
                style={{
                  color: "#064859"
                }}
              >
                {window.loc.strings.EULA.toUpperCase()}
              </div>
            </a>
            <a href={window.loc.strings.language === "Danish" ? "https://www.mindjuice.com/privacy-policy-da.html" : "https://www.mindjuice.com/privacy-policy-en.html"} target="_blank">
              <div
                className='enneagrammyShopLink'
                style={{
                  color: "#064859",
                  marginBottom: 40
                }}
              >
                {window.loc.strings.personalDataPolicy.toUpperCase()}
              </div>
            </a>
          </div>
          {this.props.appStore.isWaitingForApple &&
            <div
              className='enneagrammyShopSpinner'
            >
              <div className="spinner" />
            </div>
          }
        </div>
      </div>
    );
  }

}