import { IPodcast } from "../interfaces/IPodcast";
import { IPodcastIntro } from "../interfaces/IPodcastIntro";

export default class PodcastService {

  public static getPodcasts(): Promise<IPodcast[]> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/Podcasts/`, {
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        }
      }).then((response) => {
        if (!response.ok) {
          resolve(undefined);
        } else {
          return response.json();
        }
      }).then(response => {
        resolve(response.results);
      }).catch((error) => {
        console.log(error);
        resolve(undefined);
      });
    });
  }

  public static getPodcast(id: string): Promise<IPodcast> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/Podcasts/${id}`, {
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        }
      }).then((response) => {
        if (!response.ok) {
          resolve(undefined);
        } else {
          return response.json();
        }
      }).then(response => {
        resolve(response);
      }).catch((error) => {
        console.log(error);
        resolve(undefined);
      });
    });
  }

  public static createPodcast(podcast: IPodcast): Promise<void> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/Podcasts/`, {
        method: "POST",
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        },
        body: JSON.stringify(
          podcast
        )
      }).then((response) => {
        if (!response.ok) {
          resolve();
        } else {
          resolve();
        }
      }).catch((error) => {
        console.log(error);
        resolve();
      });
    });
  }

  public static updatePodcast(id: string, podcast: IPodcast): Promise<void> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/Podcasts/${id}`, {
        method: "PUT",
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        },
        body: JSON.stringify({
          title: podcast.title,
          speaker: podcast.speaker,
          teaser: podcast.teaser,
          image: podcast.image,
          link: podcast.link,
          videoUrl: podcast.videoUrl,
          duration: podcast.duration,
          releaseDate: podcast.releaseDate,
          category: podcast.category,
          speakerYear: podcast.speakerYear,
          views: podcast.views,
          released: podcast.released,
          backgroundColor: podcast.backgroundColor,
        })
      }).then((response) => {
        if (!response.ok) {
          resolve();
        } else {
          resolve();
        }
      }).catch((error) => {
        console.log(error);
        resolve();
      });
    });
  }

  public static deletePodcast(id: string): Promise<void> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/Podcasts/${id}`, {
        method: "DELETE",
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        }
      }).then((response) => {
        if (!response.ok) {
          resolve();
        } else {
          resolve();
        }
      }).catch((error) => {
        console.log(error);
        resolve();
      });
    });
  }

  ///////////////////////////// PodcastIntro ////////////////////////////////

  public static getPodcastIntro(): Promise<IPodcastIntro> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/PodcastIntro/`, {
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        }
      }).then((response) => {
        if (!response.ok) {
          resolve(undefined);
        } else {
          return response.json();
        }
      }).then(response => {
        resolve(response.results[0]);
      }).catch((error) => {
        console.log(error);
        resolve(undefined);
      });
    });
  }

  public static updatePodcastIntro(podcastIntro: IPodcastIntro): Promise<void> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/PodcastIntro/${podcastIntro.objectId}`, {
        method: "PUT",
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        },
        body: JSON.stringify({
          title: podcastIntro.title,
          text: podcastIntro.text,
          titleEN: podcastIntro.titleEN,
          textEN: podcastIntro.textEN
        })
      }).then((response) => {
        if (!response.ok) {
          resolve();
        } else {
          resolve();
        }
      }).catch((error) => {
        console.log(error);
        resolve();
      });
    });
  }
}