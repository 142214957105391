import * as React from 'react';
import './EnneagrammySplitTestAnswer.css';
import { inject, observer } from 'mobx-react';
import { ISplitTestAnswer } from '../../../../interfaces/ISplitTestAnswer';
import { AppStore } from '../../../../stores';

export interface IProps {
  appStore?: AppStore;
  answer: ISplitTestAnswer
  onSelect: (type: number) => void;
}

@inject('appStore')
@observer
export default class EnneagrammySplitTestAnswer extends React.Component<IProps> {

  constructor(props, context) {
    super(props, context);
  }

  render() {
    return (
      <div
        className={"splitEnneagrammyAnswerBtn"}
        style={{
          backgroundColor: "#ffffff"
        }}
        onClick={() => this.props.onSelect(this.props.answer.type)}
      >
        <div className="typeTestAnswerBtnText">{window.loc.strings.language === "Danish" ? this.props.answer.answer : this.props.answer.answerEn}</div>
      </div>
    );
  }

}
