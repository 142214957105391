import * as React from 'react';
import './EnneagrammyNavigationBar.css';
import { AppStore } from '../../../stores';
import { inject, observer } from 'mobx-react';

export interface IProps {
  appStore?: AppStore;
}

export interface IState {
  animating: boolean;
}

@inject('appStore')
@observer
export default class EnneagrammyNavigationBar extends React.Component<IProps, IState> {

  constructor(props, context) {
    super(props, context);
    this.state = {
      animating: false
    }
  }

  render() {
    return (
      <div className="enneagrammyNavigation"
        style={{
          bottom: this.props.appStore.testProgression === "SplitTestIntro" ? window.innerHeight / 3 : window.innerHeight / 5
        }}>
        <div
          className="enneagrammyNavigationBtn"
          id="doneBtn"
          onClick={() => this.props.appStore.showSplitTest()}
          style={{
            display: this.props.appStore.testProgression === "SplitTestIntro" ? "block" : "none"
          }}
        >
          OK
        </div>
      </div>
    );
  }

}
