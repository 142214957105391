
export default class DateTimeService {

  public static ConvertToDDMMYYYY(date: Date | string, allDay?: boolean): string {
    if (!date) {
      return undefined;
    }
    if (typeof (date) === "string") {
      date = this.parseDate(date, allDay);
    }

    return `${this.PadWithZero(date.getDate())}-${this.PadWithZero(date.getMonth() + 1)}-${date.getFullYear()}`;
  }

  public static  ConvertToYYYYMMDD(date: Date | string, allDay?: boolean): string {
    if (!date) {
      return undefined;
    }

    if (typeof (date) === "string") {
      date = this.parseDate(date, allDay);
    }

    return `${date.getFullYear()}-${this.PadWithZero(date.getMonth() + 1)}-${this.PadWithZero(date.getDate())}`;
  }

  public static ConvertToDDMM(date: Date | string, allDay?: boolean): string {
    if (!date) {
      return undefined;
    }

    if (typeof (date) === "string") {
      date = this.parseDate(date, allDay);
    }

    return `${this.PadWithZero(date.getDate())}/${this.PadWithZero(date.getMonth() + 1)}`;
  }

  public static ConvertToHHMM(date: Date | string, allDay?: boolean): string {
    if (!date) {
      return undefined;
    }

    if (typeof (date) === "string") {
      date = this.parseDate(date, allDay);
    }

    return `${this.PadWithZero(date.getHours())}.${this.PadWithZero(date.getMinutes())}`;
  }

  public static ConvertToDDMMYYYYHHMM(date: Date | string, allDay?: boolean): string {
    if (!date) {
      return undefined;
    }

    if (typeof (date) === "string") {
      date = this.parseDate(date, allDay);
    }

    return `${this.ConvertToDDMMYYYY(date)} ${this.ConvertToHHMM(date)}`;
  }

  public static parseDate(dateString: string, allDay?: boolean): Date {
    if (allDay) {
      const offsetInMinutes = new Date().getTimezoneOffset();
      const localDate = new Date(dateString);
      localDate.setMinutes(localDate.getMinutes() + offsetInMinutes);
      return localDate;
    }
    return new Date(dateString);
  }

  private static PadWithZero(num: number): string {
    if (num < 10) {
      return '0' + num;
    }
    return num.toString();
  }

}