import * as React from 'react';
import './Meditations.css';
import { AppStore } from '../../stores';
import { inject, observer } from 'mobx-react';
import MeditationService from '../../services/MeditationService';
import PodcastCard from '../podcasts/podcastCard/PodcastCard';
import { IPodcast } from '../../interfaces/IPodcast';
import Header from '../header/Header';
import { CardViewService } from '../../services/CardView.service';
import LogoBar from '../logoBar/LogoBar';
import Card from '../card/Card';

export interface IProps {
  appStore?: AppStore;
}

export interface IState {
  containerWidth: number;
}

@inject('appStore')
@observer
export default class Meditations extends React.Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
      containerWidth: undefined
    }
  }

  public componentDidMount(): void {
    this.props.appStore.selectedTab = 4;
    this.getMeditations();
    // Resize
    window.addEventListener('resize', () => this.resize());
    this.resize();
    setTimeout(() => {
      this.resize();
    }, 0);
    try {
      var resizeObserver = new ResizeObserver(() => {
        this.resize();
      });
      resizeObserver.observe(document.getElementById(`meditations`));
    } catch {
      this.resize();
    }
  }

  private resize(): void {
    const programsContainer = document.getElementById("meditations");
    const padding = this.props.appStore.isMobile ? 40 : 80;
    this.setState({ containerWidth: programsContainer?.clientWidth - padding }, () => this.forceUpdate());
  }

  private getMeditations(): void {
    if (this.props.appStore.meditations == undefined || this.props.appStore.meditations?.length === 0) {
      MeditationService.getMeditations().then((meditations: IPodcast[]) => {
        this.props.appStore.meditations = meditations;
        this.forceUpdate();
        this.props.appStore.meditations.forEach((meditation) => {
          if (location.search && location.search.indexOf("?podcast=") != -1) {
            if (meditation.objectId === location.search.replace("?podcast=", "")) {
              this.props.appStore.showPodcastPlayerInFullScreen = true;
              this.props.appStore.podcastToPlay = meditation;
            }
          }
        })
      });
    }
  }

  render() {
    const highlightedMeditationElements = [];
    const meditationElements = [];
    const [numberOfCardsPerRow, maxCardWidthAvailable] = new CardViewService().getNumberOfCardsPerRowAndMaxCardWidthAvailable(this.state.containerWidth, this.props.appStore.isMobile ? 15 : 30, this.props.appStore.editMode ? 300 : 200, 6);
    let currentRowNumberHighlighted = 1;
    let currentRowNumber = 1;
    this.props.appStore.meditations?.forEach((meditation: IPodcast) => {
      if (this.props.appStore.editMode) {
        meditationElements.push(
          <Card
            key={meditation.objectId}
            type="Meditationer"
            width={this.props.appStore.isMobile ? screen.availWidth - 40 : (maxCardWidthAvailable <= 350 ? maxCardWidthAvailable : 350)}
            marginRight={currentRowNumber == numberOfCardsPerRow || this.props.appStore.isMobile ? 0 : 30}
            title={meditation.title}
            image={meditation.imageUrl}
            area='meditations'
            buttons={[
              {
                title: "Rediger",
                style: {
                  backgroundColor: "#ffffff",
                  color: "#272727"
                },
                onClick: () => {
                  this.props.appStore.meditationToEdit = meditation;
                }
              }
            ]}
          />
        );
        if (currentRowNumber == numberOfCardsPerRow) {
          currentRowNumber = 1;
        } else {
          currentRowNumber++;
        }
      } else {
        const duration = localStorage.getItem(`podcast_${meditation.objectId}`);
        meditation.hideVideo = true;
        meditation.category = "Meditation";
        if (duration) {
          highlightedMeditationElements.push(
            <PodcastCard
              id={meditation.objectId}
              width={(maxCardWidthAvailable <= 350 ? maxCardWidthAvailable : 350)}
              marginRight={currentRowNumberHighlighted == numberOfCardsPerRow ? 0 : (this.props.appStore.isMobile ? 15 : 30)}
              key={meditation.objectId}
              title={meditation.title}
              image={meditation.imageUrl}
              speaker={meditation.speaker}
              podcastDuration={meditation.duration}
              category="Meditation"
              selected={this.props.appStore.podcastToPlay?.objectId === meditation.objectId}
              duration={parseInt(duration.split("-")[1])}
              progress={parseInt(duration.split("-")[0])}
              views={meditation.views}
              onClick={() => {
                this.props.appStore.podcastToPlay = meditation;
                this.resize();
              }}
            />
          );
          if (currentRowNumberHighlighted == numberOfCardsPerRow) {
            currentRowNumberHighlighted = 1;
          } else {
            currentRowNumberHighlighted++;
          }
        }
        meditationElements.push(
          <PodcastCard
            id={meditation.objectId}
            width={(maxCardWidthAvailable <= 350 ? maxCardWidthAvailable : 350)}
            marginRight={currentRowNumber == numberOfCardsPerRow ? 0 : (this.props.appStore.isMobile ? 15 : 30)}
            key={meditation.objectId}
            title={meditation.title}
            image={meditation.imageUrl}
            speaker={meditation.speaker}
            podcastDuration={meditation.duration}
            category="Meditation"
            views={meditation.views}
            selected={this.props.appStore.podcastToPlay?.objectId === meditation.objectId}
            onClick={() => {
              this.props.appStore.podcastToPlay = meditation;
              this.getMeditations();
              this.resize();
            }}
          />
        );
        if (currentRowNumber == numberOfCardsPerRow) {
          currentRowNumber = 1;
        } else {
          currentRowNumber++;
        }
      }
    });
    return (
      <div
        id="meditations"
        className='meditations'
        style={{
          top: this.props.appStore.isMobile && window.platform !== "ios" ? 0 : 50,
          left: this.props.appStore.isMobile ? 0 : 250,
          width: this.props.appStore.isMobile ? "100vw" : "calc(100vw - 250px)",
          height: this.props.appStore.isMobile ? `calc(100% - ${this.props.appStore.isMobile && window.platform !== "ios" ? 80 : 130}px)` : (this.props.appStore.podcastToPlay ? `calc(100% - ${this.props.appStore.isMobile && window.platform !== "ios" ? 101 : 151}px)` : `calc(100% - ${this.props.appStore.isMobile && window.platform !== "ios" ? 0 : 50}px)`)
        }}
      >
        {location.href.indexOf("mindPaperId") == -1 && this.props.appStore.user && this.props.appStore.isMobile && !window.location.pathname.includes("/enneagrammy") &&
          <LogoBar />
        }
        <Header
          headline={window.loc.strings.meditationsHeader}
          button={this.props.appStore.editMode ? {
            title: "Tilføj meditation",
            onClick: () => this.props.appStore.meditationToEdit = {}
          } : undefined}
        />
        <div className="programsBox">
          <div className="container">
            {highlightedMeditationElements?.length > 0 &&
              <div className='meditationsHighlighted'>
                <div className='subtitle'>{window.loc.strings.podcastSectionContinue}</div>
                {highlightedMeditationElements}
              </div>
            }
            {meditationElements?.length > 0 &&
              <div className='meditationsAll'>
                <div className='subtitle'>{window.loc.strings.meditationsAll}</div>
                {meditationElements}
              </div>
            }
          </div>
        </div>
      </div>
    );
  }

  public componentWillUnmount(): void {
    window.removeEventListener('resize', () => this.resize());
  }

}