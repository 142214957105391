import * as React from 'react';
import './UpdatePasswordWrongPasswordPopup.css';
import { AppStore } from '../../../stores';
import { inject, observer } from 'mobx-react';
import AuthenticationService from '../../../services/AuthenticationService';

export interface IProps {
  appStore?: AppStore;
}

export interface IState {
}

@inject('appStore')
@observer
export default class UpdatePasswordWrongPasswordPopup extends React.Component<IProps, IState> {

  constructor(props, context) {
    super(props, context);
    this.state = {
    }
  }

  render() {
    return (
      <div className="updatePasswordWrongPasswordPopup">
        <div
          className="updatePasswordWrongPasswordPopupBackground"
          onClick={() => {
            this.props.appStore.showingUpdatePasswordWrongPasswordPopup = false;
          }}
        />
        <div className="updatePasswordWrongPasswordPopupBox">
          <h3>{window.loc.strings.wrongPassword}</h3>
          <div
            className="cancelBtn"
            onClick={() => {
              this.props.appStore.showingUpdatePasswordWrongPasswordPopup = false;
            }}
          >
            {window.loc.strings.tryAgain}
          </div>
          <div
            className="btn"
            onClick={() => {
              this.props.appStore.showingUpdatePasswordWrongPasswordPopup = false;
              this.props.appStore.showingUpdatePassword = false;
              if (this.props.appStore.user?.username) {
                AuthenticationService.resetPassword(this.props.appStore.user?.username).then((status: number) => {
                  if (status === 200) {
                    this.props.appStore.showingForgottenPasswordConfirmation = true;
                  } else {
                    console.log("resetPassword failed");
                  }
                });
              }
            }}
          >
            {window.loc.strings.resetWithEmail}
          </div>
        </div>
      </div>
    );
  }

}