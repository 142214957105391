import * as React from 'react';
import './UpdatePasswordPopup.css';
import { AppStore } from '../../stores';
import { inject, observer } from 'mobx-react';
import AuthenticationService from '../../services/AuthenticationService';
import { IDeletedUser, IUser } from '../../interfaces/IUser';
import ForgottenPasswordConfirmation from '../landingPage/forgottenPasswordConfirmation/ForgottenPasswordConfirmation';

export interface IProps {
  appStore?: AppStore;
}

export interface IState {
  newPassword?: string;
  newPasswordAgain?: string;
  oldPassword?: string;
  showOldPassword: boolean;
  showNewPassword: boolean;
  showNewAgainPassword: boolean;
}

@inject('appStore')
@observer
export default class UpdatePasswordPopup extends React.Component<IProps, IState> {

  constructor(props, context) {
    super(props, context);
    this.state = {
      showOldPassword: false,
      showNewPassword: false,
      showNewAgainPassword: false
    }
  }

  render() {
    return (
      <div className="updatePasswordPopup">
        <div
          className="updatePasswordPopupBackground"
          onClick={() => {
            this.props.appStore.showingUpdatePassword = false;
          }}
        />
        <div className="updatePasswordPopupBox">
          <h3>{window.loc.strings.updatePassword}</h3>
          <input
            type={this.state.showOldPassword ? "text" : "password"}
            placeholder={window.loc.strings.oldPassword}
            onChange={(event: any) => {
              this.setState({ oldPassword: event.target.value.toLowerCase() });
            }}
          />
          <div
            className='showPasswordButton'
            style={{
              backgroundImage: this.state.showOldPassword ? 'url("https://img.icons8.com/fluency-systems-regular/48/invisible.png")' : 'url("https://img.icons8.com/fluency-systems-regular/48/000000/visible--v1.png")'
            }}
            onClick={() => this.setState({ showOldPassword: !this.state.showOldPassword })}
          />
          <input
            type={this.state.showNewPassword ? "text" : "password"}
            placeholder={window.loc.strings.newPassword}
            onChange={(event: any) => {
              this.setState({ newPassword: event.target.value.toLowerCase() });
            }}
          />
          <div
            className='showPasswordButton'
            style={{
              backgroundImage: this.state.showNewPassword ? 'url("https://img.icons8.com/fluency-systems-regular/48/invisible.png")' : 'url("https://img.icons8.com/fluency-systems-regular/48/000000/visible--v1.png")'
            }}
            onClick={() => this.setState({ showNewPassword: !this.state.showNewPassword })}
          />
          <input
            type={this.state.showNewAgainPassword ? "text" : "password"}
            placeholder={window.loc.strings.newPasswordAgain}
            onChange={(event: any) => {
              this.setState({ newPasswordAgain: event.target.value.toLowerCase() });
            }}
          />
          <div
            className='showPasswordButton'
            style={{
              backgroundImage: this.state.showNewAgainPassword ? 'url("https://img.icons8.com/fluency-systems-regular/48/invisible.png")' : 'url("https://img.icons8.com/fluency-systems-regular/48/000000/visible--v1.png")'
            }}
            onClick={() => this.setState({ showNewAgainPassword: !this.state.showNewAgainPassword })}
          />
          <div
            className="updateBtn"
            style={{
              opacity: (this.state.oldPassword && this.state.newPassword && this.state.newPassword === this.state.newPasswordAgain) ? 1 : 0.5
            }}
            onClick={() => {
              if (this.state.oldPassword && this.state.newPassword && this.state.newPassword === this.state.newPasswordAgain) {
                this.props.appStore.user.password = this.state.newPassword;
                AuthenticationService.login(this.props.appStore.user.username, this.state.oldPassword).then((user: IUser) => {
                  if (user) {
                    user.password = this.state.newPassword;
                    this.props.appStore.setUser(user);
                    AuthenticationService.updateUser(user).then(() => {
                      this.props.appStore.showingUpdatePassword = false;
                      this.setState({
                        oldPassword: undefined,
                        newPassword: undefined,
                        newPasswordAgain: undefined
                      })
                    });
                  } else {
                    this.props.appStore.showingUpdatePasswordWrongPasswordPopup = true;
                    console.log("Error")
                  }
                });
              }
            }}
          >
            {window.loc.strings.updatePassword}
          </div>
          <div
            className="cancelBtn"
            onClick={() => {
              this.props.appStore.showingUpdatePassword = false;
            }}
          >
            {window.loc.strings.cancel}
          </div>
        </div>
      </div>
    );
  }

}