import * as React from 'react';
import './User.css';
import { AppStore } from '../../../stores';
import { inject, observer } from 'mobx-react';
import { IProduct } from '../../../interfaces/IProduct';
import { IUser } from '../../../interfaces/IUser';
import { IProductAccess } from '../../../interfaces/IProductAccess';
import ProductService from '../../../services/ProductService';

export interface IProps {
  appStore?: AppStore;
  user: IUser;
  update: () => void;

}

export interface IState {
  products: any[];
}

@inject('appStore')
@observer
export default class User extends React.Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
      products: []
    }
  }

  private renderProducts(): void {
    const products = [];
    ProductService.userAccess(this.props.user.username).then((productAccess: IProductAccess[]) => {
      if (productAccess) {
        this.props.appStore.products.forEach((product: IProduct) => {
          let daysLeft: number;
          let hasAccess: IProductAccess;
          productAccess.forEach((access: IProductAccess) => {
            if (product.productId === access.product) {
              hasAccess = access;
              if (product.accessPeriod !== "") {
                const accessPeriode = (product.accessPeriod !== undefined ? product.accessPeriod : 365)
                var time_difference = new Date().getTime() - new Date(access.updatedAt).getTime();
                daysLeft = accessPeriode - Math.round(time_difference / (1000 * 60 * 60 * 24));
              }
            }
          });
          products.push(
            <tr>
              <th style={{
                width: 40,
                paddingTop: 8,
                paddingLeft: 8
              }}>
                <input
                  type="checkbox"
                  checked={hasAccess != undefined}
                  onChange={() => {
                    if (hasAccess == undefined) {
                      ProductService.allowAccessToProduct(product.productId, this.props.user.username).then((status: number) => {
                        this.renderProducts()
                      });
                    } else {
                      ProductService.removeAccessToProduct(hasAccess.objectId).then((status: number) => {
                        this.renderProducts()
                      });
                    }
                  }}
                />
              </th>
              <th
                style={{
                  width: 200,
                  textAlign: "left",
                  paddingLeft: 10
                }}
              >
                <div>{product.title}</div>
                <div style={{ fontSize: 12 }}>{product.type}</div>
              </th>
              <th
                style={{
                  width: 80,
                  textDecoration: "underline",
                  cursor: "pointer"
                }}
                onClick={() => {
                  if (hasAccess != undefined) {
                    this.props.appStore.productAccess = hasAccess;
                    this.forceUpdate();
                  }
                }}
              >
                {hasAccess ? (hasAccess.team ? hasAccess.team : "Vælg hold") : ""}
              </th>
              <th style={{
                width: 50,
                color: product.accessPeriod != undefined && daysLeft < 0 ? "red" : "#272727"
              }}
              >
                {product.accessPeriod != undefined && product.accessPeriod !== "" ? daysLeft : <span style={{ fontFamily: "san-serif" }}>&infin;</span>}
              </th>
            </tr>
          );
          // if (product !== undefined) {
          //   let hasAccess;
          //   productAccess.forEach((productAccess: IProductAccess) => {
          //     if (productAccess.product === product.objectId) {
          //       hasAccess = true;
          //     }
          //   })
          //   if (hasAccess) {

          //   }
          // } else {

          // }
        });

      }
      this.setState({ products });
    });
  }

  render() {
    return (
      <div className="user">
        <header
          onClick={() => {
          }}
        >
          <div className="userName">{this.props.user.name}</div>
          <div className="userEmail">{this.props.user.username}</div>
        </header>
        <div className='userActions'>
          <div
            className='userActionsButton'
            style={{
              opacity: this.props.appStore.user?.masterKey ? 1 : 0.5
            }}
            onClick={() => {
              if (this.props.appStore.user?.masterKey) {
                this.props.appStore.showingAdminUpdatePasswordForUser = this.props.user;
              }
            }}
          >
            <div
              className='userActionsIcon'
              style={{
                backgroundImage: 'url("https://dojo.mindjuice.com/images/reset-password.png")'
              }}
            />
            <div className='userActionsText'>{window.loc.strings.updatePassword}</div>
          </div>
          <div
            className='userActionsButton'
            style={{
              opacity: this.props.appStore.user?.masterKey ? 1 : 0.5
            }}
            onClick={() => {
              if (this.props.appStore.user?.masterKey) {
                this.props.appStore.showingAdminDeleteAccountForUser = this.props.user;
              }
            }}
          >
            <div
              className='userActionsIcon'
              style={{
                backgroundImage: 'url("https://dojo.mindjuice.com/images/delete-account.png")'
              }}
            />
            <div className='userActionsText'>{window.loc.strings.deleteAccount}</div>
          </div>
        </div>
        <div>
          {this.state.products && this.state.products.length > 0 ?
            <table>
              {this.state.products}
            </table>
            :
            <div className='userActions'>
              <div
                className='userActionsButton'
                onClick={() => {
                  this.renderProducts()
                }}
                style={{
                  width: "auto",
                  paddingLeft: 20,
                  paddingRight: 20,
                  marginTop: 10
                }}
              >
                <div className='userActionsText'>Vis adgang til programmer og hold</div>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}