import * as React from 'react';
import './SideBar.css';
import { inject, observer } from 'mobx-react';
import { AppStore } from '../../stores';
import { Link } from 'react-router-dom';

export interface IProps {
  appStore?: AppStore;
}

export interface IState {
}

@inject('appStore')
@observer
export default class SideBar extends React.Component<IProps, IState> {

  constructor(props,) {
    super(props);
    this.state = {
    }
  }

  render() {
    return (
      <div className="mindjuiceSideBar"
        style={{
          height: this.props.appStore.podcastToPlay ? "calc(100% - 151px)" : "calc(100% - 50px)"
        }}
      >
        {/* Today */}
        <Link
          to={`${this.props.appStore.environment}/`}
          key={"home"}
        >
          <div
            className="sideBarItem"
            style={{
              opacity: this.props.appStore.selectedTab === 1 ? 1 : 0.5
            }}
            onClick={() => {
              this.props.appStore.showExtendedMenu = false;
              this.props.appStore.selectedTab = 1;
              this.render();
            }}
          >
            <div
              className="sideBarItemIcon"
              style={{
                backgroundImage: `url(https://dojo.mindjuice.com/images/tabbar/home${this.props.appStore.selectedTab === 1 ? "-selected" : ""}.png)`
              }}
            />
            <div className="sideBarItemText">
              {window.loc.strings.tabBarToday}
            </div>
          </div>
        </Link>
        {/* Programs */}
        {/* <Link
          to={`${this.props.appStore.environment}/programs/`}
          key={"programs"}
        >
          <div
            className="sideBarItem"
            style={{
              opacity: this.props.appStore.selectedTab === 2 ? 1 : 0.5
            }}
            onClick={() => {
              this.props.appStore.showExtendedMenu = false;
              this.props.appStore.selectedTab = 2;
              this.render();
            }}
          >
            <div
              className="sideBarItemIcon"
              style={{
                backgroundImage: `url(https://dojo.mindjuice.com/images/tabbar/programs${this.props.appStore.selectedTab === 2 ? "-selected" : ""}.png)`
              }}
            />
            <div className="sideBarItemText">
              {window.loc.strings.tabBarPrograms}
            </div>
          </div>
        </Link> */}
        {/* Tests */}
        <Link
          to={`${this.props.appStore.environment}/tests/`}
          key={"tests"}
        >
          <div
            className="sideBarItem"
            style={{
              opacity: this.props.appStore.selectedTab === 2 ? 1 : 0.5
            }}
            onClick={() => {
              this.props.appStore.showExtendedMenu = false;
              this.props.appStore.selectedTab = 2;
              this.render();
            }}
          >
            <div
              className="sideBarItemIcon"
              style={{
                backgroundImage: `url(https://dojo.mindjuice.com/images/tabbar/tests${this.props.appStore.selectedTab === 2 ? "-selected" : ""}.png)`
              }}
            />
            <div className="sideBarItemText">
              {this.props.appStore.isMobile ? window.loc.strings.tabBarTests : window.loc.strings.testsHeaderWeb}
            </div>
          </div>
        </Link>
        {/* Podcasts */}
        <Link
          to={`${this.props.appStore.environment}/inspiration/`}
          key={"podcasts"}
        >
          <div
            className="sideBarItem"
            style={{
              opacity: this.props.appStore.selectedTab === 3 ? 1 : 0.5
            }}
            onClick={() => {
              this.props.appStore.showExtendedMenu = false;
              this.props.appStore.selectedTab = 3;
              this.render();
            }}
          >
            <div
              className="sideBarItemIcon"
              style={{
                backgroundImage: `url(https://dojo.mindjuice.com/images/tabbar/podcasts${this.props.appStore.selectedTab === 3 ? "-selected" : ""}.png)`
              }}
            />
            <div className="sideBarItemText">
              {window.loc.strings.tabBarPodcasts}
            </div>
          </div>
        </Link>
        {/* Meditations */}
        <Link
          to={`${this.props.appStore.environment}/meditations/`}
          key={"meditations"}
        >
          <div
            className="sideBarItem"
            style={{
              opacity: this.props.appStore.selectedTab === 4 ? 1 : 0.5
            }}
            onClick={() => {
              this.props.appStore.showExtendedMenu = false;
              this.props.appStore.selectedTab = 4;
              this.render();
            }}
          >
            <div
              className="sideBarItemIcon"
              style={{
                backgroundImage: `url(https://dojo.mindjuice.com/images/tabbar/meditations${this.props.appStore.selectedTab === 4 ? "-selected" : ""}.png)`
              }}
            />
            <div className="sideBarItemText">
              {window.loc.strings.tabBarMeditations}
            </div>
          </div>
        </Link>

        {/* Mindpaper */}
        {this.props.appStore.user?.userType === "admin" && this.props.appStore.editMode &&
          < Link
            to={`${this.props.appStore.environment}/mindpapers/`}
            key={"mindpapers"}
          >
            <div
              className="sideBarItem"
              style={{
                opacity: this.props.appStore.selectedTab === 5 ? 1 : 0.5
              }}
              onClick={() => {
                this.props.appStore.selectedTab = 5;
                this.render();
              }}
            >
              <div
                className="sideBarItemIcon"
                style={{
                  backgroundImage: this.props.appStore.selectedTab === 5 ? 'url("https://dojo.mindjuice.com/images/mindpaper-filled.png")' : `url("https://dojo.mindjuice.com/images/mindpaper.png")`
                }}
              />
              <div className="sideBarItemText">
                Mindpapers
              </div>
            </div>
          </Link>
        }

        {/* Users */}
        {this.props.appStore.user?.userType === "admin" && this.props.appStore.editMode &&
          < Link
            to={`${this.props.appStore.environment}/users/`}
            key={"meditations"}
          >
            <div
              className="sideBarItem"
              style={{
                opacity: this.props.appStore.selectedTab === 6 ? 1 : 0.5
              }}
              onClick={() => {
                this.props.appStore.selectedTab = 6;
                this.render();
              }}
            >
              <div
                className="sideBarItemIcon"
                style={{
                  backgroundImage: this.props.appStore.selectedTab === 6 ? 'url("https://img.icons8.com/fluency-systems-filled/96/ffffff/conference-background-selected.png")' : `url("https://img.icons8.com/fluency-systems-regular/96/ffffff/groups.png")`
                }}
              />
              <div className="sideBarItemText">
                Brugere
              </div>
            </div>
          </Link>
        }

        {/* Notifications */}
        {this.props.appStore.user?.userType === "admin" && this.props.appStore.editMode &&
          < Link
            to={`${this.props.appStore.environment}/notifications/`}
            key={"notifications"}
          >
            <div
              className="sideBarItem"
              style={{
                opacity: this.props.appStore.selectedTab === 7 ? 1 : 0.5
              }}
              onClick={() => {
                this.props.appStore.selectedTab = 7;
                this.render();
              }}
            >
              <div
                className="sideBarItemIcon"
                style={{
                  backgroundImage: this.props.appStore.selectedTab === 7 ? 'url("https://img.icons8.com/fluency-systems-filled/96/ffffff/appointment-reminders.png")' : `url("https://img.icons8.com/fluency-systems-regular/48/ffffff/appointment-reminders--v1.png")`
                }}
              />
              <div className="sideBarItemText">
                Notifikationer
              </div>
            </div>
          </Link>
        }
        {/* Admin */}
        {
          this.props.appStore.user?.userType === "admin" &&
          <div
            className="sideBarItemAdmin"
          >
            <div
              className="sideBarItemIcon"
              style={{
                backgroundImage: `url("https://img.icons8.com/fluency-systems-regular/48/FFFFFF/system-administrator-male.png")`,
                opacity: 0.5
              }}
            />
            <label className={"IA_switchLabel"}>
              <div
                style={{
                  position: "relative",
                }}
                className="IA_OnFocus"
                tabIndex={0}
                role="switch"
              >
                <input
                  type="checkbox"
                  tabIndex={-1}
                  checked={this.props.appStore.editMode}
                  onChange={() => {
                    this.props.appStore.editMode = !this.props.appStore.editMode;
                    this.props.appStore.slideIndex = 1;
                    this.props.appStore.slideMarginLeft = 0;
                    if (this.props.appStore.editMode) {
                      localStorage.setItem("editMode", "true");
                    } else {
                      localStorage.removeItem("editMode");
                    }
                  }}
                />
                <span
                  tabIndex={-1}
                  className={"IA_switchSlider"}
                  style={{
                    boxShadow: this.props.appStore.editMode ? `0 0 1px #7a12d4` : "none",
                    background: this.props.appStore.editMode ? "#7a12d4" : "#3c3c3c"
                  }}
                ></span>
              </div>
            </label>
            <div
              className="sideBarItemText"
              style={{
                opacity: 0.5
              }}
            >
              Rediger
            </div>
          </div>
        }
      </div >
    );
  }

}