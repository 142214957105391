import * as React from 'react';
import './Mindpapers.css';
import { AppStore } from '../../stores';
import { inject, observer } from 'mobx-react';
import Header from '../header/Header';
import LogoBar from '../logoBar/LogoBar';
import ProductService from '../../services/ProductService';
import { IMindPaper } from '../../interfaces/IMindPaper';
import MindPaperEditPanel from '../../admin/products/productEditPanel/mindPaperEditPanel/MindPaperEditPanel';
import { INote } from '../../interfaces/INote';

export interface IProps {
  appStore?: AppStore;
}

export interface IState {
  notPushedNotificationsToRender?: JSX.Element[];
  pushedNotificationsToRender?: JSX.Element[];
}

@inject('appStore')
@observer
export default class Mindpapers extends React.Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  public componentDidMount(): void {
    this.getMindpapers();
  }

  private getMindpapers(): void {
    if (this.props.appStore.editMode) {
      ProductService.getMindPapers().then((mindPapers: any[]) => {
        this.props.appStore.mindPapers = mindPapers;
      });
    } else {
      const mindPapers: IMindPaper[] = [];
      ProductService.getNotes(this.props.appStore.user?.id).then((notes: INote[]) => {
        console.log(notes);
        this.props.appStore.notes = notes;
        this.props.appStore.notes.forEach(async (note: INote) => {
          await ProductService.getMindPaper(note.mindPaperId).then((mindPaper: IMindPaper) => {
            mindPapers.push(mindPaper);
          });
          this.props.appStore.mindPapers = mindPapers;
        })
      });
    }
  }

  render() {
    const mindpapers = [];
    this.props.appStore.mindPapers?.forEach((mindpaper: IMindPaper) => {
      if (mindpaper) {
        mindpapers.push(
          <div
            className='mindpaperCover'
          >
            <img
              src={`${mindpaper?.url}/${mindpaper?.fileName}_Side_${mindpaper?.pages > 9 ? "01" : "1"}.jpg`}
              width={"100%"}
              style={{
                objectFit: "contain",
                maxHeight: 380
              }}
              onClick={() => this.props.appStore.pageEmbed = `https://dojo.mindjuice.com/mindPaper2?mindPaperId=${mindpaper?.objectId}`}
            />
            {this.props.appStore.editMode &&
              <div
                className="mindpaperEditButton"
                style={{
                  backgroundImage: `url("https://img.icons8.com/external-anggara-basic-outline-anggara-putra/96/ffffff/external-edit-user-interface-anggara-basic-outline-anggara-putra-5.png")`,
                }}
                onClick={() => {
                  this.props.appStore.mindPaperToEdit = mindpaper;
                }}
              />
            }
            <div
              className='mindpaperCoverTitle'
              onClick={() => this.props.appStore.pageEmbed = `https://dojo.mindjuice.com/mindPaper2?mindPaperId=${mindpaper?.objectId}`}
            >
              {mindpaper?.title}
            </div>
          </div>
        );
      }
    });
    return (
      <div
        className="mindpaperAdmin"
        style={{
          left: this.props.appStore.isMobile ? 0 : 250,
          width: this.props.appStore.isMobile ? "100vw" : "calc(100vw - 250px)",
          height: this.props.appStore.isMobile ? "calc(100% - 130px)" : (this.props.appStore.podcastToPlay ? "calc(100% - 151px)" : "calc(100% - 50px)")
        }}
      >
        {location.href.indexOf("mindPaperId") == -1 && this.props.appStore.user && this.props.appStore.isMobile && !window.location.pathname.includes("/enneagrammy") &&
          <LogoBar />
        }
        <Header
          headline={"Mindpapers"}
          button={this.props.appStore.editMode ? {
            title: "Opret nyt Mindpaper",
            onClick: () => {
              this.props.appStore.mindPaperToEdit = {};
            }
          } : undefined}
        />
        <div
          className="mindpaperAdminContent"
          style={{
            clear: "both"
          }}
        >
          {mindpapers}
        </div>
        {this.props.appStore.mindPaperToEdit && this.props.appStore.selectedTab === 5 &&
          <MindPaperEditPanel
            reloadMindPapers={(selectedId: string) => {
              console.log(selectedId);
              this.props.appStore.mindPaperToEdit = undefined;
              this.getMindpapers();
            }}
          />
        }
      </div >
    );
  }
}