import * as React from 'react';
import './TypeTestDeletePopup.css';
import { AppStore } from '../../../stores';
import { inject, observer } from 'mobx-react';
import TestService from '../../../services/TestService';
import { ITestResult } from '../../../interfaces/ITestResult';
import * as ReactGA from 'react-ga';

export interface IProps {
  appStore?: AppStore;
}

export interface IState {
}

@inject('appStore')
@observer
export default class TypeTestDeletePopup extends React.Component<IProps, IState> {

  constructor(props, context) {
    super(props, context);
    this.state = {
    }
  }

  render() {
    return (
      <div className="typeTestDeletePopup">
        <div
          className="typeTestDeletePopupBackground"
          onClick={() => {
            this.props.appStore.showingSignUp = false;
          }}
        />
        <div className="typeTestDeletePopupBox">
          <h3>{window.loc.strings.deletePopupHeadline}</h3>
          <div
            className="typeTestDeleteBtn"
            onClick={() => {
              ReactGA.event({
                category: 'Tests',
                action: `User deleted test result`
              });
              TestService.delete(this.props.appStore.user.id, this.props.appStore.testIdToDelete).then((testResults: ITestResult[]) => {
                if (testResults) {
                  this.props.appStore.setTestResults(testResults);
                  this.props.appStore.testIdToDelete = undefined;
                }
              });
            }}
          >
            {window.loc.strings.delete}
          </div>
          <div
            className="typeTestCancelBtn"
            onClick={() => this.props.appStore.testIdToDelete = undefined}
          >
            {window.loc.strings.cancel}
          </div>
        </div>
      </div>
    );
  }

}