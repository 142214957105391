import * as React from 'react';
import './TeamPanel.css';
import { AppStore } from '../../../stores';
import { inject, observer } from 'mobx-react';
import AdminService from '../../../services/AdminService';
import { IProductAccess } from '../../../interfaces/IProductAccess';
import ProductService from '../../../services/ProductService';
import { ITeam } from '../../../interfaces/ITeam';
import TeamEditPanel from './teamEditPanel/TeamEditPanel';

export interface IProps {
  appStore?: AppStore;
  productId: string;
}

export interface IState {
  team: string;
}

@inject('appStore')
@observer
export default class TeamPanel extends React.Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
      team: undefined
    }
  }

  public componentWillReceiveProps(props: IProps): void {
    if (props.productId !== undefined) {
      setTimeout(() => {
        this.getTeams(props.productId);
      }, 500);
    }
  }

  private getTeams(productId: string): void {
    ProductService.getTeamsForProduct(productId).then((teams: ITeam[]) => {
      if (teams) {
        this.props.appStore.teamsForProduct = [];
        teams.forEach((team: ITeam) => {
          this.props.appStore.teamsForProduct.push(
            <div
              key={"teamsForProduct_" + team.objectId}
              className="teamLabel"
              onClick={() => this.props.appStore.teamToEdit = team}
            >
              {team.team}
            </div>
          );
        })
        this.forceUpdate();
      }
    });
  }

  render() {
    return (
      <div>
        <div
          className="teamPanelWrapper"
          style={{
            visibility: this.props.appStore.showTeamPanelForProduct !== undefined ? "visible" : "hidden"
          }}
          onClick={() => {
            this.props.appStore.showTeamPanelForProduct = undefined;
          }}
        />
        <div
          className="teamPanel"
          style={{
            right: this.props.appStore.showTeamPanelForProduct !== undefined ? 0 : -620
          }}
        >
          <div className="editPanelCloseBtn"
            onClick={() => this.props.appStore.showTeamPanelForProduct = undefined}
          />
          <div className="addUserAccess">
            <h3>Tilføj hold:</h3>
            <div className="inputFieldLabel">Hold navn</div>
            <input
              type="text"
              value={this.state.team}
              onChange={(event: any) => {
                this.setState({ team: event.target.value });
              }}
            />
            <button
              onClick={() => {
                const team: ITeam = {
                  productId: this.props.productId,
                  team: this.state.team,
                  info: ""
                }
                ProductService.createTeam(team).then(() => {
                  this.setState({ team: "" });
                  this.forceUpdate();
                });
              }}
            >
              Tilføj
            </button>
          </div>
          <h3>Hold:</h3>
          <div>
            {this.props.appStore.teamsForProduct}
          </div>
        </div>
        <TeamEditPanel
          show={this.props.appStore.teamToEdit != undefined}
          update={() => this.getTeams(this.props.productId)}
        />
      </div>
    );
  }
}