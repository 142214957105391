import * as React from 'react';
import './EnneagrammyAbout.css';
import { inject, observer } from 'mobx-react';
import { AppStore } from '../../stores';

export interface IProps {
  appStore?: AppStore;
}

@inject('appStore')
@observer
export default class EnneagrammyAboutestIntro extends React.Component<IProps> {

  public componentDidMount(): void {
    this.props.appStore.selectedTypeTestTab = 4;
  }

  render() {
    return (
      <div className="enneagrammyAbout">
        <h1>NYHED: Test dig selv og find din Enneagramtype</h1>
        <p> Måske kender du til Enneagrammet i forvejen, men er i tvivl om din type. Måske vil du bare gerne selv finde din egen type frem for, at venner og familie bliver ved at gætte på, hvad type du er. Denne test hjælper dig til at finde din Enneagramtype, som også kan ses som et indre kompas. Med Mindjuice nye Enneagramtest får du mulighed for at fordybe dig i forståelsen i de 9 typer, hvem vi er og hvorfor.</p>
        <h3>Hvorfor gør du egentlig som du gør?</h3>
        <p>Du vil hurtigt opdage at Enneagrammet i modsætning til andre typologier er baseret på dybdegående årsager til HVORFOR du gør som du gør, snarere end det er baseret på en beskrivelse af, hvad du gør. Dybden og forståelsen af motiv skaber en forståelse for andres og egen adfærd, som ikke findes i nogle øvrige typologier. Testen er bygget op omkring 24 spørgsmål. Når den er færdig, får du resultatet for alle 9 typer oplyst i procenter, alt efter hvor meget af hver typer du rummer.</p>
        <h3>Hold øje med din egen udvikling</h3>
        <p>Mindjuice Enneagram-test giver dig en resultatoversigt, så du kan måle og spore din udvikling med dato og klokkeslet. Hvilket giver dig et fyldestgørende overblik over din udvikling. Du kan således målrette arbejde på at integrere en af de typer, som du slår lavt ud på. Herefter kan du tage testen igen og se, hvordan procentsatsen har flyttet sig. Med testen følger uddybende forklaringer på, hvad de enkelte typers motiver er. Pernille W. Lauritsen uddyber hver type på overskuelige videoer af to minutters varighed, der giver dig indsigt i, hvad deres gøren og kunnen indeholder.</p>
        <p>Hun gennemgår hver types:</p>
        <ul>
          <li>3 største styrker.</li>
          <li>3 største svagheder.</li>
          <li>3 udviklingsveje og øvelser til vækst.</li>
        </ul>
        <h3>Hvorfor er Enneagrammet populært?</h3>
        <p>Systemet er som sagt baseret på dine grundlæggende motiver for adfærd, modsat andre typologier, som typisk beskriver adfærden uden årsagen. Det giver dig dermed en reel vejviser ud af din indre rundkørsel, så du slipper for igen og igen at køre i ring. Læren om Enneagrammet er et af de mest populære fag hos Mindjuice Academy. Det skyldes dels, at vi fusionerer Enneagrammets visdom med visdomstraditioner, transformativ coaching og udviklingspsykologi, dels at vi er kendt for at skabe et højt niveau af nærvær, sårbarhed og ærlighed.</p>
        <h3>Din guide er Pernille W. Lauritsen</h3>
        <p>Pernille W. Lauritsen guider dig igennem beskrivelserne at typerne i testen. Hun er grundlægger af Mindjuice Academy – en af de største skoler inden for personlig udvikling og bevidst lederskab i Skandinavien. Hun er også tidligere elev af Riso - Hudsons The Enneagram Institute i New York og havde i flere år Don Riso som sin personlige mentor og sparringspartner. Don Risos bøger er oversat og udkommet i over 40 lande, og han er til dato en af verdens mest betydningsfulde lærere inden for Enneagrammet og bevidsthedsarbejde.</p>
        <h3>Et historisk samarbejde</h3>
        <p>Få år før sin død blev Don Riso tilknyttet Mindjuices Masteruddannelse, som fløj til New York for at møde den store mester ud i personlighedens kringelkroge. Don beskrev Pernilles design af Masteruddannelsen som en direkte forlængelse af hans arbejde med Enneagrammet og bevidsthedsniveauer. Pernille har igennem de sidste 23 år trænet over 10.000 mennesker i personlige transformationsprocesser, i kommunikation, krisehåndtering, visdomsteori og Enneagrammet. Hun er tidligere præsident for International Coach Federation i Danmark og har skrevet fire bøger om relationer og bevægelser.</p>
        <h3>Nærværende og ærlig undervisning</h3>

        <p>Hun er desuden podcast - programvært på Podimo og tidligere radiovært for coachingprogrammet “Det Du Frygter” på DR P1. Lige nu er hun aktuel med DR - dokumentaren ’Tessas Hævn’, hvor hun medvirker som coach i hvert afsnit. Som underviser er Pernille kendt for sin levende og engagerende undervisning, som bringer nærvær, logik og sårbarhed til bordet – også online, hvor hun har undervist i mere end et årti.</p>
        <h3>Om Mindjuice Academy</h3>
        <p>Holdet i Mindjuice Academy har brugt knapt 20 år på at blive specialister i, hvad det vil sige at være menneske, når det er både bedst og værst. Vi ved derfor meget om, hvordan små justeringer i det indre skaber store resultater i livet. Vi bruger Enneagrammet som fundamentet for den læring, vi tilbyder. Vi har afholdt mere end 120 uddannelser og certificeret + 3.000 deltagere på længerevarende uddannelser, herunder top executives, kreative kunstnere, offentlige ledere, sportsfolk, advokater, ingeniører med mere.</p>
      </div>
    );
  }

}